<template>
  <div>

    <div class="content tabstyle" style="padding-top: 0px; padding-bottom: 0px;">
        <div class="subModule" style="margin: 0;">
            <div class="pageHeader moduleHeader blue2 header3">
                <div class="left">
                    <h3>
                        <!-- <span class="usedStyle red" :class="{ green: sparecontrol == 1 }"></span> -->
                        {{ this.sparePageTitle }}
                    </h3>
                </div>
                <div class="right" v-if="this.actionRemove == false && this.sparecontrol == 0">
                    <svg @click="WorkOrderItemDialog" style="float: right; margin-top: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="15.999" viewBox="0 0 16 15.999">
                        <path id="Union_2" data-name="Union 2" d="M-4613,16V9h-7V7h7V0h2V7h7V9h-7v7Z" transform="translate(4620)" fill="#444"/>
                    </svg>
                </div>
                <div class="clear"></div>
             </div>
            <div class="detailBox">
                <ul v-if="workOrderItems.length > 0" >
                    <li v-for="(item) in workOrderItems" :key="item.Id" :class="{ bgacctive_1: item.AppointmentId != null }">
                        <!-- <div style="display: inline-block; width: 10%; vertical-align: top;" v-if="item.AppointmentId != null">
                            <div class="icon check" v-if="item.AppointmentId != null" :class="{ check: item.AppointmentId != null }">
                                <i class="fas fa-check"></i>
                            </div>
                             <div class="icon check" v-if="item.AppointmentId == null" :class="{ red: item.AppointmentId == null }">
                                <i class="fas fa-check"></i>
                            </div>
                        </div> -->
                        <div @click="sparePartDialog(item)">
                            <span v-if="item.AppointmentId != null">
                                <div style="display: inline-block; margin-right: 10px;" class="icon check" >
                                    <i  :class="{ check: item.AppointmentId != null }" class="fas fa-check"></i>
                                </div>
                                {{ item.SparePartNumber }}
                            </span>

                             <span v-if="item.AppointmentId == null">
                                <div style="display: inline-block; margin-right: 10px;" class="icon red" >
                                    <i  :class="{ check: item.AppointmentId != null }" class="fas fa-clock"></i>
                                </div>
                                {{ item.SparePartNumber }}
                            </span>

                            <span class="itemCount">{{ item.Quantity }} Adet</span>
                            <span v-if="item.Appointment != null">
                                <label style="display: block; margin: 10px 0 0;">{{ item.Appointment.TechnicianName }}</label>
                                <label style="display: block; margin: 10px 5px;">({{ new Date(item.Appointment.StartingDate).toLocaleString()}})</label>
                            </span>
                        </div>
                    </li>
                </ul>

                <div class="photoGroupItem"  v-if="workOrderItems.length < 1" >
                    <div class="moduleContent">
                    <div class="warning" style="padding:25px 25px ">
                        <span>
                            <svg class="text-left" id="Warning" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
                                <path id="Path_35" data-name="Path 35" d="M7,0a7,7,0,1,0,7,7A7.021,7.021,0,0,0,7,0Zm.963,10.675H6.037V9.012H8.05v1.663ZM8.05,4.2,7.525,8.05H6.475L5.95,4.2V3.325H8.137V4.2Z" fill="#ff1119"/>
                            </svg>
                           Yedek parça kayıtı bulunamadı!
                        </span>
                    </div>
                </div>
                </div>

            </div>
        </div>
    </div>
    


    <a-drawer
      placement="right"
      :closable="false"
      :visible="WorkOrderItemDialogVisible"
      wrapClassName="addWorkOrderItemDialog"
      @close="WorkOrderItemDialogClose">

     <div class="mheader" style="height: 66px; overflow: hidden;">
        <div class="row">
            <div class="col-xs-2">
                <svg @click="WorkOrderItemDialogClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                  <g id="back" transform="translate(-1)">
                    <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                  </g>
                </svg>
            </div>
            <div class="col-xs-8">
                <span class="title">
                  {{ this.sparePartModalTitle }}
                </span>
            </div>
            <div class="col-xs-2 text-right formBtn" v-if="this.actionRemove == false">
                  <svg v-if="this.sparePartDialogControl == 1 || this.sparePartDialogControl == 2" @click="addWorkItemSubmit" xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                      <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                  </svg>

                <a-dropdown :trigger="['click']" v-if="this.sparePartDialogControl == 3 && this.actionhidden">
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                    <svg class="href"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="3" height="15" viewBox="0 0 3 15">
                        <defs>
                        <clipPath id="clip-path">
                            <rect width="3" height="15" fill="none"/>
                        </clipPath>
                        </defs>
                        <g id="Options" clip-path="url(#clip-path)">
                        <path id="Union_1" data-name="Union 1" d="M0,13.5A1.5,1.5,0,1,1,1.5,15,1.5,1.5,0,0,1,0,13.5Zm0-6A1.5,1.5,0,1,1,1.5,9,1.5,1.5,0,0,1,0,7.5Zm0-6A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" fill="#fff"/>
                        </g>
                    </svg>
                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item key="0" v-if="sparePartView.AppointmentId == null && this.workorderid != null && this.workorderid > 0">
                            <a @click="sparePartUsed(sparePartView)">Kullan</a>
                        </a-menu-item>
                         <a-menu-item key="1" v-if="sparePartView.AppointmentId == ((this.servicedetail.Appointment != null) ? this.servicedetail.Appointment.Id : null) && sparePartView.AppointmentId > 1 && this.workorderid != null && this.workorderid > 0">
                            <a @click="sparePartUsedRemove(sparePartView)">Çıkar</a>
                        </a-menu-item>
                        <a-menu-item key="2">
                            <a @click="sparePartEdit(sparePartView)">Düzenle</a>
                        </a-menu-item>
                        <a-menu-item key="3">
                            <a @click="sparePartDelete(sparePartView)">Sil</a>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>
        </div>
      </div>

       <div class="content" style="padding-top: 63px;">
        <div class="formStyle" style="margin-top: 25px;">
            <form class="ant-form ant-form-horizontal">
                <div v-if="this.sparePartDialogControl == 3" style="margin-top: -21px;">
                     <div class="pageHeader" style="margin-bottom: 0;">
                        <h3>Genel Bilgiler</h3>
                     </div>
                     <div class="detailBox">
                        <ul>
                            <li>
                                <span>Parça Adı</span>
                                <span>{{ this.sparePartView.SparePartName }}</span>
                            </li>
                            <li>
                                <span>Seri No</span>
                                <span>{{ this.sparePartView.SparePartNumber }}</span>
                            </li>
                            <li>
                                <span>Adet</span>
                                <span>{{ this.sparePartView.Quantity }}</span>
                            </li>
                             <li>
                                <span>Teslimat</span>
                                <span>{{ this.sparePartView.DeliveryName }}</span>
                            </li>
                        </ul>
                     </div>
                </div>

                <div v-if="this.sparePartDialogControl == 1 || this.sparePartDialogControl == 2">
                    
                    <div class="ant-row ant-form-item">
                        <div class="ant-col ant-form-item-label">
                            <label title="Ürün Seri No" class="">Ürün Seri No</label>
                        </div>
                        <div class="ant-col ant-form-item-control-wrapper">
                            <div class="ant-form-item-control">
                                <a-input @click="sparaPartSelectModalClick" pressEnter="addWorkItemSubmit()"  v-model="workItemData.SparePartNumber" placeholder="Ürün Seri No" />
                            </div>
                        </div>
                    </div>

                    <div class="ant-row ant-form-item">
                        <div class="ant-col ant-form-item-label">
                            <label title="Ürün Seri No" class="">Parça Adı</label>
                        </div>
                        <div class="ant-col ant-form-item-control-wrapper">
                            <div class="ant-form-item-control">
                                <a-input pressEnter="addWorkItemSubmit()"  v-model="workItemData.SparePartName" placeholder="Parça Adı" />
                            </div>
                        </div>
                    </div>
                    
                    <div class="ant-row ant-form-item">
                        <div class="ant-col ant-form-item-label">
                            <label title="Adet" class="">Adet</label>
                        </div>
                        <div class="ant-col ant-form-item-control-wrapper">
                            <div class="ant-form-item-control">
                               <a-input-number placeholder="Adet" x-webkit-speech="x-webkit-speech" pressEnter="addWorkItemSubmit()" v-model="workItemData.Quantity" :min="0" :max="999" @change="QuantityChange($event)" />
                            </div>
                        </div>
                    </div>

                     <div class="ant-row ant-form-item">
                        <div class="ant-col ant-form-item-label">
                            <label title="Adet" class="">Teslimat Durumu</label>
                        </div>
                        <div class="ant-col ant-form-item-control-wrapper">
                            <div class="ant-form-item-control">

                                <a-select
                                    :value="selectDelivery"  @change="onChangeDelivery($event)">
                                     <a-select-option v-for="d in deliveryTypeList" :key="d.Id">
                                        {{ d.Name }}
                                    </a-select-option>>
                                </a-select>

                                    
                                <!-- <a-select
                                    label-in-value
                                    :placeholder="(deliveryTypeName == null) ? 'Seçim yapınız' : deliveryTypeName"
                                    @change="onChangeDelivery($event)">
                                    <a-select-option v-for="d in deliveryTypeList" :key="d.Id">
                                        {{ d.Name }}
                                    </a-select-option>>
                                </a-select> -->


                                
                            </div>
                        </div>
                    </div>

                </div>

                <div class="clearfix"></div>
            </form>
        </div>
    </div>
      
    </a-drawer>

    <a-drawer
      placement="right"
      :closable="false"
      :visible="sparePartSelectSelectModal"
      wrapClassName = "sparePartSelectSelectModal"
      @close="sparePartSelectModalClose">
      
      <sparepartlistselect  @parentmodalclose="sparePartSelectModalClose" @parentgetproduct="sparePartSelectItem=$event"></sparepartlistselect>
    </a-drawer>


</div>
</template>


<script>
  import sparepartlistselect from './sparePartSelect';
  export default {
    name: "App",
    data() {
      return {
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'coordinated' }),

        info : [],

        filter: { Skip: 0, PageSize: 10 },

        WorkOrderItemDialogVisible: false,
        workItemData: {},
        sparePartDialogControl: 0,
        sparePartModalTitle: null,
        sparePartView: {},
        Quantity: null,

        workOrderItems : [],

        dataFilterQuery : [],
        filterInfoData: [],

        sparePageTitle: "Yedek Parçalar",
        
        // Edit Buton
        inVisible : false,

        disableProduct:true,

        warningControl: 1,

        // Spare Part Form
        sparePartId: "",
        sparePartListData: [],
        notFoundContent: "",
        sparePartFilter: {},

        
        // Yedek Parça Seçimi
        sparePartSelectSelectModal : false,
        sparePartSelectItem : {},

        // Parça kullanım durumu kontrolü
        spareUsedControl: false,
        

        actionRemove : false,

        // Parça Teslimat Tipi
        deliveryTypeName: null,
        selectDelivery : null,
        deliveryTypeList: [{ Name: 'Hemen', Id: 1 }, { Name: '2 - 3 İş Günü', Id: 2 }, { Name: '2 Hafta', Id: 3 }],
        deliveryTypeListSelect: [],

      };
    },
    components: {
      sparepartlistselect
    },
    props: [
        'offerid',
        'sparecontrol',
        'workorderid',
        'actionhidden',
        'appointmentstartdate',
        'appointmentenddate',
        'actionremove',
        'servicedetail'
    ],
    created() {
        

        if(this.sparecontrol == 1) this.sparePageTitle = "Kullanılan Parçalar"
        
        if(this.appointmentstartdate != null )  {
            this.spareUsedControl = true
            //console.log("parça kullanım durumu kontrol :" + this.appointmentstartdate)
        }


        if(this.sparecontrol == 0)  {
            this.actionRemove = this.actionremove
        }

        //appointmentenddate


-

        this.getItems()

    },
    watch: {
        sparePartSelectItem() {
            console.log(this.sparePartSelectItem)
            this.workItemData = this.sparePartSelectItem
            //console.log("fiyat: " + this.sparePartSelectItem.ListPrice)
        },

        appointmentstartdate(val) {
            if(val != null ) this.spareUsedControl = true
            console.log("parça kuallnım durumu:" + val)
        },

        servicedetail(val) {
            if(val.ResultType > 8 ) {
                this.actionRemove = true
            }
        }

    },
    methods: {

        // Yedek Parça Seçimi
        sparaPartSelectModalClick() {
            this.sparePartSelectSelectModal = true
            this.$root.sparePartSelectSelectModal = true
        },
        sparePartSelectModalClose() {
            this.sparePartSelectSelectModal = false
            this.$root.sparePartSelectSelectModal = false
        },
         // Yedek Parça Seçimi

        // Spara Part Form
        searchNoContent(event) {
            if(event.key > 3) {
                this.notFoundContent = "Kayıt bulunamadı"
            }
        },

        sparePartSearch(searchPart) {
            console.log(searchPart.length)
            if (searchPart.length >= 3) {
                this.sparePartFilter.Skip = 0
                this.sparePartFilter.PageSize = 20
                this.sparePartFilter.SparePartSearchText = searchPart
                console.log(this.sparePartFilter)
                this.$axios.get("/Offers/SearchSparePart", this.sparePartFilter).then(resp => {
                    this.sparePartListData =  resp.data.List;
                });
            }
        },
        
        // From Compamny
        sparaPartChange(event) {
            console.log(event)
        },

        // Form

        callback(key) {
            console.log(key);
            if(key == 1) { this.inVisible = false  } 
            if(key == 2) { this.inVisible = true  } 
        },

        WorkOrderItemDialog() {
            this.sparePartModalTitle= "Yedek Parça Ekle"
            this.sparePartDialogControl = 1
            this.WorkOrderItemDialogVisible = true

            this.selectDelivery = "Hemen"
            this.workItemData.DeliveryType = 1
        },

        WorkOrderItemDialogClose() {
            this.WorkOrderItemDialogVisible = false
            this.workItemData = {}
            this.Quantity = null
        },

        QuantityChange(event) {
            console.log(event)
            this.workItemData.Quantity = event
        },

        sparePartDialog(item) {
           this.sparePartModalTitle= "Yedek Parça"
           this.sparePartView = item
           this.workItemData.SparePartNumber = item.SparePartNumber
           this.workItemData.Quantity = item.Quantity
           this.Quantity = item.Quantity

           //console.log(item)
           this.sparePartDialogControl = 3  // 1: add, 2: edit, 3: view
           this.WorkOrderItemDialogVisible = true
        },
        
        sparePartEdit(sparePartView) {
            this.workItemData = sparePartView

            this.workItemData.ListPrice = this.sparePartView.Price

            this.deliveryTypeName = this.sparePartView.DeliveryName

            this.workItemData.DeliveryType = this.sparePartView.DeliveryType
            this.selectDelivery = this.deliveryTypeList.filter(x=> x.Id == this.workItemData.DeliveryType).map(v=> v.Name).toLocaleString()             

            //console.log(sparePartView)
            this.sparePartDialogControl = 2
        },

        onChangeDelivery(event) {
            this.workItemData.DeliveryType = event
            this.selectDelivery = this.deliveryTypeList.filter(x=> x.Id == event).map(v=> v.Name).toLocaleString()             
            //console.log(this.selectDelivery)
        },

        sparePartUsedRemove(sparePartView) {
            console.log(sparePartView)

            this.$axios.post("/offers/usesparepart?offerdetailId=" + sparePartView.Id).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    var successMessage= "İşlem başarılı"
                        
                    this.$message
                    .loading('Lütfen bekleyin..', 2.5)
                    .then(() => this.$message.success(successMessage, 2.5))
                    
                    this.WorkOrderItemDialogVisible = false
                    this.workItemData = {}
                    this.Quantity = null
                    this.getItems()
                }
                else {
                    this.$message
                        .loading('Lütfen bekleyin..', 2.5)
                        .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                }
            }).catch(e => { console.log(e) })
            
            //this.addWorkItemSubmit()
        },

        sparePartUsed(sparePartView) {
            //this.workItemData  = this.sparePartView
        
            if(this.spareUsedControl == true) {
                this.$axios.post("/offers/usesparepart?offerdetailId="+ sparePartView.Id + "&appointmentId=" + this.workorderid).then(resp => {
                    if (resp.data.IsSuccessful == true) {
                        var successMessage= "İşlem başarılı"
                            
                        this.$message
                        .loading('Lütfen bekleyin..', 2.5)
                        .then(() => this.$message.success(successMessage, 2.5))
                        
                        this.WorkOrderItemDialogVisible = false
                        this.workItemData = {}
                        this.Quantity = null
                        this.getItems()
                    }
                    else {
                        this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                    }
                }).catch(e => { console.log(e) })
            } else {
                this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error("Parçayı kullanmanız için işi başlatmanız gerekir.", 2.5))
            }

            /*
            if(sparePartView.Id >1 )  this.workItemData.Id = sparePartView.Id
            this.workItemData.AppointmentId = Number(this.$route.params.id)
            this.workItemData.SparePartNumber = sparePartView.SparePartNumber
            this.workItemData.OfferId = sparePartView.OfferId
            this.addWorkItemSubmit()*/
        },
        
        addWorkItemSubmit() {
            //console.log(this.workItemData)

            if(this.workItemData.DeliveryType == undefined) this.workItemData.DeliveryType = 1

            if(this.workItemData.SparePartNumber && this.workItemData.Quantity && this.workItemData.DeliveryType != null) {

                 if(this.sparePartDialogControl == 2)  {
                    this.workItemData.Id = Number(this.sparePartView.Id)

    
                    if (this.workItemData.CurrencyUnit == 1) {
                        this.sparePartView.Price =  this.workItemData.ListPrice / this.sparePartView.EuroRate
                        this.sparePartView.UnitPrice =  this.sparePartView.Price / this.sparePartView.EuroRate
                        this.workItemData.ListPrice = this.sparePartView.Price
                    }
                    
                }

                this.workItemData.CurrencyUnit = (this.workItemData.CurrencyUnit != null) ? this.workItemData.CurrencyUnit : 2

                if (this.workItemData.CurrencyUnit == 0) {
                    this.workItemData.CurrencyUnit = 1
                }

                if(this.sparePartDialogControl == 1)  {
                    this.workItemData.CurrencyUnit = 2
                }

                // Yeni
                //this.workItemData.SparePartName = this.workItemData.SparePartName
                //this.workItemData.Unit = this.workItemData.Unit
                this.workItemData.UnitPrice = this.workItemData.ListPrice

                this.workItemData.Discount = (this.workItemData.Discount != null) ?  this.workItemData.Discount : 0

                if(this.servicedetail.IsRentalProduct == true && this.servicedetail.RequestType != 15) {
                    this.workItemData.Discount = 45
                }


                this.workItemData.TotalAmount =  (this.workItemData.Quantity * this.workItemData.UnitPrice) - ((this.workItemData.Discount * (this.workItemData.Quantity * this.workItemData.UnitPrice)) / 100)


                this.workItemData.DeliveryType = Number(this.workItemData.DeliveryType)


                if(this.workItemData.AppointmentId != null) {
                    this.workorderid = this.workItemData.AppointmentId
                }

                this.workorderid =  Number(this.workorderid)

                this.workItemData.WorkOrderId = (this.workorderid != null && this.workorderid > 1) ? this.workorderid : null
                this.workItemData.Quantity = Number(this.workItemData.Quantity)
                this.workItemData.OfferId = Number(this.offerid)
                this.workItemData.IsVisible = true

                //console.log(this.workItemData)

                if(this.workItemData.CurrencyUnit == 3) {
                    this.$message
                                .loading('Lütfen bekleyin..', 2.5)
                                .then(() => this.$message.error("Parçanın para birimi DOLAR olduğundan işlem yapılamaz. Lütfen servis şefiniz ile iletişime geçiniz.", 2.5))
                } else {
                    this.$axios.post("/Offers/addOrUpdateOfferDetail?", this.workItemData).then(resp => {
                        if (resp.data.IsSuccessful == true) {
                            var successMessage= "Yedek parça güncelleme işlemi başarılı"

                             if(this.sparePartDialogControl == 2)  successMessage = "Yedek parça düzenleme başarılı"

                             this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.success(successMessage, 2.5))
                            
                            this.WorkOrderItemDialogVisible = false
                            this.workItemData = {}
                            this.Quantity = null
                            this.getItems()
                        }
                        else {
                            this.$message
                                .loading('Lütfen bekleyin..', 2.5)
                                .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                        }
                    }).catch(e => { console.log(e) })
                }

                    
                    
                
                        
            } else {
                this.$message
                    .loading('Lütfen bekleyin..', 2.5)
                    .then(() => this.$message.error("Lütfen eksik bilgileri giriniz.", 2.5))
            }
        },

        sparePartDelete() {
            if (confirm('Yedek parçayı silmek istediğinizden emin misiniz?')) {
                 this.$axios.post("/Offers/DeleteOfferDetail/" + this.sparePartView.Id).then(resp => {
                    if(resp.data.IsSuccessful == true) {
                         this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.success('Yedek parça silme işlemiş başarılı.', 2.5))
                            this.workItemData = {}
                             this.Quantity = null
                             this.getItems()
                            this.WorkOrderItemDialogVisible = false
                    } else {
                         this.$message
                                .loading('Lütfen bekleyin..', 2.5)
                                .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                    }
                });                
            }
        },
        
        getItems() {
             this.filter = { Skip: 0, PageSize: 200 }
             this.filter.OfferId = this.offerid

             this.$axios.get("/Offers/GetOfferDetailList?", { params: this.filter }).then(resp => {
                if (resp.data.IsSuccessful) {
                    this.workOrderItems = resp.data.List
                    
                    this.workOrderItems = this.workOrderItems.filter(function (datafilters) {
                         return datafilters.IsVisible != false;
                    });

                    if (this.sparecontrol == 1) {
                        this.dataFilterQuery = this.workOrderItems
                        var workOrderDataId = this.workorderid
                        this.filterInfoData = this.dataFilterQuery.filter(function (data) {
                            return data.AppointmentId == workOrderDataId
                        })
                        this.workOrderItems = this.filterInfoData
                    }

                    this.$emit('getsparepartdata', this.workOrderItems)

                    
                    //  if (this.sparecontrol == 0) {
                    //     this.dataFilterQuery = this.workOrderItems
                    //     this.filterInfoData = this.dataFilterQuery.filter(function (data) {
                    //         return data.AppointmentId == null
                    //     })
                    //     this.workOrderItems = this.filterInfoData
                    // }

                } else {
                   console.log("hata")
                }
            }).catch((error) => { console.log(error) })
        },


    },
  };
</script>

<style>
    .addWorkOrderItemDialog .ant-drawer-content-wrapper {
      width: 100% !important;
    }

    .addWorkOrderItemDialog  .ant-drawer-body {
      padding: 0px 0 !important;
    }
</style>
