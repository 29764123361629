<template>
  <div>
    <menus ref="openMenu"></menus>
    <div class="mheader" style="height: 63px; overflow: hidden;">
      <div class="row">
          <div class="col-xs-2">
              <svg @click="prevGo" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                <g id="back" transform="translate(-1)">
                  <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                </g>
              </svg>
          </div>
          <div class="col-xs-8">
              <span class="title">
                Sevk Detay - {{ this.$route.params.id }}
              </span>
          </div>
          <div class="col-xs-2 text-right">
             
              <a-dropdown :trigger="['click']" v-if="!item.ShippingStatus">
                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                  <svg class="href"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="3" height="15" viewBox="0 0 3 15">
                    <defs>
                      <clipPath id="clip-path">
                        <rect width="3" height="15" fill="none"/>
                      </clipPath>
                    </defs>
                    <g id="Options" clip-path="url(#clip-path)">
                      <path id="Union_1" data-name="Union 1" d="M0,13.5A1.5,1.5,0,1,1,1.5,15,1.5,1.5,0,0,1,0,13.5Zm0-6A1.5,1.5,0,1,1,1.5,9,1.5,1.5,0,0,1,0,7.5Zm0-6A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" fill="#fff"/>
                    </g>
                  </svg>
                </a>
                <a-menu slot="overlay">
                  <a-menu-item key="0">
                    <a @click="approveShipping">Onayla</a>
                  </a-menu-item>
                  <!-- <a-menu-item key="1" v-if="item.IsEditable">
                    <a @click="editShipping" >Düzenle</a>
                  </a-menu-item> -->
                  <!-- 
                  <a-menu-item key="2" v-if="item.IsDeleteable">
                    <a @click="deleteShipping">Sil</a>
                  </a-menu-item> -->
                </a-menu>
              </a-dropdown>
               
          </div>
      </div>
    </div>

    <div class="content tabstyle" style="padding-top: 63px;">
        <div>
            <a-tabs default-active-key="1" @change="callback">
                <a-tab-pane key="1" tab="Detay">
                    <div class="photoGroupItem">
                        <productlist :iseditable="this.item.IsEditable" @productlist="productDataList=$event" v-if="this.item.Id" ref="dataReflesh" :shippingdata="shippingGetData"></productlist>
                    </div>
                </a-tab-pane>

                <a-tab-pane key="2" tab="Bilgi" force-render>


                     <div class="pageHeader">
                        <h3>Genel Bilgiler</h3>
                    </div>
                    <div class="detailBox">
                        <ul>
                            <li>
                                <span>Sevk Tipi</span>
                                <span>{{ this.item.ShippingTypeName }}</span>
                            </li>
                            <li>
                                <span>Onay Durumu</span>
                                <span>{{ this.item.ShippingStatus ? 'Onaylı' : 'Onaysız' }}</span>
                            </li>
                        </ul>
                     </div>


                    <div class="pageHeader">
                        <h3>Gönderen</h3>
                    </div>
                    <div class="detailBox">
                        <ul>
                            <li>
                                <span>Firma Adı</span>
                                <span>{{ this.item.FromCompanyName }}</span>
                            </li>
                            <li>
                                <span>Sevk Tarihi</span>
                                <span>{{new Date(this.item.FromDate).toLocaleString().split(' ')[0]}} / {{ this.item.FromHours }}</span>
                            </li>
                            <li>
                                <span>Lokasyon</span>
                                <span>{{ this.item.FromLocationName }}</span>
                            </li>
                        </ul>
                     </div>


                    <div class="pageHeader">
                        <h3>Alıcı</h3>
                    </div>
                    <div class="detailBox">
                        <ul>
                            <li>
                                <span>Firma Adı</span>
                                <span>{{ this.item.ToCompanyName }}</span>
                            </li>
                            <li>
                                <span>Sevk Tarihi</span>
                                <span>{{new Date(this.item.ToDate).toLocaleString().split(' ')[0]}} / {{ this.item.ToHours }}</span>
                            </li>
                            <li>
                                <span>Lokasyon</span>
                                <span>{{ this.item.ToLocationName }}</span>
                            </li>
                        </ul>
                     </div>

                     

                    <div class="pageHeader">
                        <h3>Diğer</h3>
                    </div>
                    <div class="detailBox">
                        <ul>
                           <li>
                                <span>Onaylayan</span>
                                <span v-if="this.item.ApprovalDate">{{ this.item.ApproverUserName }} ( {{new Date(this.item.ApprovalDate).toLocaleString() }} )</span>
                            </li>

                            <li>
                                <span>Oluşturan</span>
                                <span>{{ this.item.CreateUserName }} ( {{new Date(this.item.CreateDate).toLocaleString() }} )</span>
                            </li>
                            <li>
                                <span>Güncelleyen</span>
                                <span v-if="this.item.UpdateDate">{{ this.item.UpdateUserName }} ( {{new Date(this.item.UpdateDate).toLocaleString() }} )</span>
                            </li>
                        </ul>
                     </div>

                     <!-- <div class="pageHeader">
                        <h3>Sevk Bilgileri</h3>
                     </div>
                     <div class="content shippingList shippingDetail">
                        <div class="list">
                              <div class="row" :class="{ approved: item.ShippingStatus }">
                                  <div class="col-xs-6 shippingReagent padding">
                                      <span class="title" :title="item.FromCompanyName">{{ item.FromCompanyName }}</span>
                                      <ul>
                                        <li><i class="fas fa-clock"></i> {{new Date(item.FromDate).toLocaleString().split(' ')[0]}} / {{ item.FromHours }}</li>
                                        <li><i class="fas fa-map-marker-alt"></i> {{ item.FromLocationName }}</li>
                                      </ul>
                                  </div>
                                  <div class="col-xs-6 padding">
                                      <span class="title" :title="item.ToCompanyName">{{ item.ToCompanyName }}</span>
                                      <ul class="bsx">
                                        <li><i class="fas fa-clock"></i> {{new Date(item.ToDate).toLocaleString().split(' ')[0]}} / {{ item.ToHours }}  </li>
                                        <li><i class="fas fa-map-marker-alt"></i> {{ item.ToLocationName }}</li>
                                      </ul>
                                  </div>
                                  <svg class="shippingStatusIcon" xmlns="http://www.w3.org/2000/svg" width="29.333" height="29.333" viewBox="0 0 29.333 29.333">
                                    <g id="Path_206" data-name="Path 206" fill="#ff1119">
                                      <path d="M 14.66668701171875 26.83337783813477 C 11.41684722900391 26.83337783813477 8.361516952514648 25.56781768798828 6.063537120819092 23.26983642578125 C 3.765557050704956 20.97185707092285 2.499996900558472 17.91652679443359 2.499996900558472 14.66668701171875 C 2.499996900558472 11.41684722900391 3.765557050704956 8.361516952514648 6.063537120819092 6.063537120819092 C 8.361516952514648 3.765557050704956 11.41684722900391 2.499996900558472 14.66668701171875 2.499996900558472 C 17.91652679443359 2.499996900558472 20.97185707092285 3.765557050704956 23.26983642578125 6.063537120819092 C 25.56781768798828 8.361516952514648 26.83337783813477 11.41684722900391 26.83337783813477 14.66668701171875 C 26.83337783813477 17.91652679443359 25.56781768798828 20.97185707092285 23.26983642578125 23.26983642578125 C 20.97185707092285 25.56781768798828 17.91652679443359 26.83337783813477 14.66668701171875 26.83337783813477 Z" stroke="none"/>
                                      <path d="M 14.66668701171875 4.999996185302734 C 9.336456298828125 4.999996185302734 4.999996185302734 9.336456298828125 4.999996185302734 14.66668701171875 C 4.999996185302734 19.99691772460938 9.336456298828125 24.33337783813477 14.66668701171875 24.33337783813477 C 19.99691772460938 24.33337783813477 24.33337783813477 19.99691772460938 24.33337783813477 14.66668701171875 C 24.33337783813477 9.336456298828125 19.99691772460938 4.999996185302734 14.66668701171875 4.999996185302734 M 14.66668701171875 -3.814697265625e-06 C 22.76687622070313 -3.814697265625e-06 29.33337783813477 6.566497802734375 29.33337783813477 14.66668701171875 C 29.33337783813477 22.76687622070313 22.76687622070313 29.33337783813477 14.66668701171875 29.33337783813477 C 6.566497802734375 29.33337783813477 -3.814697265625e-06 22.76687622070313 -3.814697265625e-06 14.66668701171875 C -3.814697265625e-06 6.566497802734375 6.566497802734375 -3.814697265625e-06 14.66668701171875 -3.814697265625e-06 Z" stroke="none" fill="#fff"/>
                                    </g>
                                    <g id="Forward_arrow" data-name="Forward arrow" transform="translate(6.333 8.333)">
                                      <path id="Path_10" data-name="Path 10" d="M6.328,0,5.178,1.151,9.252,5.225l.282.282h0l.752.822L5.178,11.506l1.151,1.151,6.328-6.328Z" fill="#fff"/>
                                    </g>
                                  </svg>
                              </div>
                        </div>
                      </div> -->
                    
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>


  </div>
</template>

<script>
  import Menus from '../Menu';
  import productlist from './shippingProduct';
  export default {
    name: "App",
    data() {
      return {
        item: {},
        queryId: '',
        shippingGetData: {},
        addProduct : {},

        productDataList: [],
      };
    },
    components: {
      Menus,
      productlist
    },
    created() {
        this.getShippingDetail()
        /*
        // Tarayıcınız  Geolocation API desteği var mı?
          if (!navigator.geolocation) {
            alert('Tarayıcınız Geolocation desteği bulunmuyor')
          } else {
            navigator.geolocation.getCurrentPosition(function (position) {
              // Mevcut pozisyonumuzu alıyoruz
              var lat = position.coords.latitude
              var lng = position.coords.longitude
              alert("latitude:"+ lat + " longitude:" +lng)
          })
        }*/
    },
    methods: {
        callback(key) {
            console.log(key);
            if(key == 1) { this.inVisible = false  } 
            if(key == 2) { this.inVisible = true  } 
        },
        
        openMenu () {
          this.$refs.openMenu.showDrawer()
        },

        prevGo () {
          this.$router.push("/shippings")
          //this.$router.go(-1)
        },
        
        approveShipping() {
          
            var ApproveRequired = false
            var arrayData = []
            arrayData = this.productDataList.filter(data => data.PhotoStatus == false);


            if(this.productDataList.length > 0) {
              ApproveRequired = (arrayData.length == 0) ? true : false
            } else {
              ApproveRequired = false
            }

            // if(this.item.ShippingType == 4) {
            //     ApproveRequired = true
            // }

            if (ApproveRequired == true) {
                  if (confirm('Sevk onaylanacak. İşleme devam etmek istiyor musunuz?')) {
                      this.loadingDialog = true
                      this.$axios.post("/shippings/ApproveShipping/" + this.item.Id).then(resp => {
                          if (resp.data.IsSuccessful == true) {
                              this.$message.loading({ content: 'Lütfen bekleyin...' });
                              setTimeout(() => {
                                this.dataReflesh()
                                this.$message.success({ content: 'Sevk başarıyla onaylandı.', duration: 2 });
                              }, 1000);
                              this.getShippingDetail()
                          } else {
                              this.$message.loading({ content: 'Lütfen bekleyin...' });
                                setTimeout(() => {
                                  this.dataReflesh()
                                  this.$message.error({ content: resp.data.ExceptionMessage, duration: 2 });
                                }, 1000);
                          }
                      }).catch(e => { console.log(e) })
                      this.snackbar = false
                      this.snackbartext = ""
                  }

              } else {
                  this.$message.loading({ content: 'Lütfen bekleyin...' });
                                setTimeout(() => {
                                  this.dataReflesh()
                                  this.$message.error({ content: "Lütfen ürünlerde gerekli alanları kontrol ediniz!", duration: 2 });
                                }, 1000);
              }
               
        },

        dataReflesh () {
          this.$refs.dataReflesh.getItems()
        },

        getShippingDetail() {
            this.queryId = this.$route.params.id
            this.$axios.get("/Shippings/GetShipping/" + this.queryId).then(response => {
                this.item = response.data.Entity
                this.shippingGetData = { ShippingId: this.item.Id, ShippingStatus :  this.item.ShippingStatus, FromLocationId :  this.item.FromLocationId,}

                if (this.item.ShippingType == 1) this.item.ShippingTypeName = "Giden Sevk"
                if (this.item.ShippingType == 2) this.item.ShippingTypeName = "Gelen Sevk"
                if (this.item.ShippingType == 3) this.item.ShippingTypeName = "Transfer"
                if (this.item.ShippingType == 4) this.item.ShippingTypeName = "Müşteri Arası Transfer"

                
                if (this.$route.query.productId) {
                  this.addProduct = { CompanyId : this.item.ToCompanyId,  CompanyLocationId: this.item.ToLocationId, ProductId: this.$route.query.productId, ShippingId:this.item.Id  }
                  this.getAddProduct()
                }
                
            })
        },
        
        getAddProduct() {
            console.log(this.addProduct)
            this.$axios.post("/shippings/AddOrUpdateProduct?", this.addProduct).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.$message.loading({ content: 'Ekleniyor...' });
                    setTimeout(() => {
                      this.dataReflesh()
                      this.$message.success({ content: 'Ürün sevke eklendi.', duration: 2 });
                    }, 1000);

                } else {
                    this.$message.loading({ content: 'Ekleniyor...' });
                    setTimeout(() => {
                      this.dataReflesh()
                      this.$message.error({ content: resp.data.ExceptionMessage, duration: 2 });
                    }, 1000);
                }

            }).catch(e => { console.log(e) })
        },

        editShipping() {
            var editUrl = "/shipping/edit/" + this.queryId
            //console.log(editUrl)
            this.$router.push(editUrl)
        },

        deleteShipping() {
             this.$axios.post("/shippings/delete/" + this.queryId).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.$message.loading({ content: 'Lütfen bekleyin...' });
                    setTimeout(() => {
                      this.dataReflesh()
                      this.$message.success({ content: 'Sevki silme işlemi başarılı.', duration: 2 });
                      setTimeout(() => { this.$router.push("/shippings") }, 1000);
                    }, 1000);
                   
                } else {
                    this.$message.loading({ content: 'Lütfen bekleyin...' });
                    setTimeout(() => {
                      this.dataReflesh()
                      this.$message.error({ content: resp.data.ExceptionMessage, duration: 2 });
                    }, 1000);
                }
            }).catch(e => { console.log(e) })
        }
        
    },
  };
</script>

<style></style>