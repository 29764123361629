<template>
  <div>
    <menus ref="openMenu"></menus>

    <div class="mheader" style="height: 130px; overflow: hidden;">
      <div class="row">
          <div class="col-xs-2">
              <svg @click="openMenu" class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
                <path id="Menu" d="M-6160,12V10.285h8.751V12Zm0-5.143V5.143h14V6.857Zm0-5.143V0h14V1.715Z" transform="translate(6160)" fill="#fff"/>
              </svg>
          </div>
          <div class="col-xs-8">
              <span class="title">
                Müşteriler
              </span>
          </div>
          <div class="col-xs-2 text-right">
              <a href="#">
                <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
                    <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#fff"/>
                </svg>
              </a>
          </div>
          <div class="clear"></div>
          <div class="col-xs-12">
              <div class="searchInput" style="width: 100%; display: inline-block; float: left;">
                   <!-- <a-input-search placeholder="Arama yapın" @search="onSearch" />  -->
                  <a-select
                    v-model="selectCompany"
                    mode="default"
                    placeholder="Arama Yap"
                    :showSearch="true"
                    label-in-value
                    :value="value"
                    :filter-option="false"
                    @search="companySearch"
                    @change="compamySearchChange($event)">
                    <a-icon slot="suffixIcon" type="search" />
                    <a-select-option v-for="d in data" :key="d.Id">
                      {{ d.Name }}
                    </a-select-option>
                  </a-select>
              </div>
          </div>
      </div>
    </div>


   <div class="content shippingList ProductListPage" style="padding-top: 130px;">
         <div class="ShippingProductList row">
            <div class="col-md-12">
                <div class="itemProduct row" v-for="item in dataquery" :key="item.SerialNo">
                    <a class="href" @click="goDetail(item)">
                        <div class="col-xs-12">
                            <div class="title">
                                <span style="font-size: 14px;" class="serino" :title="item.Name">{{ item.Name }}</span>
                            </div>
                        </div>
                    </a>
                </div>

            </div>

        </div>
        
        <div class="onLoadBtn" v-if="showLoadingMore" slot="loadMore" :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }">
          <a-spin v-if="loadingMore" />
          <a-button v-else @click="onLoadMore">
              daha fazla...
          </a-button>
        </div>

    </div>


  </div>
</template>

<script>
  import Menus from '../Menu';
  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        moment,
        testdata : "555",
        dataquery: [],
        companyList: [],
        searchValue: '',
        searchCompany: null,
        
        // Filter
        filter: { Skip: 0, PageSize: 20 },

        data: [],
        value: '',
        selectCompany: [],

        // Scroll
        loading: true,
        loadingMore: false,
        showLoadingMore: true,
        
      };
    },
    components: {
      Menus
    },
    created() {
      var onLoadMore = false
      this.getItems(onLoadMore)
    },
    methods: {

      compamySearchChange(event) {
        var onLoadMore = false
        console.log(event)
        this.filter.Id = event.key
        this.getItems(onLoadMore)
      },

      openMenu () {
        this.$refs.openMenu.showDrawer()
      },
      
      companySearch(searchCompany) {
        if (searchCompany.length >= 3) {
           this.$axios.get("/company/searchcompany/" + searchCompany + "/0/10/").then(resp => {
              this.data =  resp.data.List;
          });
        }
      },
      getItems(onLoadMore) {
          if(onLoadMore == true) {
            this.filter = { Skip: this.filter.Skip + 20, PageSize: 20 }
          }

          this.$axios.get("/company/CompanyList", { params: this.filter }).then(resp => {
              if (resp.data.IsSuccessful) {
                  if(onLoadMore == false) {
                      this.dataquery = resp.data.List
                  } else {
                     this.dataquery = this.dataquery.concat(resp.data.List),
                     this.loadingMore = false
                  }
              } 
          }).catch((error) => { console.log(error) })
      },

      onLoadMore() {
        this.loadingMore = true
        var onLoadMore = true
        this.getItems(onLoadMore)
      },

    },
  };
</script>

<style></style>