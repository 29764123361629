<template>
  <div>
    <menus ref="openMenu"></menus>
    <div class="mheader" style="height: 63px; overflow: hidden;">
        <div class="row">
            <div class="col-xs-2">
                <svg @click="prevGo" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                    <g id="back" transform="translate(-1)">
                        <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                    </g>
                </svg>
            </div>
            <div class="col-xs-8">
                <span class="title">
                    {{ this.pageControlTitle }}
                </span>
            </div>
            <div class="col-xs-2 text-right formBtn">

                <div v-if="this.pageControl != 1">
                    <a-dropdown :trigger="['click']">
                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                            <svg class="href"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="3" height="15" viewBox="0 0 3 15">
                                <defs>
                                <clipPath id="clip-path">
                                    <rect width="3" height="15" fill="none"/>
                                </clipPath>
                                </defs>
                                <g id="Options" clip-path="url(#clip-path)">
                                <path id="Union_1" data-name="Union 1" d="M0,13.5A1.5,1.5,0,1,1,1.5,15,1.5,1.5,0,0,1,0,13.5Zm0-6A1.5,1.5,0,1,1,1.5,9,1.5,1.5,0,0,1,0,7.5Zm0-6A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" fill="#fff"/>
                                </g>
                            </svg>
                        </a>
                        <a-menu slot="overlay">
                            <a-menu-item key="0">
                                <a @click="startWork('photoDetail')">Fotoğraflar</a>
                            </a-menu-item>
                            <a-menu-item key="0" v-if="info.Status ==1 || info.Status ==2">
                                <a @click="appointmentEdit">Düzenle</a>
                            </a-menu-item>
                            <!-- <a-menu-item key="0"  v-if="info.Status ==1">
                                <a @click="appointmentCancel">İptal</a>
                            </a-menu-item> -->
                        </a-menu>
                    </a-dropdown>
                </div>
                
                <!-- <div v-if="actionHidden">
                    <a-dropdown :trigger="['click']">
                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                        <svg class="href"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="3" height="15" viewBox="0 0 3 15">
                            <defs>
                            <clipPath id="clip-path">
                                <rect width="3" height="15" fill="none"/>
                            </clipPath>
                            </defs>
                            <g id="Options" clip-path="url(#clip-path)">
                            <path id="Union_1" data-name="Union 1" d="M0,13.5A1.5,1.5,0,1,1,1.5,15,1.5,1.5,0,0,1,0,13.5Zm0-6A1.5,1.5,0,1,1,1.5,9,1.5,1.5,0,0,1,0,7.5Zm0-6A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" fill="#fff"/>
                            </g>
                        </svg>
                        </a>
                        <a-menu slot="overlay">
                            <a-menu-item key="0"  v-if="!this.editedItem.EndDate">
                                <a @click="startWork" v-if="this.editedItem.StartingDate == null">İşe Başla</a>
                                <a @click="startWork('photo')" v-if="this.editedItem.StartingDate == null">İşe Başla</a>
                                <a @click="customerApprovalAction" v-if="this.editedItem.StartingDate != null && this.editedItem.EndDate == null ">Müşteri Onayı</a>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </div> -->
                
            </div>
        </div>
    </div>

    <div class="content tabstyle" style="padding-top: 63px;">
        <div>
            <a-tabs :default-active-key="3" @change="callback">
                <a-tab-pane key="3" tab="Detay" >
                    <!-- <div class="content tabstyle" style="padding-top: 0px; padding-bottom: 0px;">
                        <div class="subModule" style="margin: 0;">
                            <div class="pageHeader moduleHeader blue2aa">
                                <div class="left">
                                    <h3>Yedek Parçalar</h3>
                                </div>
                            
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <sparepartlist v-if="info.Id" :offerid="this.info.OfferId" :sparecontrol="1" :workorderid="this.info.Id"></sparepartlist> -->
                    <sparepartlist v-if="this.pageControl == 1 && info.Id" :appointmentenddate="this.EndDate" :appointmentstartdate="this.StartingDateData" :offerid="this.info.OfferId" :actionhidden="actionHidden" :sparecontrol="0" :workorderid="this.info.Id"></sparepartlist>
                    <sparepartlist v-if="this.pageControl != 1 && info.Id" :appointmentenddate="this.EndDate" :appointmentstartdate="this.StartingDateData" :offerid="this.info.OfferId" :actionhidden="actionHidden" :sparecontrol="1" :workorderid="this.info.Id"></sparepartlist>

                    <!-- <div class="content tabstyle" style="padding-top: 0px; padding-bottom: 0px;">
                        <div class="subModule" style="margin: 0;">
                            <div class="pageHeader moduleHeader blue2" @click="startWork('photoDetail')">
                                <div class="left">
                                    <h3>Fotoğraflar</h3>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>  -->
                </a-tab-pane>

                <a-tab-pane key="2" tab="Bilgi" force-render>
                    <div class="pageHeader">
                        <h3>Randevu Detayı</h3>
                    </div>
                    <div class="detailBox">
                        <ul>
                            <li>
                                <span>Firma Adı</span> 
                                <span>{{ this.info.CompanyName }}</span>
                            </li>
                            
                            <li v-if="this.info.Location">
                                <span>Lokasyon</span> 
                                <span>{{ this.info.Location.CityName + " / " + this.info.Location.RegionName +  ((this.info.Location.PlaceName != null) ? " / " + this.info.Location.PlaceName : "") }}</span>
                            </li>
                            <li>
                                <span>Teknisyen</span> 
                                <span>{{ this.info.TechnicianName }}</span>
                            </li>
                            <li>
                                <span>Çalışma Saati</span> 
                                <span>{{ this.info.WorkTimeValue }}</span>
                            </li>
                            <li>
                                <span>Lokasyon Mesafesi</span> 
                                <span>{{ this.info.Distance }}</span>
                            </li>
                            <li>
                                <span>Randevu Tarihi</span> 
                                <span v-if="this.info.AppointmentDate">{{new Date(this.info.AppointmentDate).toLocaleString().split(' ')[0]}} / {{ this.info.AppointmentHour }}</span>
                            </li>
                            <li>
                                <span>İşin Başlama Tarihi</span> 
                                <span v-if="this.info.StartingDate">{{new Date(this.info.StartingDate).toLocaleString()}}</span>
                            </li>
                            <li>
                                <span>İşin Bitiş Tarihi</span> 
                                <span v-if="this.info.EndDate">{{new Date(this.info.EndDate).toLocaleString()}}</span>
                            </li>
                            <li>
                                <span>Onaylayan</span> 
                                <span>{{ this.info.ContactName }}</span>
                            </li>
                            <li>
                                <span>Yapılan İş</span> 
                                <span>{{ this.info.WorkDoneDescription }}</span>
                            </li>
                        </ul>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
    
    <!-- Müşteri Onay Modal -->
    <a-drawer
        placement="right"
        :closable="false"
        :visible="customerApprovalVisible"
        wrapClassName="customerApprovalDialog"
        @close="customerApprovalClose">

        <div class="mheader" style="height: 66px; overflow: hidden;">
            <div class="row">
                <div class="col-xs-2">
                    <svg @click="customerApprovalClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                        <g id="back" transform="translate(-1)">
                            <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                        </g>
                    </svg>
                </div>
                <div class="col-xs-8">
                    <span class="title">
                        Müşteri Onayı
                    </span>
                </div>
                <div class="col-xs-2 text-right formBtn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                        <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                    </svg>
                </div>
            </div>
        </div>

        <div class="content" style="padding-top: 63px;">
            <div class="formStyle" style="margin-top: 25px;">
                <form class="ant-form ant-form-horizontal">
                    <div class="ant-row ant-form-item">
                        <div class="ant-col ant-form-item-label">
                            <label title="Adet" class="">SMS ŞİFRE ONAYI</label>
                        </div>
                        <div class="ant-col ant-form-item-control-wrapper">
                            <div class="ant-form-item-control">
                                <a-input-number placeholder="Sms"/>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </form>
            </div>
        </div>
    </a-drawer>
    <!-- Müşteri Onay Modal -->

     <a-drawer
      placement="right"
      :closable="false"
      :visible="appointmentCreateModal"
      wrapClassName = "appointmentCreateModal"
      @close="appointmentCreateModalClose">
      
        <appointmentcreate :formcontrol="1" :appointmentdata="this.info" :servicedata="this.serviceDetail" :servicedetaildata="this.info" @parentmodalclose="appointmentCreateModalClose" @parentmodalsuccessclose="appointmentCreateModalSuccess"></appointmentcreate>
    </a-drawer>

 
  </div>
</template>

<script>
  import Menus from '../Menu';
  import sparepartlist from './sparePart';
  import appointmentcreate from './appointmentCreate.vue';
  export default {
    name: "App",
    data() {
      return {
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'coordinated' }),
        info : [],
        filter: { Skip: 0, PageSize: 10 },
        editedItem : {},

        // Edit Buton
        inVisible : false,

        // Page Control
        pageControl: null,
        pageControlTitle : "Randevu Detay",

        workOrderId : null,

        // Photo Control
        photocontrol: null,

        // Service Detail Data
        serviceDetail : {},

        // Müşteri Onay
        customerApprovalVisible: false,

        // Action Btn Hidden
        actionHidden:true,

        OfferId:null,

        OfferDetail: {},

        StartingDateData: null,
        EndDate:null,


        // Tab
        dafaultTab: 2,

        // Randevu
        appointmentCreateModal: false,
        modalDatas : {},

      };
    },
    components: {
      Menus,
      sparepartlist,
      appointmentcreate
    },
    created() {
        this.workOrderId = this.$route.params.id
        this.photocontrol = this.$route.query.photocontrol
        this.OfferId = this.$route.query.OfferId

        //console.log(this.$route.query)
        this.pageControl = this.$route.query.page

        if(this.pageControl == 1) {
            this.pageControlTitle = "Teklif / Servis Formu"
            //this.filter.OfferId = this.OfferId
            this.filter.AppointmentId = this.workOrderId 

            this.getOfferDetail()

        } else {
            this.dafaultTab = 2
            this.pageControlTitle = "Randevu Detay"
            this.filter.AppointmentId = this.workOrderId 
            this.actionHidden = false
        }

        this.getWorkOrderDetail()
    },
    watch: {
        
    },
    methods: {

         // Randevu
        appointmentEdit() {
            this.appointmentCreateModal = true

        },

        appointmentCancel() {
            //this.appointmentCreateModal = true
            //this.appointmentDataCancel = true

            if(this.info.UsedPartCount == 0) {
                this.info.Status = 3
                this.info.RequestId = this.info.ServiceRequestId

                this.$axios.post("/Offers/AddOrUpdateAppointment?", this.info).then(resp => {
                        if (resp.data.IsSuccessful == true) {
                            this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.success("Randevu iptal edildi.", 2.5))
                            this.createId = resp.data.Entity
                            this.prevGo()
                        }
                        else {
                            this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error("Randevu iptal edilirken hata oluştu", 2.5))
                        }
                    }).catch(e => { console.log(e) })
                } else {
                    this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error("Randevuda parça kullanıldığı için iptal edilemez", 2.5))
                }

        },

        appointmentCreateModalClose() {
            this.appointmentCreateModal = false
            this.$emit('appointmentaddmodalclose')
        },

        appointmentCreateModalSuccess() {
            this.appointmentCreateModal = false
            this.$emit('parentmodalsuccess')
        },

        // Randevu

        
        callback(key) {
            console.log(key);
            if(key == 1) { this.inVisible = false  } 
            if(key == 2) { this.inVisible = true  } 
        },

        openMenu () {
          this.$refs.openMenu.showDrawer()
        },
        
        prevGo () {
            var prevGo = "/service/" + this.info.ServiceRequestId
            this.$router.push(prevGo)
        },

        startWork(type) {
            // this.$route.params.id
            // var startingdate= new Date()
            // console.log(startingdate)
            // this.editedItem.StartingDate = startingdate
            // this.workOrderUpdate()
            if (type == 'photo') {
                //photo/photoDetail?dataProductId=307&sourceType=3
                var prevGo = "/photo/photoDetail?dataProductId=" + this.info.WorkTimeId + "&sourceType=2&page=service&start=1" + "&workOrderId=" + this.workOrderId
                this.$router.push(prevGo)
            } 

            if (type == 'photoDetail') {
                //photo/photoDetail?dataProductId=307&sourceType=3
                var EndDateControl = false
                if(this.info.EndDate != null) {
                    EndDateControl = true
                }

                if(this.info.WorkTimeId != null) {
                    prevGo = "/photo/photoDetail?dataProductId=" + this.info.WorkTimeId + "&sourceType=2&page=service" + "&workOrderId=" + this.workOrderId + "&appointment=1&EndDateControl=" +  EndDateControl
                    this.$router.push(prevGo)
                } else {
                    this.$message.warning('Bu randevuya ait fotoğraf kaydı bulunamadı!');
                }
                
            } 

            if (type == 'successful') {
                if(this.serviceDetail.IsProductVerified == true) {
                    console.log(this.editedItem)
                    var startingdate= new Date()
                    this.editedItem.RequestId = this.editedItem.ServiceRequestId
                    this.editedItem.StartingDate = startingdate
                    console.log("başla " + this.editedItem.StartingDate)
                    console.log(this.editedItem)

                    this.workOrderUpdate()
                }  else {
                        this.$message.warning('Lütfen ürünü doğrulayın!');
                }
            }
        },

        // Müşteri Onayı
        customerApprovalAction() {
           this.customerApprovalVisible = true
        },
        
        customerApprovalClose() {
            this.customerApprovalVisible = false
        },
        // Müşteri Onayı

        stopWork() {
            //this.$route.params.id
            var EndDate= new Date()
            console.log(EndDate)
            this.editedItem.EndDate = EndDate

            console.log(this.editedItem)
            //this.workOrderUpdate()
        },

        getWorkOrderDetail() {
             this.$axios.get("/Offers/GetAppointmentList?", { params: this.filter }).then(resp => {
                if (resp.data.IsSuccessful) {

                    
                    if(this.workOrderId > 0) {
                        this.info = resp.data.List[0], this.loadingDialog = false
                        this.editedItem  = this.info
                        
                        if(this.info.StartingDate != null) {
                            this.StartingDateData = this.info.StartingDate
                        }

                        if(this.info.EndDate != null) {
                            this.EndDate = this.info.EndDate
                        }
                    }
                    
                    this.$axios.get("/ServiceRequests/Get/" + this.info.ServiceRequestId).then(resp => {
                        if (resp.data.IsSuccessful) {
                            this.serviceDetail = resp.data.Entity, this.loadingDialog = false
                            if(this.photocontrol==1) {
                                this.startWork('successful')
                            }
                        } else {
                        console.log("hata")
                        }
                    }).catch((error) => { console.log(error) })
                    
                    this.getSparePart()

                } else {
                   console.log("hata")
                }
            }).catch((error) => { console.log(error) })
            
        },

        getOfferDetail() {
            this.$axios.get("/Offers/Get/" + this.OfferId).then(resp => {
                if (resp.data.IsSuccessful) {
                    this.OfferDetail = resp.data.Entity
                } else {
                    console.log("hata")
                }
            }).catch((error) => { console.log(error) })     
        },

        getSparePart() {
            this.filter = { Skip: 0, PageSize: 10 }
            this.filter.OfferId = this.info.OfferId
            
            this.$axios.get("/Offers/GetOfferDetailList?", { params: this.filter }).then(resp => {
                if (resp.data.IsSuccessful) {
                    this.workOrderItems = resp.data.List
                } else {
                    console.log("hata")
                }
            }).catch((error) => { console.log(error) })
        },

        workOrderUpdate() {
            this.$axios.post("/Offers/AddOrUpdateAppointment?", this.editedItem).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.success('İşe başlama bilgisi sisteme iletilmiştir.', 2.5))

                    this.getSparePart()
                }
            }).catch(e => { console.log(e) })
        },
        
    },
  };
</script>

<style>
    .addWorkOrderItemDialog .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .addWorkOrderItemDialog  .ant-drawer-body {
        padding: 0px 0 !important;
    }

    .customerApprovalDialog .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .customerApprovalDialog  .ant-drawer-body {
        padding: 0px 0 !important;
    }
</style>