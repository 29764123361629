<template>
  <div>
    <menus ref="openMenu"></menus>
    <div class="mheader" style="height: 63px; overflow: hidden;">
      <div class="row">
          <div class="col-xs-2">
              <svg @click="prevGo" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                <g id="back" transform="translate(-1)">
                  <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                </g>
              </svg>
          </div>
          <div class="col-xs-8">
              <span class="title">
                 {{ this.pageTitle }}
              </span>
          </div>
          <div class="col-xs-2 text-right formBtn">
                <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit" v-if="!this.handleSubmitDisable">
                    <a-form-item :wrapper-col="{ span: 12 }">
                        <a-button type="primary" html-type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                            </svg>
                        </a-button>
                    </a-form-item>
                </a-form>
          </div>
      </div>
    </div>

    <div class="content" style="padding-top: 63px;">
        <div class="formStyle">
            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">

                <div class="pageHeader">
                    <h3>Genel Bilgi</h3>
                </div>


                <div @click="companySelectAction">
                    <a-form-item label="Firma seçiniz">
                        <a-select
                            label-in-value
                            :filter-option="false"
                            placeholder = "Firma seçiniz"
                            notFoundContent= ""
                            :disabled="(disableCompany == true) ? true : disableCustom"
                            v-decorator="['CompanyId', { initialValue: companyselect[0], rules: [{ required: true, message: 'Firma seçimi alanı zorunludur!' }] }]">
                            <a-select-option v-for="item in companyselect" :key="item.key">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </div>

                <!-- <a-form-item label="Firma Adı">
                     <a-select
                        mode="default"
                        placeholder="Firma arayınız"
                        :showSearch="true"
                        label-in-value
                        :filter-option="false"
                        :notFoundContent= "notFoundContent"
                        :disabled="disableCustom"
                        v-decorator="['CompanyId', { initialValue: companyData[0], rules: [{ required: true, message: 'Gönderen firma alanı zorunludur!' }] }]"
                        @search="companySearch"
                        @inputKeydown="searchNoContent($event)"
                        @change="companySearchChange($event)">
                        <a-icon slot="suffixIcon" type="search" />
                        <a-select-option v-for="c1 in companyData" :key="c1.Id">
                            {{ c1.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item> -->
                
                <a-form-item label="Lokasyon">
                    <a-select
                        label-in-value
                        v-decorator="['CompanyLocationId', { initialValue: locationsList[0], rules: [{ required: true, message: 'Gönderen lokasyon alanı zorunludur!' }] }]"
                        :disabled="(disableExpertiz == true) ? true : disableCustom"
                        placeholder="Lokasyon seçiniz"
                        notFoundContent= "Lütfen firma seçiniz"
                        @change="locationChange($event)">
                        <a-select-option v-for="item in locationsList" :key="item.Id" :value="item.Id">
                            {{ item.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>


                <div @click="productSelectModalClick">
                    <a-form-item label="Ürün Seri No">
                        <a-select
                            :showSearch="true"
                            label-in-value
                            :filter-option="false"
                            placeholder = "Ürün seçiniz"
                            notFoundContent= ""
                            v-decorator="['ProductId', { initialValue: productlist[0], rules: [{ required: true, message: 'Ürün seri no alanı zorunludur!' }] }]"
                            :disabled="disableCustom"
                            @search="productSearch"
                            @change="productChange($event)">
                            <a-select-option v-for="item in productlist" :key="item.Id" :value="item.Id">
                                {{ item.Name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </div>
                
                <a-form-item label="Çalışma Saati" v-if="this.editedItem.IsEquipment == false">
                  <a-input-number placeholder="Çalışma saati"  id="inputNumber" :min="0" v-decorator="['WorkTimeValue', { initialValue: dataForm.WorkTimeValue, rules: [{ required: true, message: 'Lütfen çalışma saati bilgisini giriniz.' }] }, ]" />
                </a-form-item>

                <div class="clearfix"></div>

            </a-form>
        </div>
    </div>

      <a-drawer
      placement="right"
      :closable="false"
      :visible="productSelectModal"
      wrapClassName = "productSelectModal"
      @close="productModalClose">
      
      <productlist  @parentmodalclose="productModalClose" @parentgetproduct="productSelectItem=$event"></productlist>
    </a-drawer>

    <a-drawer
      placement="right"
      :closable="false"
      :visible="companySelectModal"
      wrapClassName = "companySelectModal"
      @close="companyModalClose">
      
      <companylist :modaldata="modalData" @parentmodalclose="companyModalClose" @parentgetitem="selectItem=$event"></companylist>
    </a-drawer>


  </div>
</template>


<script>



  import productlist from '../product/ProductList';
  import companylist from '../general/generalList';
  import Menus from '../Menu';
  export default {
    name: "App",
    data() {
      return {
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'coordinated' }),

        // Url Params
        shippingId : 0,
        dataProductId: 0,
        sourceType: 0,

        // Company
        companyData: [],
        companyId : '',
        locationsList: [],
        productlist: [],

        // Data 
        editedItem: {},
        dataForm: {},

        // Form
        createId: null,

        // Disable Setting
        disableCustom: false,

        // Edit
        editId: 0,
        editPage : false,
        pageTitle : "Fotoğraf Ekle",

    
        productSelectControl: false,
        productSelectModal : false,

        productSelectItem : {},

        notFoundContent: "",

        // General Modal
        modalData: {},
        selectItem : [],
        companySelectModal : false,
        companyselect: [],

        // Katalog çekimi 
        disableExpertiz: false,
        disableCompany:false,

        // Anlık Konum
        location:null,
        errorStr:null,
        backgroundLocation : {},
        locationQuery : {},
        locationDistanceQuery: null,

        handleSubmitDisable:false,
        

      };
    },
    components: {
      Menus,
      productlist,
      companylist
    },
    created() {
        //this.getShippingDetail()
        this.shippingId = this.$route.query.shippingId
        this.dataProductId = this.$route.query.dataProductId
        this.sourceType = this.$route.query.sourceType
        this.productSelectControl = this.$route.query.productSelect

        this.editId = this.$route.params.id
        if(this.editId > 1) {
           this.editPage  = true
           this.pageTitle = "Fotoğraf Düzenle #" + this.editId
           this.getPhotoDetail()
        } else  {
            if(this.shippingId > 1) {
                this.getProductById()
            }
        }

        if(this.sourceType == 3) {
            this.companyselect = [{ key: 1, label: "HASEL İSTİF MAKİNALARI SAN. VE TİC. A.Ş." }]
            //this.locationsList = [{ key: 1019841, label: "MERKEZ" }]
            this.disableCompany = true
            this.disableExpertiz = false

            this.dataForm.companyId = 1
            this.getcompanyLocation()

        }

        this.modalData = { PageTitle: "Firma Seçimi", control: 1, Prev: this.$route.path, apiUrl: "company/searchcompany/"}

    },
     watch: {
        productSelectItem() {
            //console.log(this.productSelectItem)
            this.productlist = this.productSelectItem
            this.editedItem.IsEquipment = this.productlist[0].IsEquipment
            //alert(this.editedItem.IsEquipment)
            //console.log(this.productlist)
        },

        // General List
        selectItem() {
            this.companyselect = this.selectItem
            console.log(this.companyselect)

            this.dataForm.companyId = this.companyselect[0].key
            console.log(this.companyselect)
            this.getcompanyLocation()
        },

        location() {
            if(this.location) {
                this.backgroundLocation = {
                    lat: this.location.coords.latitude,
                    lng: this.location.coords.longitude
                }
            }
        },

    },

    
    mounted () {
       this.getLocation()
    },

    methods: {


        // General List
        companyModalClose() {
            this.companySelectModal = false;
        },

        // Firma seçimi
        companySelectAction() {
            this.companySelectModal = true
        },

        
        searchNoContent(event) {
            if(event.key > 3) {
                this.notFoundContent = "Kayıt bulunamadı"
            }
        },

        openMenu () {
          this.$refs.openMenu.showDrawer()
        },

        prevGo () {
        
            if(this.editPage == true) {
                this.$router.go(-1)
            } else {
                if (this.shippingId > 1) {
                    var prevGo = "/shipping/detail/" + this.shippingId
                    this.$router.push(prevGo)
                } else {
                     this.$router.go(-1)
                }
               
            }
        },
        

        productSelectModalClick() {
            this.productSelectModal = true
            this.$root.productSelectModal = true
        },

        productModalClose() {
            this.productSelectModal = false;
        },

        // Shipping Detail
        getPhotoDetail() {
            this.$axios.get("/WorkTimes/Get/" + this.editId).then(resp => {
                this.editedItem = resp.data.Entity
                this.dataForm =  this.editedItem

                //this.dataForm.CompanyId =  this.editedItem.CompanyName

                this.dataForm.SerialNo =  this.editedItem.ProductSerialNo
               
                this.companyselect = [{ key: this.dataForm.CompanyId, label: this.dataForm.CompanyName }]
                this.locationsList = [{ key: this.dataForm.LocationId, label: this.dataForm.LocationName }]
                this.productlist= [{ key: this.dataForm.ProductId, label: this.dataForm.SerialNo }]
                
                //this.form.setFieldsValue({ shippingType: this.item.ShippingType })
                
                this.disableCustom = true
            });
        },

        
        // Shipping Detail
        async getProductById() {
            await this.$axios.get("/Shippings/GetShippingDetail/" + this.dataProductId).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.editedItem = resp.data.Entity
                    this.dataForm =  this.editedItem

                    this.companyselect = [{ key: this.dataForm.CompanyId, label: this.dataForm.CompanyName }]
                    this.locationsList = [{ key: this.dataForm.LocationId, label: this.dataForm.LocationName }]
                    this.productlist= [{ key: this.dataForm.ProductId, label: this.dataForm.SerialNo, name:this.dataForm.SerialNo }]

                    this.disableCustom = true

                    if(this.editedItem.WorkTimeId != null) {
                        //alert("kayıt var" + this.editedItem.WorkTimeId)
                        var url5 = '/photo/photoDetail?shippingId='+ this.shippingId + '&dataProductId=' + this.editedItem.WorkTimeId + '&sourceType=' + this.sourceType 
                        this.$router.push(url5)
                    }
                }


            });
        },

        // From Compamny
        companySearchChange(event) {
            this.dataForm.companyId = event.key
            console.log(event)
            this.getcompanyLocation()
        },

        
        locationChange(event) {
            this.dataForm.locationId = event
            
            var locationQuery1 = this.locationsList.filter(function (datafilter) {
                return datafilter.Id == event.key;
            });

            this.locationQuery.Latitude = locationQuery1[0].Latitude
            this.locationQuery.Longitude = locationQuery1[0].Longitude

            this.locationDistance()
            
        },

        companySearch(searchCompany) {
            if (searchCompany.length >= 3) {
                this.$axios.get("/company/SearchCompany/" + searchCompany + "/0/10/").then(resp => {
                    this.companyData =  resp.data.List;
                });
            }
        },

        async getcompanyLocation() {
            await this.$axios.get("/company/GetCompanyAndLocationForShipping/" + this.dataForm.companyId + "/" + localStorage.getItem('userId')).then(response => {
                this.locationsList = response.data.Entity.LocationList
                if(this.locationsList.length == 1) {
                    this.locationsList = [{ key: this.locationsList[0].Id, label: this.locationsList[0].Name }]
                }
            });
        },

        productSearch(searchProduct) {
            console.log(searchProduct)
            /*if (searchProduct.length >= 3) {
                this.$axios.get("/company/searchcompany/" + searchProduct + "/0/10/").then(resp => {
                    this.productlist =  resp.data.List;
                });
            }*/
        },

        // Konum isteği
        getLocation() {
            //do we support geolocation
            if(!("geolocation" in navigator)) {
              this.errorStr = 'Geolocation is not available.';
              return;
            }

            // get position
            navigator.geolocation.getCurrentPosition(pos => {
                this.location = pos;
            }, err => {
                this.errorStr = err.message;
            })
        },

        // Mesafe Ölçme
        async locationDistance() {
            await this.$axios.get("Location/GetLocationsDistance/" +  this.locationQuery.Latitude + "/" +  this.locationQuery.Longitude  + "/" +  this.backgroundLocation.lat + "/" + this.backgroundLocation.lng).then(resp => {
                if (resp.data.IsSuccessful) {
                    this.locationDistanceQuery = resp.data.Entity
                } else {
                    console.log("hata")
                }
            }).catch((error) => { console.log(error) })
        },

        // Form
        handleSubmit(e) {

            this.handleSubmitDisable = true

            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    //console.log('Form alanları: ', values);

                    if(this.editPage == true) {
                        values.Id  = this.editedItem.Id
                    }

                    if(this.shippingId > 1) {
                        values.ShippingId  = Number(this.shippingId)
                        values.ShippingDetailId  = Number(this.dataProductId)
                    }

                    values.CompanyId  = values.CompanyId.key
                    values.CompanyLocationId  = values.CompanyLocationId.key
                    values.ProductId  = Number(values.ProductId.key)
                    values.SourceType  =  Number(this.sourceType)
                    values.WorkTimePhotoList  =  []
                    values.MobileFile = true

                    // Konum
                    values.Latitude = Number(this.locationQuery.Latitude )
                    values.Longitude = Number(this.locationQuery.Longitude )
                    values.Distance = this.locationDistanceQuery

                    if(this.editedItem.IsEquipment == true) {
                        values.WorkTimeValue = 0
                    }

                    this.$axios.post("/workTimes/AddOrUpdate?", values).then(resp => {
                        if (resp.data.IsSuccessful == true) {
                             this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.success('Çalışma saati bilgisi başarıyla eklendi.', 2.5))
                            this.createId = resp.data.Entity

                             if(this.editPage != true) {
                                this.dataProductId  = resp.data.Entity
                            }

                            if(this.sourceType == 1) {
                                if(this.editPage == true) {
                                    var url1 = '/photo/photoDetail?shippingId=' + this.shippingId + '&dataProductId=' + this.dataProductId + '&sourceType=' + this.sourceType 
                                    this.$router.push(url1)
                                } else {
                                    var url = '/photo/photoDetail?shippingId=' + this.shippingId + '&dataProductId=' + this.createId + '&sourceType=' + this.sourceType 
                                    this.$router.push(url)
                                }
                            } else if(this.sourceType == 3) {
                                var url5 = "/photo/photoDetail?dataProductId=" + this.dataProductId  + "&sourceType=" + this.sourceType 
                                 this.$router.push(url5)
                            } else {
                                 var url2 = '/photo/photoList?sourceType=' + this.sourceType 
                                 this.$router.push(url2)
                            }
                            
                        }
                        else {
                            this.handleSubmitDisable = false
                            this.$message
                                .loading('Lütfen bekleyin..', 2.5)
                                .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                        }

                    }).catch(e => { console.log(e) })

                    //*/*this.$router.push(-1)

                } else {
                        this.handleSubmitDisable = false
                        this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error('Hata oluştu tekrar deneyin!', 2.5))
                }
            });

        },

        
    },
  };
</script>

<style>
    .productSelectModal .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .productSelectModal  .ant-drawer-body {
        padding: 0px 0 !important;
    }

    .companySelectModal .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .companySelectModal  .ant-drawer-body {
        padding: 0px 0 !important;
    }

</style>