<template>
  <div>
    <div class="mheader" style="height: 63px; overflow: hidden;">
      <div class="row">
          <div class="col-xs-2">
              <svg @click="modalClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                <g id="back" transform="translate(-1)">
                  <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                </g>
              </svg>
          </div>
          <div class="col-xs-8">
              <span class="title">
                 {{ this.modaltitle }}
              </span>
          </div>
          <div class="col-xs-2 text-right formBtn">
                <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit" v-if="this.actionVisible">
                    <a-form-item :wrapper-col="{ span: 12 }">
                        <a-button type="primary" html-type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                            </svg>
                        </a-button>
                    </a-form-item>
                </a-form>
          </div>
      </div>
    </div>

    <div class="content" style="padding-top: 63px;">
        <div class="formStyle">
            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">

                <div class="pageHeader">
                    <h3>Randevu Bilgileri</h3>
                </div>


                <div>
                    <a-form-item label="Randevu Tarihi">
                        <a-date-picker 
                        :disabled="disableControl" 
                        placeholder="Randevu tarihi" 
                        :defaultValue="moment(appointmentDate)"  :format="dateFormat"
                        v-decorator="['appointmentDate', { initialValue: appointmentDate, rules:  [{ required: true, message: 'Randevu tarihi zorunludur!' }]}]" 
                        @change="dateChange" />
                    </a-form-item>
                </div>

                <div>
                    <a-form-item label="Randevu Saati">
                        <a-select
                        :disabled="disableControl" 
                        label-in-value
                        v-decorator="['appointmentHour', {  initialValue: hourListSelect[0], rules: [{ required: true, message: 'Randevu saati zorunludur!' }] }]"
                        placeholder="Randevu Saat"
                        @change="hourChange($event)">
                            <a-select-option v-for="item in hourList" :key="item" :value="item">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </div> 

                <a-form-item label="Firma Adı"> 
                      <a-input :disabled="true" v-model="this.CompanyName" placeholder="Firma Adı" />
                </a-form-item>

                <a-form-item label="Lokasyon"> 
                      <a-input :disabled="true" v-model="this.getFormData.LocationName" placeholder="Lokasyon" />
                </a-form-item>

                 <a-form-item label="Teknisyen Seçiniz"> 
                    <a-select
                        label-in-value
                        :disabled="disableControl" 
                        v-decorator="['TechnicianId', { initialValue: TechnicianListSelect[0], rules: [{ required: disableTechnician, message: 'Teknisyen alanı zorunludur!' }] }]"
                        placeholder="Teknisyen seçiniz"
                        notFoundContent= "Teknisyen seçiniz"
                        @change="technicianChange($event)">
                        <a-select-option v-for="item in TechnicianList" :key="item.Id" :value="item.Id">
                            {{ item.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                 <a-form-item label="Yapılan İş" v-if="disableControl">
                      <a-textarea v-model="getFormData.WorkDoneDescription" v-decorator="['Yapılan İş', { initialValue: this.getFormData.WorkDoneDescription }, ]" placeholder="Yapılan İş" :rows="5" />
                </a-form-item>

                
                <div class="clearfix"></div>

                <!-- <pre>
                    {{ this.servicedetaildata }}
                </pre> -->
            </a-form>
        </div>
    </div>

  </div>
</template>


<script>
  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        moment,
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'coordinated' }),

        // Hours
        hourListSelect: [],
        hourList: ['00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45', '03:00', '03:15', '03:30', '03:45', '04:00', '04:15', '04:30', '04:45', '05:00', '05:15', '05:30', '05:45', '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45', '08:00', '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '15:45', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45'],

        appointmentDate: new Date(),
        dateFormat: 'DD/MM/YYYY',

        modaltitle : "Yeni Randevu",

        appointmentId: null,

        ContactId: null,
        WorkTimeId: null,
        Status: null,
        TechnicianId: null,

        getFormData: {},

        TechnicianList: [],
        TechnicianListSelect: [],


        RoleList: localStorage.getItem('RoleList'),

        disableTechnician : false,

        filter: { Skip: 0, PageSize: 10 },

        actionVisible : true,

        shippingwarning: false,

        dataQuery : {},

        CompanyName: null,

        disableControl : false,


      };
    },

    props: [
        'servicedetaildata',
        'formcontrol',
        'servicedata',
        'appointmentdata'
    ],
    watch: {
        getFormData(val) {
            console.log("test" + val.CompanyName )
        }
    },
    created() {



       if(this.formcontrol == 1) {
            if(this.appointmentdata.Status == 2) {
                this.disableControl = true
            }
            
           this.appointmentId = this.servicedetaildata.Id
           this.appointmentDate = this.servicedetaildata.AppointmentDate
           this.hourListSelect = [{ key: this.servicedetaildata.AppointmentHour, label: this.servicedetaildata.AppointmentHour}]


           this.servicedetaildata.Id = this.servicedata.Id
           this.TechnicianId = this.servicedetaildata.TechnicianId

           this.TechnicianListSelect = [{ key: this.TechnicianId, label:  this.servicedetaildata.TechnicianName, name:  this.servicedetaildata.TechnicianName }]

           this.WorkTimeId = this.servicedetaildata.WorkTimeId
           this.Status = this.servicedetaildata.Status
                
           this.CompanyName = this.servicedetaildata.Shipping.CompanyName
           this.getFormData.LocationName = this.servicedetaildata.Shipping.LocationName

           this.getFormData.WorkDoneDescription = this.servicedetaildata.WorkDoneDescription

           this.modaltitle="Randevu Düzenle"
       } else {
           
            //this.shippingQuery()
       }

       if(this.servicedetaildata.Shipping.Status == true) {
           this.CompanyName = this.servicedetaildata.Shipping.CompanyName
           this.getFormData.LocationName = this.servicedetaildata.Shipping.LocationName
       } else {
            this.$message.error('Bu ürüne ait onaysız bir sevk kaydı bulunuyor. Sevk onaylandıktan sonra randevu açılabilir.')
            this.actionVisible = false
       }

       if(this.RoleList.search('Servis Teknisyeni') > -1) {
           this.getFormData.TechnicianName = localStorage.getItem('Name')
           this.getFormData.Id = localStorage.getItem('userId')
           this.TechnicianId = localStorage.getItem('userId')

           this.TechnicianListSelect = [{ key: this.TechnicianId, label:  this.getFormData.TechnicianName, name:  this.getFormData.TechnicianName }]
           this.disableTechnician = true
           this.getTechnicians()
       }

       //console.log(this.servicedata)


       if(this.TechnicianId == null) {
           this.getTechnicians()
       }

     




    },
    methods: {

        // Sevk Sorgu
            async shippingQuery() {
                this.filter.ServiceRequestId = this.$route.params.id
                this.filter.ProductId  = this.servicedetaildata.ProductId

                await this.$axios.get("/Shippings/GetShippingList?", { params: this.filter }).then(resp => {
                    if (resp.data.IsSuccessful && resp.data.Count > 0) {
                        //console.log("sevk firma " + resp.data.List[0].ToCompanyId)

                        // if(resp.data.List[0].ToCompanyId == this.servicedetaildata.LocationId) {
                        //     this.shippingwarning = true
                        // }

                        this.dataQuery =  resp.data.List[0]

                        this.servicedetaildata.LocationId = this.dataQuery.ToLocationId
                        this.CompanyName = this.dataQuery.ToCompanyName
                        this.getFormData.LocationName = this.dataQuery.ToLocationName

                        console.log(this.getFormData)


                    } else {
                        this.$message.error('Randevu kaydı oluşturulamaz! Bu ürüne ait kiralama sevk kaydı bulunmamaktadır, lütfen sevk kaydı giriniz.')
                        this.actionVisible = false

                    }
                }).catch((error) => { console.log(error) })

                this.loadingDialog = false

            },
            

        // Teknisyenleri Çek
        async getTechnicians() {
            await this.$axios.get("/user/GetTechnicians").then(response => {
                if (response.data.IsSuccessful)
                    this.TechnicianList = response.data.List
                else {
                    this.$message.error('Teknisyeneler çekilirken hata oluştu.');
                }
            });
        },
         // Teknisyenleri Çek

        modalClose() {
          this.$emit('parentmodalclose')
        },

        dateChange(date, dateString) {
            console.log(date, dateString);
        },

        hourChange(event) {
            console.log(event)
        },

        technicianChange(event) {
            console.log(event)
            this.TechnicianId = event.key
            this.getFormData.TechnicianName = event.label
        },

        companySearch(searchCompany) {
            if (searchCompany.length >= 3) {
                this.$axios.get("/company/searchcompany/" + searchCompany + "/0/10/").then(resp => {
                    this.companyData =  resp.data.List;
                });
            }
        },

        // Form
        handleSubmit(e) {

            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    
                    // Randevu Ekle
                    var appointmentData = {}

                    var messageText = "Randevu ekleme başarılı."
                    
                    if(this.formcontrol == 1) {
                        appointmentData.Id =  this.appointmentId
                        appointmentData.ContactId =  this.servicedetaildata.ContactId
                        appointmentData.EndDate =  this.servicedetaildata.EndDate
                        appointmentData.StartingDate =  this.servicedetaildata.StartingDate
                        appointmentData.Status = this.servicedetaildata.Status
                        appointmentData.WorkDoneDescription =  this.getFormData.WorkDoneDescription
                        messageText = "Randevu güncelleme başarılı."
                    }

                    //appointmentData.ContactId = this.ContactId
                    appointmentData.WorkTimeId = this.WorkTimeId
                    appointmentData.LocationId = this.servicedetaildata.Shipping.LocationId
                    appointmentData.TechnicianId = this.TechnicianId

                    appointmentData.AppointmentDate = new Date(values.appointmentDate)
                    appointmentData.AppointmentHour = values.appointmentHour.key.trim()
                    appointmentData.RequestId = this.servicedetaildata.Id

                    //console.log(appointmentData)


                    this.$axios.post("/Offers/AddOrUpdateAppointment?", appointmentData).then(resp => {
                        if (resp.data.IsSuccessful == true) {
                            this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.success(messageText, 2.5))
                            this.createId = resp.data.Entity

                            this.$emit('parentmodalsuccessclose')
                        }
                        else {
                            this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                        }

                    }).catch(e => { console.log(e) })
                    // Randevu Ekle 

                } else {
                        this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error('Hata oluştu tekrar deneyin!', 2.5))
                }
            });

        },

        
    },
  };
</script>

<style>
    .productSelectModal .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .productSelectModal  .ant-drawer-body {
        padding: 0px 0 !important;
    }

  

</style>