import Vue from 'vue';
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { router } from "./router";

import axios from "axios";
import * as GmapVue from 'gmap-vue';
//import VueSignaturePad from 'vue-signature-pad';

import('./assets/bootstrap.min.css')
import('./assets/mobil.css')
import('./assets/fontawesome.css')

import App from './App';

const axiosInstance = axios.create({
  //baseURL: "https://crmapi.hasel.com/api"
  baseURL: "https://testapi.hasel.com/api"
});

axiosInstance.interceptors.request.use(config => {
  // Token değerini local storageden okuyoruz.
  config.headers["Authorization"] = "Bearer " + localStorage.getItem('token');
  config.headers["UserId"] = localStorage.getItem('userId');
  config.headers["MobileVersion"] = "3.0.10";
  config.headers["IsMobile"] = true;
  return config;
});

axiosInstance.interceptors.response.use(config => {
  if (config.status === 401) {
      alert("Oturum süreniz sona erdi. Tekrar giriş yapınız.");
      window.localStorage.clear();
  }
  return config;
}, error => {
  if (error.response.status === 401)
      window.location = '/?p=exit&returnUrl='+window.location.pathname+window.location.search;
      window.localStorage.clear();
      localStorage.setItem('returnUrl', window.location.pathname+window.location.search);
  return error;
});

Vue.prototype.$axios = axiosInstance;

Vue.use(Antd);

// Harita
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyDG6-zUYVi7mwdonAlPtNSqtNosUe_z1Ak',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'gmap-vue/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})
// Harita

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  render: h => h(App),
  Antd,
  axios,
  router,
  GmapVue
  //VueSignaturePad
});