<template>
  <div>
    <menus ref="openMenu"></menus>

   <div class="subModule" style="margin:0;">

       <div class="pageHeader moduleHeader blue2 header3">
             <div class="left">
                <h3>
                    <!-- <span class="usedStyle red" :class="{ green: usedquerydata == 1 }"></span>  -->
                    {{ this.pageTitle }}
                </h3>
            </div>
            
            <div class="right">
                <!-- <a @click="newAppointment">
                    <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
                        <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#656565"/>
                    </svg>
                </a> -->
            </div>
            <div class="clear"></div>
        </div>
    </div>

   <div class="content shippingList ProductListPage" style="padding-bottom: 0; margin-bottom: 0px;">
         <div class="ShippingProductList row">
            <div class="col-md-12" v-if="this.dataquery.length > 0" >
                <div class="itemProduct row" v-for="item in dataquery" :key="item.SerialNo">
                    <a class="href" @click="goDetail(item)">
                        <div class="col-xs-12">

                            <div class="title servicedetail">
                                <div class="row">
                                    <div class="col-xs-6" style="margin-top:10px" v-if="item.CreateDate">
                                            <div class="icon yellow" style="display: inline-block; margin-right: 10px; float: left; color:#444;"><i class="far fa-clock"></i></div>
                                        <span class="productname" v-if="item.CreateDate" >{{ new Date(item.CreateDate).toLocaleString().split(" ")[0] }}</span>
                                    </div>

                                    <div class="col-xs-4 catalogphotodata" style="margin-top:8px">
                                        <a-icon type="dashboard" />
                                        <span class="productname" style="margin-top: 0;"  v-if="item.WorkTimeValue" >{{ item.WorkTimeValue}}</span>
                                    </div>

                                    <div class="col-xs-2 catalogphotodata" style="margin-top:8px">
                                        <a-icon type="camera" />
                                        <span class="productname" style="margin-top: 0;" v-if="item.WorkTimePhotoList">{{ item.WorkTimePhotoList.length }}</span>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </a>
                </div>

            </div>

            <div v-else class="col-md-12" >
              <div class="photoGroupItem">
                  <div class="moduleContent">
                    <div class="warning" style="padding:25px 25px ">
                        <span>
                            <svg class="text-left" id="Warning" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
                                <path id="Path_35" data-name="Path 35" d="M7,0a7,7,0,1,0,7,7A7.021,7.021,0,0,0,7,0Zm.963,10.675H6.037V9.012H8.05v1.663ZM8.05,4.2,7.525,8.05H6.475L5.95,4.2V3.325H8.137V4.2Z" fill="#ff1119"/>
                            </svg>
                           Fotoğraf kaydı bulunamadı!
                        </span>
                    </div>
                  </div>
                </div>
            </div>

        </div>
        

    </div>


  </div>
</template>

<script>
  import Menus from '../Menu';
  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        moment,
        pageTitle : "Fotoğraflar",
        dataquery: [],

        // Param
        serviceId: null,
    
        // Filter
        filter: { Skip: 0, PageSize: 2 },
        
      };
    },
    components: {
      Menus
    },
    created() {
      this.serviceId  = this.$route.params.id
      this.filter.ServiceId = this.serviceId
      this.getItems()
    },
    watch: {
      
    },
    methods: {

      goDetail(item) {
        let url = '/photo/photoDetail?dataProductId=' + item.Id + '&sourceType=2&serviceId=' + this.serviceId
        console.log(url)
        this.$router.push(url)
      },

      openMenu () {
        this.$refs.openMenu.showDrawer()
      },
      
      getItems() {
          this.$axios.get("/WorkTimes/GetList", { params: this.filter }).then(resp => {
              if (resp.data.IsSuccessful) {
                  this.dataquery = this.dataquery.concat(resp.data.List),
                  this.loadingMore = false   
              } 
          }).catch((error) => { console.log(error) })
      },
    },
  };
</script>

<style></style>