<template>
<div>
    <div class="subModule" style="margin:0">

       <div class="pageHeader moduleHeader blue2 header3">
             <div class="left">
                <h3>
                    <!-- <span class="usedStyle red" :class="{ green: usedquerydata == 1 }"></span>  -->
                    {{ this.pageTitle }}
                </h3>
            </div>
            
            <div class="right" v-if="serviceform == 1 && this.service.ResultType != 8">
                <a @click="newAppointment">
                    <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
                        <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#656565"/>
                    </svg>
                </a>
            </div>
            <div class="clear"></div>
        </div>

        <div class="moduleContent">

            <div class="warning" v-if="this.warningControl" style="padding:25px 25px">
                <span>
                    <svg class="text-left" id="Warning" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
                        <path id="Path_35" data-name="Path 35" d="M7,0a7,7,0,1,0,7,7A7.021,7.021,0,0,0,7,0Zm.963,10.675H6.037V9.012H8.05v1.663ZM8.05,4.2,7.525,8.05H6.475L5.95,4.2V3.325H8.137V4.2Z" fill="#ff1119"/>
                    </svg>
                    <span v-if="serviceform == 0">Servis talebine kayıtlı teklif / servis formu bulunamadı</span>
                    <span v-if="serviceform == 1">Servis talebine kayıtlı randevu bulunamadı</span>
                </span>
            </div>

            <div class="ShippingProductList row">
                <div class="col-md-12">
                    <div class="itemProduct row" v-for="item in dataquery" :key="item.Id">
                        <a class="href" @click="goDetail(item)">

                            <div class="col-xs-10" v-if="serviceform == 0">
                                <div class="title">
                                    <span class="serino" style="margin-bottom: 5px;" v-if="item.ProductName">{{ item.ProductName }}</span>
                                    <span class="serino" v-if="item.OfferDate">{{ new Date(item.OfferDate).toLocaleString()}}</span>
                                </div>
                            </div>

                            <div v-if="serviceform == 1">
                                
                                <div class="col-xs-12">
                                    <div class="title">
                                        <span class="serino" v-if="item.ProductName">{{ item.OfferDate }}</span>
                                        <span class="serino" v-if="item.TechnicianName">

                                            <div style="display: inline-block; margin-right: 10px;"  class="icon check" v-if="item.Status == 3" :class="{ check: item.EndDate != null }">
                                                <i style="color:red;" class="fas fa-ban"></i>
                                            </div>

                                            <div style="display: inline-block; margin-right: 10px;"  class="icon check" v-if="item.EndDate != null && item.Status != 3" :class="{ check: item.EndDate != null }">
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div style="display: inline-block; margin-right: 10px;"  class="icon yellow" v-if="item.EndDate == null && item.Status != 3" :class="{ yellow: item.EndDate != null }">
                                                <i class="far fa-clock"></i>
                                            </div>
                                            
                                            {{ item.TechnicianName }}
                                        </span>
                                        <span class="info" :title="item.AppointmentDate">{{ new Date(item.AppointmentDate).toLocaleString().split(' ')[0]}} / {{ item.AppointmentHour }}</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            
        </div>
    </div>

     <a-drawer
      placement="right"
      :closable="false"
      :visible="appointmentCreateModal"
      wrapClassName = "appointmentCreateModal"
      @close="appointmentCreateModalClose" v-if="serviceform == 1">
      
        <appointmentcreate :servicedetaildata="this.service" @parentmodalclose="appointmentCreateModalClose" @parentmodalsuccessclose="appointmentCreateModalSuccess"></appointmentcreate>
    </a-drawer>


</div>
</template>

<script>
import appointmentcreate from './appointmentCreate.vue';
export default {
  data() {
        return {
            filter: { Skip: 0, PageSize: 10 },
            dataquery: [],
            dataFilterQuery: [],
            filterData: [],
            warningControl: false,
            ServiceRequestId: null,
            pageTitle: "Teklif / Servis Formu",
            queryApi: '/Offers/GetList?',

            // Randevu
            appointmentCreateModal: false,
            modalDatas : {}
            
        };
    },
    props: [
        'workorderpostdata',
        'usedquerydata',
        'serviceform',
        'service'
    ],
    created() {
        this.ServiceRequestId = this.workorderpostdata.ServiceRequestId 
        
        // if(this.serviceform == 0) {
        //     this.pageTitle = "Randevular"
        //     this.queryApi = '/Offers/GetAppointmentList?'
        // } 

        if(this.serviceform == 1) {
            this.pageTitle = "Randevular"
            this.queryApi = '/Offers/GetAppointmentList?'
        } 

        this.getItems()
    },
    components: {
      appointmentcreate
    },
    methods: {
        // Randevu
        newAppointment() {
            this.appointmentCreateModal = true
        },

        appointmentCreateModalClose() {
            this.appointmentCreateModal = false
            this.$emit('appointmentaddmodalclose')
        },

        appointmentCreateModalSuccess() {
            this.appointmentCreateModal = false
            this.$emit('parentmodalsuccess')
        },

        // Randevu


        goDetail(item) {  
            if(this.serviceform == 1) {
                if(item.Status != 3) {
                    var url = "/service/workorder/" + item.Id
                    this.$router.push(url)
                } else {
                     this.$message.warning('Randevu iptal edildiği için işlem yapılamaz.'), 1000;
                }
            } 
            
            if(this.serviceform == 0) {
                url = "/service/offer/" + item.ActiveAppointmentId + "?page=1&OfferId=" + item.Id + "&ServiceRequestId=" + this.$route.params.id
                this.$router.push(url)

                // if(item.ActiveAppointmentId > 0) {
                //      url = "/service/offer/" + item.ActiveAppointmentId + "?page=1&OfferId=" + item.Id
                //     this.$router.push(url)
                // } else {
                //     this.$message.warning('Randevu kaydı bulunamadığı için işlem yapılamaz.'), 1000;
                // }

            }
        },

        getItems() {
             this.filter.ServiceRequestId = this.workorderpostdata.ServiceRequestId 
             //alert(this.filter.ServiceRequestId)
             this.$axios.get(this.queryApi, { params: this.filter }).then(resp => {
                if (resp.data.IsSuccessful) {
                    this.dataquery = resp.data.List, this.dataquery.length = resp.data.Count, this.loadingDialog = false, this.warningControl = (this.dataquery.length <= 0) ? true : false
                    
            
                    // if (this.usedquerydata == 0) {
                    //     console.log(this.dataFilterQuery)
                    //     this.filterData = this.dataFilterQuery.filter(function (datafilter) {
                    //         return datafilter.EndDate == null;
                    //     });
                    //     this.dataquery = this.filterData
                    // } else {
                    //     this.filterData = this.dataFilterQuery.filter(function (datafilter) {
                    //         return datafilter.EndDate != null;
                    //     });
                    //     this.dataquery = this.filterData
                    // }

                } else {
                   console.log("hata")
                }
            }).catch((error) => { console.log(error) })
        },


    },
};
</script>


<style>
    .appointmentCreateModal .ant-drawer-content-wrapper {
        right: 0;
        width: 100% !important;
    }

    .appointmentCreateModal  .ant-drawer-body {
        padding: 0px 0 !important;
    }
</style>