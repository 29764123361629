<template>
  <div>
    <div class="mheader" v-bind:style="{ height: paddingTop + 'px', overflow: hidden }" >
      <div class="row">
          <div>
              <div class="col-xs-2">
                  <svg @click="modalClose"  class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                    <g id="back" transform="translate(-1)">
                      <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                    </g>
                  </svg>
              </div>
              <div class="col-xs-8">
                  <span class="title">
                     {{ this.pageTitle }}
                  </span>
              </div>

              <div class="col-xs-2 text-right" v-if="this.pageControl == 1">
                  <span style="float:right;">
                      <a-icon @click="filterDialog" style="font-size: 18px; margin-left:20px;" type="filter" />
                  </span>
              </div>


              <div class="col-xs-2 text-right" v-if="this.pageControl == 2">
                  <svg @click="newpersonaction" class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
                      <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#fff"/>
                  </svg>
              </div>
          </div>

          <div class="clear"></div>
          <div class="col-xs-12" v-if="this.modaldata.control != 3">
              <div class="searchInput" style="width: 100%; display: inline-block; float: left;">
                <a-input-search placeholder="Arama yapın" @search="onSearch"/>
              </div>
          </div>
      </div>
    </div>

    <div class="content shippingList ProductListPage" v-bind:style="{ paddingTop: paddingTop + 'px' }">
         <div class="ShippingProductList row">
            <div class="col-md-12" v-if="this.modaldata.control != 3">
                <div class="itemProduct row" v-for="item in dataquery" :key="item.Id">
                    <a class="href" @click="goDetail(item)">
                        <div class="col-xs-12">
                            <div class="title">
                                <span class="serino" v-if="item.Name">{{ item.Name }}</span>
                                <span class="info" v-if="item.Gsm">{{ item.Gsm }}</span>
                                <span class="info" v-if="item.Email">{{ item.Email }}</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div v-else>
                <div class="itemProduct row" v-for="item in dataquery" :key="item.Id">
                    <a class="href" @click="goDetail(item)">
                        <div class="col-xs-12">
                            <div class="title">
                                <span class="serino" v-if="item.ProductSerialNo">{{ item.ProductSerialNo }}</span>
                                <span class="info" v-if="item.RentalRefNo">{{ item.RentalRefNo }}</span>
                                <span class="info" v-if="item.CompanyName">{{ item.CompanyName }}</span>
                                <span class="info" v-if="item.LocationName">{{ item.LocationName }}</span>
                                <span class="info" v-if="item.OperationName">{{ item.OperationName }}</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

        </div>
    </div>


    <a-drawer placement="right" 
        :closable="false"
        :visible="newPersonModal"
        wrapClassName = "newPersonModal"
        @close="newPersonModalClose">
       
                 <div class="mheader" style="height: 65px; overflow: hidden;">
                  <div class="row">
                      <div class="col-xs-2">
                          <svg @click="newPersonModalClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                            <g id="back" transform="translate(-1)">
                              <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                            </g>
                          </svg>
                      </div>
                      <div class="col-xs-8">
                          <span class="title">
                            YENİ İLGİLİ KİŞİ
                          </span>
                      </div>
                      <div class="col-xs-2 text-right formBtn">
                            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                                <a-form-item :wrapper-col="{ span: 12 }">
                                    <a-button type="primary" html-type="submit">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                            <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                                        </svg>
                                    </a-button>
                                </a-form-item>
                            </a-form>
                      </div>

                  </div>
                </div>

                <div style="padding-top: 85px; height:100%;">
                  <div class="formStyle">
                    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">

           

                      <a-form-item label="Adı Soyadı"> 
                            <a-input v-decorator="['Name', { rules: [{ required: true, message: 'Adı soyadı alanı zorunludur!' }] }]" placeholder="Adı Soyadı" />
                      </a-form-item>


                       <a-form-item label="Gsm"> 
                            <a-input type='number' placeholder="(532) 000 00 00" v-decorator="['Gsm', {  rules: [{ validator: checkPhone}] }]" />

                      </a-form-item>

                      <a-form-item label="Ünvan"> 
                            <a-input v-decorator="['Title', { rules: [{ required: false }] }]" placeholder="Ünvan" />
                      </a-form-item>

                 


                    </a-form>
                   </div>
                  
                </div>

                
                
                <div class="clearfix"></div>

           
        

    </a-drawer>



    
    <a-drawer
      placement="right"
      :closable="false"
      :visible="filterVisible"
      wrapClassName="filterDialog"
      @close="filterClose">

     <div class="mheader" style="height: 66px; overflow: hidden;">
        <div class="row">
            <div class="col-xs-2">
                <svg @click="filterClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                  <g id="back" transform="translate(-1)">
                    <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                  </g>
                </svg>
            </div>
            <div class="col-xs-8">
                <span class="title">
                   Filtre
                </span>
            </div>
            <div class="col-xs-2 text-right formBtn">
                  <svg @click="filterSubmit" xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                      <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                  </svg>
            </div>
        </div>
      </div>

       <div class="content" style="padding-top: 63px;">
        <div class="formStyle" style="margin-top: 25px;">
            <form class="ant-form ant-form-horizontal">
                
                
                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Ürün Seri No</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-input pressEnter="filterSubmit()" v-model="filter.ProductSeriNo" placeholder="Ürün Seri No" />
                        </div>
                    </div>
                </div>

                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Referans No" class="">Ürün Referans No</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-input pressEnter="filterSubmit()" v-model="filter.RentalRefNo" placeholder="Ürün Referans No" />
                        </div>
                    </div>
                </div>


            </form>
        </div>
    </div>
      
    </a-drawer>


    

  </div>
</template>

<script>
  // import productheader from '../product/ProductHeader';
  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        moment,
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'coordinated' }),

        dataquery: [],

        // Ürün Ekle
        addProductModal : false,

        // Page
        pageTitle: null,

        // Custom Api
        getApiUrl: null,

        // Filter
        filter: { Skip: 0, PageSize: 20 },

        // İlgili Kişi
        newPersonModal: false,
        personData: {},

        pageControl: 0,

        filterVisible: false,

        // Style
        paddingTop: 130,
        hidden: "hidden",

      };
    },
    // components: {
    //   productheader
    // },
    created() {
      
      console.log(this.modaldata)

      this.pageTitle = this.modaldata.PageTitle
      this.getApiUrl = this.modaldata.apiUrl
      this.filter.CompanyId = this.modaldata.CompanyId
      this.pageControl = this.modaldata.pageControl

      this.getItems()

      if(this.modaldata.control == 3) {
        this.paddingTop = 70
        this.filterVisible = true
      }

    },

    props: [
        'modaldata',
    ],

    watch: {
        modaldata(val) {
          console.log(val)
        }
    },
    
    methods: {

      // Filter
      filterDialog() {
        this.filterVisible = true
      },

      filterClose() {
        this.filterVisible = false
      },

      filterSubmit() {
        this.getItems()
        this.filterClose()
      },

      // İlgili Kişi

        checkPhone(rule, value, callback) {
            //console.log(value + " --" + value.length)
            if (value.length == 10) {
                callback();
                return;
            }
            callback('Lütfen telefon numarası alanını doğru giriniz.');
        },

        newPersonModalClose() {
          this.newPersonModal = false
        },

        newpersonaction() {
          this.newPersonModal = true
        },
        // İlgili Kişi


        // Yeni Veri 
        addAction() {
          this.addProductModal = true;
        },
        addProductModalClose() {
          this.addProductModal = false;
        },
        // Yeni Veri
        
        onSearch(value) {
            console.log(value);
            this.filter.IsSearching = (value.length > 2) ?  true : null 
            this.filter.SearchText = (value.length > 2) ?  value : null
            this.getItems()
        },

        goDetail(item) {    

          var dataControl = true
          
          var selectitem = [{ key: item.Id, label: item.Name, phone: item.Gsm, item: item }]

          //console.log(selectitem)

          if (this.modaldata.control == 3) {
            if(selectitem[0].item.LocationId != null) {
              selectitem = [{ key: item.ProductId, label: item.ProductSerialNo, phone: item.Gsm, item: item }]
            } else {
              dataControl = false
              this.$message.error('Bu ürüne ait kiralama sevk kaydı bulunmamaktadır, lütfen sevk kaydı giriniz.');
            }
          }


          if(dataControl == true) {
            
            this.$emit('parentgetitem', selectitem)
            this.$emit('parentmodalclose')
          }
        },

        modalClose() {
          this.$emit('parentmodalclose')
        },
        getItems() {

            if(this.modaldata.control == 1) {
              var companysearch = this.filter.SerialNo
              if(companysearch == undefined) companysearch = "ass"
              delete this.filter.PageSize, delete this.filter.Skip, delete this.filter.SerialNo
              this.getApiUrl = "/company/SearchCompany/" + companysearch + "/0/10/"
            }

            if(this.modaldata.control == 3) {
              this.filter.StatusId = 1
            }

            
            if(this.filter.ProductSeriNo)  this.filter.ProductSeriNo = this.filter.ProductSeriNo.trim()
            if(this.filter.RentalRefNo)  this.filter.RentalRefNo = this.filter.RentalRefNo.trim()
              
            
            this.$axios.get( this.getApiUrl, { params: this.filter }).then(resp => {
                if (resp.data.IsSuccessful) {
                    //  if(this.filter.SerialNo) {
                    //     this.$message
                    //     .loading('Lütfen bekleyin..', 2.5)
                    //     .then(() => this.$message.success(this.filter.SerialNo + " " + 'aramanızla ilişkili ürünler yüklendi.', 2.5))
                    // }
                    this.dataquery = resp.data.List
                }
            }).catch((error) => { console.log(error) })

        },

           // Form
        handleSubmit(e) {

            e.preventDefault();
            this.form.validateFields((err, values) => {
              console.log(values)

              values.CompanyId = this.modaldata.CompanyId
              //values.CustomerId = 1054010

              this.$axios.post("/authenticator/addOrUpdate", values).then(resp => {
                  if (resp.data.IsSuccessful == true) {
                    this.getItems()
                    this.newPersonModalClose()
                    this.$message
                        .loading('Lütfen bekleyin..', 2.5)
                        .then(() => this.$message.success('İlgili kişi eklendi.', 2.5))
                  }
              }).catch(e => { console.log(e) })
              

            });

        },
        

    },
  };
</script>

<style>
    .addProductModal .ant-drawer-content-wrapper {
      width: 100% !important;
    }

    .addProductModal  .ant-drawer-body {
      padding: 0px 0 !important;
    }

    .addProductModal {
          z-index: 99999 !important;
    }

    .newPersonModal .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .newPersonModal  .ant-drawer-body {
        padding: 0px 0 !important;
    }
</style>