import Vue from "vue";
import VueRouter from "vue-router";

/*
import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);
*/

import MenuComponent from "./components/Menu";
import Login from "./components/Login";
import Home from "./components/Home";
import About from "./components/About";

// Shipping
import ShippingList from "./components/shipping/shippingList";
import ShippingDetail from "./components/shipping/shippingDetail";
import ShippingProduct from "./components/shipping/shippingProduct";
import ShippingCreate from "./components/shipping/shippingCreate";
import ShippingEdit from "./components/shipping/shippingCreate";

// Photo
import PhotoListPage from "./components/photo/photoList";
import PhotoCreate from "./components/photo/photoCreate";
import PhotoEdit from "./components/photo/photoCreate";
import PhotoDetail from "./components/photo/photoDetail";

// Product
import ProductList from "./components/product/ProductList";

// Customer
import CustomerList from "./components/customer/customerList";
import CustomerDetail from "./components/customer/customerDetail";


// Service
import ServiceCreate from "./components/service/addOrUptadeService";
import ServiceEdit from "./components/service/addOrUptadeService";
import serviceRequestList from "./components/service/serviceRequestList";
import serviceRequestDetail from "./components/service/serviceRequestDetail";
import ServiceWorkDetail from "./components/service/workOrderDetail";
import ServiceOfferDetail from "./components/service/workOrderDetail";

//import ServiceWorksList from "./components/service/serviceWorksList";

// General List
import GeneralList from "./components/general/generalList";

import Konum from "./components/konum";

Vue.use(VueRouter);

const routes = [
	{ path: "/", component: Login },
	{ path: "/home", component: Home },
	{ path: "/about", component: About },
	{ path: "/menu", component: MenuComponent },

	{ path: "/shippings", component: ShippingList },
	{ path: "/shipping/detail/:id", component: ShippingDetail },
	{ path: "/shippingProduct", component: ShippingProduct },
	{ path: "/shipping/create", component: ShippingCreate },
	{ path: "/shipping/edit/:id", component: ShippingEdit },

	{ path: "/photo/photoList", component: PhotoListPage },
	{ path: "/photo/photoCreate", component: PhotoCreate },
	{ path: "/photo/edit/:id", component: PhotoEdit },
	{ path: "/photo/photoDetail", component: PhotoDetail },

	{ path: "/customers", component: CustomerList },
	{ path: "/customer/detail/:id", component: CustomerDetail },
	
	{ path: "/product", component: ProductList },
	{ path: '/about/:id', component: About },

	{ path: "/service/create", component: ServiceCreate },
	{ path: "/service/edit/:id", component: ServiceEdit },
	{ path: "/service", component: serviceRequestList },
	{ path: "/service/:id", component: serviceRequestDetail },
	{ path: '/service/workorder/:id', component: ServiceWorkDetail },
	{ path: '/service/offer/:id', component: ServiceOfferDetail },
	//{ path: "/service", component: ServiceWorksList },

	{ path: '/general/list', component: GeneralList },

	{ path: "/konum", component: Konum }
];

export const router = new VueRouter({
	mode: "history",
	routes
});