<template>
  <div>
    <menus ref="openMenu"></menus>

    <div class="mheader" style="height: 65px; overflow: hidden;">
      <div class="row">
          <div class="col-xs-2">
              <svg @click="openMenu" class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
                <path id="Menu" d="M-6160,12V10.285h8.751V12Zm0-5.143V5.143h14V6.857Zm0-5.143V0h14V1.715Z" transform="translate(6160)" fill="#fff"/>
              </svg>
          </div>
          <div class="col-xs-8">
              <span class="title">
                Sevk Hareketleri
              </span>
          </div>
          <div class="col-xs-2 text-right">
              <!-- 
                <a href="/shipping/create">
                  <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
                      <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#fff"/>
                  </svg>
                </a>
              -->
                <a-icon @click="filterDialog" style="font-size: 18px;" type="filter" />
          </div>
       
          
      </div>
    </div>

    
    <div class="content shippingList" style="padding-top: 65px;" v-if="this.dataCount == 0">
        <div class="warning" v-if="this.dataCount == 0"  style="padding: 25px 25px 25px; background: #fff;">
            <span>
                <svg class="text-left" id="Warning" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
                    <path id="Path_35" data-name="Path 35" d="M7,0a7,7,0,1,0,7,7A7.021,7.021,0,0,0,7,0Zm.963,10.675H6.037V9.012H8.05v1.663ZM8.05,4.2,7.525,8.05H6.475L5.95,4.2V3.325H8.137V4.2Z" fill="#ff1119"/>
                </svg>
                Sevk kayıtı bulunamadı
            </span>
        </div>
    </div>
    
    <div class="content shippingList" style="padding-top: 65px;" v-if="this.dataCount > 0">

        <div class="list" v-for="item in dataquery" :key="item.Id">
            <a class="detailLink" v-bind:href="'/shipping/detail/'+item.Id">
              <div class="row" :class="{ approved: item.ShippingStatus }">
                  <div class="col-xs-6 shippingReagent padding">
                      <span class="title">{{ item.FromCompanyName }}</span>
                      <ul>
                        <li><i class="fas fa-map-marker-alt"></i> {{ item.FromLocationName }}</li>
                        <li><i class="fas fa-clock"></i> {{new Date(item.FromDate).toLocaleString().split(' ')[0]}} </li>
                      </ul>
                  </div>
                  <div class="col-xs-6 padding">
                      <span class="title">{{ item.ToCompanyName }}</span>
                      <ul class="bsx">
                        <li><i class="fas fa-map-marker-alt"></i> {{ item.ToLocationName }}</li>
                        <li><i class="fas fa-clock"></i> {{new Date(item.ToDate).toLocaleString().split(' ')[0]}} </li>
                      </ul>
                  </div>
                  <svg class="shippingStatusIcon" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 29.333 29.333">
                    <g id="Path_206" data-name="Path 206" fill="#ff1119">
                      <path d="M 14.66668701171875 26.83337783813477 C 11.41684722900391 26.83337783813477 8.361516952514648 25.56781768798828 6.063537120819092 23.26983642578125 C 3.765557050704956 20.97185707092285 2.499996900558472 17.91652679443359 2.499996900558472 14.66668701171875 C 2.499996900558472 11.41684722900391 3.765557050704956 8.361516952514648 6.063537120819092 6.063537120819092 C 8.361516952514648 3.765557050704956 11.41684722900391 2.499996900558472 14.66668701171875 2.499996900558472 C 17.91652679443359 2.499996900558472 20.97185707092285 3.765557050704956 23.26983642578125 6.063537120819092 C 25.56781768798828 8.361516952514648 26.83337783813477 11.41684722900391 26.83337783813477 14.66668701171875 C 26.83337783813477 17.91652679443359 25.56781768798828 20.97185707092285 23.26983642578125 23.26983642578125 C 20.97185707092285 25.56781768798828 17.91652679443359 26.83337783813477 14.66668701171875 26.83337783813477 Z" stroke="none"/>
                      <path d="M 14.66668701171875 4.999996185302734 C 9.336456298828125 4.999996185302734 4.999996185302734 9.336456298828125 4.999996185302734 14.66668701171875 C 4.999996185302734 19.99691772460938 9.336456298828125 24.33337783813477 14.66668701171875 24.33337783813477 C 19.99691772460938 24.33337783813477 24.33337783813477 19.99691772460938 24.33337783813477 14.66668701171875 C 24.33337783813477 9.336456298828125 19.99691772460938 4.999996185302734 14.66668701171875 4.999996185302734 M 14.66668701171875 -3.814697265625e-06 C 22.76687622070313 -3.814697265625e-06 29.33337783813477 6.566497802734375 29.33337783813477 14.66668701171875 C 29.33337783813477 22.76687622070313 22.76687622070313 29.33337783813477 14.66668701171875 29.33337783813477 C 6.566497802734375 29.33337783813477 -3.814697265625e-06 22.76687622070313 -3.814697265625e-06 14.66668701171875 C -3.814697265625e-06 6.566497802734375 6.566497802734375 -3.814697265625e-06 14.66668701171875 -3.814697265625e-06 Z" stroke="none" fill="#fff"/>
                    </g>
                    <g id="Forward_arrow" data-name="Forward arrow" transform="translate(6.333 8.333)">
                      <path id="Path_10" data-name="Path 10" d="M6.328,0,5.178,1.151,9.252,5.225l.282.282h0l.752.822L5.178,11.506l1.151,1.151,6.328-6.328Z" fill="#fff"/>
                    </g>
                  </svg>
              </div>
            </a>
        </div>


        <div class="onLoadBtn" v-if="showLoadingMore" slot="loadMore" :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }">
          <a-spin v-if="loadingMore" />
          <a-button v-else @click="onLoadMore">
              daha fazla...
          </a-button>
        </div>

    </div>


    <a-drawer
      placement="right"
      :closable="false"
      :visible="filterVisible"
      wrapClassName="filterDialog"
      @close="filterClose">

     <div class="mheader" style="height: 66px; overflow: hidden;">
        <div class="row">
            <div class="col-xs-2">
                <svg @click="filterClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                  <g id="back" transform="translate(-1)">
                    <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                  </g>
                </svg>
            </div>
            <div class="col-xs-8">
                <span class="title">
                   Filtre
                </span>
            </div>
            <div class="col-xs-2 text-right formBtn">
                  <svg @click="filterSubmit" xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                      <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                  </svg>
            </div>
        </div>
      </div>

       <div class="content" style="padding-top: 63px;">
        <div class="formStyle" style="margin-top: 25px;">
            <form class="ant-form ant-form-horizontal">

              <div class="ant-row ant-form-item">
                  <div class="ant-col ant-form-item-label">
                      <label title="Ürün Seri No" class="">Sevk Tipi</label>
                  </div>
                  <div class="ant-col ant-form-item-control-wrapper">
                    <div class="ant-form-item-control">
                        <a-select placeholder="Seçim yapınız"
                            @change="shippingTypeChange($event)">
                              <a-select-option v-for="c1 in shippingTypeList" :key="c1.Id">
                                {{ c1.Name }}
                            </a-select-option>
                        </a-select>                      
                      </div>
                  </div>
              </div>

              <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Firma Adı</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                      <div class="ant-form-item-control">
                          <a-select
                              v-model="selectCompany"
                              mode="default"
                              placeholder="Firma Ara"
                              :showSearch="true"
                              label-in-value
                              :value="value"
                              :filter-option="false"
                              notFoundContent=""
                              :loading="true"
                              @search="companySearch"
                              @change="compamySearchChange($event)">
                              <a-icon slot="suffixIcon" type="search" />
                              <a-select-option v-for="d in data" :key="d.Id">
                                {{ d.Name }}
                              </a-select-option>
                            </a-select>                        
                        </div>
                    </div>
                </div>
                
                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Ürün Seri No</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-input pressEnter="filterSubmit()" v-model="filter.ProductSerialNo" placeholder="Ürün Seri No" />
                        </div>
                    </div>
                </div>

                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Referans No" class="">Ürün Referans No</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-input pressEnter="filterSubmit()" v-model="filter.RentalRefNo" placeholder="Ürün Referans No" />
                        </div>
                    </div>
                </div>

              <div class="ant-row ant-form-item">
                  <div class="ant-col ant-form-item-label">
                      <label title="Ürün Seri No" class="">Başlangıç Tarihi</label>
                  </div>
                  <div class="ant-col ant-form-item-control-wrapper">
                    <div class="ant-form-item-control">
                          <a-date-picker
                            format="YYYY-MM-DD"
                            placeholder="Başlangıç Tarihi"
                            @change="handleStartOpenChange"
                          />                    
                      </div>
                  </div>
              </div>

              <div class="ant-row ant-form-item">
                  <div class="ant-col ant-form-item-label">
                      <label title="Ürün Seri No" class="">Bitiş Tarihi</label>
                  </div>
                  <div class="ant-col ant-form-item-control-wrapper">
                    <div class="ant-form-item-control">
                          <a-date-picker
                            format="YYYY-MM-DD"
                            placeholder="Bitiş Tarihi"
                            @change="handleEndOpenChange"
                          />                   
                      </div>
                  </div>
              </div>

               <div class="ant-row ant-form-item">
                  <div class="ant-col ant-form-item-label">
                      <label title="Ürün Seri No" class="">Onaysız Sevkler</label>
                  </div>
                  <div class="ant-col ant-form-item-control-wrapper">
                    <div class="ant-form-item-control">
                          <a-switch @change="onChangeIsNotApproved" />                  
                      </div>
                  </div>
              </div>

            </form>
        </div>
    </div>
      
    </a-drawer>


  </div>
</template>

<script>
  import Menus from '../Menu';
  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        moment,
        
        testdata : "555",
        dataquery: [],
        companyList: [],
        searchValue: '',
        searchCompany: null,
        
        // Filter
        filterVisible: false,
        filter: { Skip: 0, PageSize: 5 },

        data: [],
        value: '',
        selectCompany: [],

        // Scroll
        loading: true,
        loadingMore: false,
        showLoadingMore: true,

        IsApprovedControl:null,
        dataCount:0,

        shippingTypeList: [{ Id: 1, Name: 'Giden Sevk' }, { Id: 2, Name: 'Gelen Sevk' }, { Id: 3, Name: 'Transfer' }],


      };
    },
    components: {
      Menus
    },
    created() {

      this.IsApprovedControl = this.$route.query.IsApproved
      

      if(this.IsApprovedControl == 'true') this.filter.IsApproved = true
      if(this.IsApprovedControl == 'false') this.filter.IsNotApproved = true

      //alert(this.IsApprovedControl)

      var onLoadMore = false
      this.getItems(onLoadMore)


    },
    watch: {
      $route() {
        this.IsApprovedControl = this.$route.query.IsApproved

        if(this.IsApprovedControl != undefined) {
          this.filter.IsApproved = this.IsApprovedControl
        }

        //console.log("test" + this.$route.path)
        if(this.$route.path) {
          var onLoadMore = false
          this.getItems(onLoadMore)
        }
      }
    },
    methods: {
      shippingTypeChange(event) {
          console.log(event)
          this.filter.ShippingTypeId = event
      },

      handleStartOpenChange(dateString) {
          console.log( dateString);
          this.filter.ShippingStartDate = new Date(dateString)
      },

      handleEndOpenChange(dateString) {
          console.log( dateString);
        this.filter.ShippingEndDate = new Date(dateString)
      },

      onChangeIsNotApproved(checked) {
        console.log(checked)
        this.filter.IsNotApproved = checked
      },

      compamySearchChange(event) {
        console.log(event)
        this.filter.CompanyId = event.key
        //this.getItems(onLoadMore)
      },

      openMenu () {
        this.$refs.openMenu.showDrawer()
      },

      filterDialog() {
        this.filterVisible = true
      },

      filterClose() {
        this.filterVisible = false
      },
      
      companySearch(searchCompany) {
        if (searchCompany.length >= 3) {
           this.$axios.get("/company/searchcompany/" + searchCompany + "/0/10/").then(resp => {
              this.data =  resp.data.List;
          });
        }
      },

      getItems(onLoadMore) {
          if(onLoadMore == true) {
            this.filter.Skip = this.filter.Skip + 5
          }
          
          this.$axios.get("/Shippings/GetShippingList?", { params: this.filter }).then(resp => {
              if (resp.data.IsSuccessful) {
                  if(onLoadMore == false) {
                      this.dataquery = resp.data.List
                      this.dataCount = resp.data.Count
                      this.filterVisible= false
                  } else {
                     this.dataquery = this.dataquery.concat(resp.data.List),
                     this.loadingMore = false
                  }
              } 
          }).catch((error) => { console.log(error) })
      },

      onLoadMore() {
        this.loadingMore = true
        var onLoadMore = true
        this.getItems(onLoadMore)
      },

      filterSubmit() {
          var onLoadMore = false
          this.getItems(onLoadMore)
      }

    },
  };
</script>

<style>
  .filterDialog .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .filterDialog  .ant-drawer-body {
    padding: 0px 0 !important;
  }
</style>