<template>

    <div>
        <a-drawer
          placement="left"
          :closable="false"
          :visible="visible"
          :after-visible-change="afterVisibleChange"
          wrapClassName = "openMenu"
          @close="onClose">
      <div>

        <a-menu style="width: 90%"
          :default-selected-keys="['1']"
          :open-keys.sync="openKeys"
          mode="inline"
          @click="handleClick">

          <a-menu-item @click="openPage('/Home')">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="iconmonstr-user-19" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,22a9.983,9.983,0,0,1-7.749-3.69,2.357,2.357,0,0,1,1.867-1.246c2.244-.518,4.459-.981,3.393-2.945C6.356,8.3,8.612,5,12,5c3.322,0,5.634,3.177,2.489,9.119-1.035,1.952,1.1,2.416,3.393,2.945A2.371,2.371,0,0,1,19.753,18.3,9.982,9.982,0,0,1,12,22Z" fill="#fff"/>
              </svg>
            </span>
            <span class="title">{{ this.NameSurname }}</span>
            <span class="desc">{{ this.personelTitle }}</span>

            <div class="logout" @click="logout()">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16.8" height="14" viewBox="0 0 16.8 14">
                  <path id="iconmonstr-log-out-10" d="M9.8,13.9v-.058A5.6,5.6,0,1,1,7,3.4a5.551,5.551,0,0,1,2.8.758V2.587a7,7,0,1,0,0,12.827Zm2.8-6.714L14.674,9,12.6,10.814V9.7H7V8.3h5.6ZM11.2,4.1V6.9H5.6v4.2h5.6v2.8L16.8,9Z" transform="translate(0 -2)" fill="#494a4a"/>
                </svg>
              </span>
              <span>
                Çıkış
              </span>
            </div>
          </a-menu-item>

                    
          <a-menu-item @click="openPage('/Home')">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.667" height="25.556" viewBox="0 0 26.667 25.556">
                <path id="iconmonstr-home-4" d="M13.333,10.094l7.778,7.238v7H5.556v-7l7.778-7.238Zm0-3.036-10,9.306V26.556h20V16.364Zm2.222,16.163H11.111V16.556h4.444Zm11.111-9.836-1.512,1.628L13.333,4.033,1.512,15,0,13.37,13.333,1,26.667,13.387Z" transform="translate(0 -1)" fill="#fff"/>
              </svg>
            </span>
            <span class="title">Giriş</span>
          </a-menu-item>
          
                    
          <a-menu-item @click="openPage('/service')" v-if="this.RoleList.search('Servis') > -1 || this.RoleList.search('Admin') > -1 || this.RoleList.search('Admin') > -1 || this.RoleList.search('Yönetici') > -1 || this.RoleList.search('Operasyon Yöneticisi') > -1 || this.RoleList.search('Operasyon Asistanı') > -1">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="iconmonstr-wrench-24" d="M13.9,10.623l1.37-2.054a1.143,1.143,0,1,1,1.9,1.268L15.8,11.891a1.143,1.143,0,1,1-1.9-1.268Zm-1.881-.684A1.145,1.145,0,0,0,13.6,9.622l1.383-2.074a1.144,1.144,0,1,0-1.9-1.27L11.7,8.352a1.142,1.142,0,0,0,.317,1.587Zm7.007,3.949-1.212,1.817a1.051,1.051,0,0,0,1.75,1.166l1.211-1.817a1.051,1.051,0,1,0-1.749-1.166Zm-3.071-.84a1.139,1.139,0,1,0,1.895,1.264l1.312-1.968a1.139,1.139,0,1,0-1.9-1.264L15.95,13.048ZM21.615,24a2.378,2.378,0,0,1-1.686-.7l-7.022-7.144a2.159,2.159,0,0,0-1.182-3.588l-3.074-.546L7.593,10.966A2.992,2.992,0,0,0,5.32,10.1,5.055,5.055,0,0,1,.277,3.4L3.491,6.61c1.253.074,3.192-1.865,3.118-3.119L3.4.277a5.052,5.052,0,0,1,6.7,5.044,2.989,2.989,0,0,0,.871,2.273l.027.027A2.142,2.142,0,0,0,12.8,11.078a2.25,2.25,0,0,0,2.1,2.411,2.048,2.048,0,0,0,1.789,2.371,2.055,2.055,0,0,0,3.857,1.315L23.3,19.93A2.384,2.384,0,0,1,21.615,24Zm.885-2.5a1,1,0,1,0-1,1A1,1,0,0,0,22.5,21.5Zm-9.631-3.939c-.667-.688-1.7-.739-3.584-.864a.437.437,0,0,0-.485.443l-.458,4.208s2.794,1.888,3.94,2.652c1.064-1.921,2.7-2.037,3.921-3l-3.334-3.437Zm-1.622-1.692a1.168,1.168,0,0,0,.3-2.308c-5.171-.919-4.9-.889-5.069-.889A1.338,1.338,0,0,0,5.172,13.75L4.726,17.7a1.61,1.61,0,0,0,.633,1.532c.487.354,2.026,1.449,2.026,1.449s.328-2.835.42-3.651a1.313,1.313,0,0,1,1.424-1.335c.092,0,1.859.178,2.018.178Z" transform="translate(0)" fill="#fff"/>
              </svg>
            </span>
              <span class="title">Servis Talepleri</span>
          </a-menu-item>

          <a-menu-item @click="openPage('/shippings')" v-if="this.RoleList.search('Servis') > -1 || this.RoleList.search('Sevk') > -1 || this.RoleList.search('Admin') > -1 || this.RoleList.search('Yönetici') > -1 || this.RoleList.search('Operasyon Yöneticisi') > -1 || this.RoleList.search('Operasyon Asistanı') > -1">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
                <path id="iconmonstr-delivery-5" d="M5,18H4a1,1,0,0,1-1-1V15H15V6h4.667A2.081,2.081,0,0,1,21.6,7.107c.594.94,1.536,2.432,2.109,3.378A2.006,2.006,0,0,1,24,11.52V16a1.892,1.892,0,0,1-2,2H21a3,3,0,0,1-6,0H11a3,3,0,0,1-6,0Zm3-1.2A1.2,1.2,0,1,1,6.8,18,1.2,1.2,0,0,1,8,16.8Zm10,0A1.2,1.2,0,1,1,16.8,18,1.2,1.2,0,0,1,18,16.8ZM8,8H0V6H8V5H3V4A1,1,0,0,1,4,3h9a1,1,0,0,1,1,1V14H3V11H1V9H8Zm9,0v3h4.715L20.288,8.5A1,1,0,0,0,19.42,8H17Z" transform="translate(0 -3)" fill="#fff" fill-rule="evenodd"/>
              </svg>
            </span>
            <span class="title">Sevk Hareketleri</span>
          </a-menu-item>
          
          <!-- 
            <a-menu-item @click="openPage('/shippings?IsApproved=false')">
              <span class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.667" height="25.556" viewBox="0 0 26.667 25.556">
                  <path id="iconmonstr-home-4" d="M13.333,10.094l7.778,7.238v7H5.556v-7l7.778-7.238Zm0-3.036-10,9.306V26.556h20V16.364Zm2.222,16.163H11.111V16.556h4.444Zm11.111-9.836-1.512,1.628L13.333,4.033,1.512,15,0,13.37,13.333,1,26.667,13.387Z" transform="translate(0 -1)" fill="#fff"/>
                </svg>
              </span>
              <span>Onay Beklenen Sevk Hareketleri</span>
            </a-menu-item>

            <a-menu-item @click="openPage('/shippings?IsApproved=true')">
              <span class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.667" height="25.556" viewBox="0 0 26.667 25.556">
                  <path id="iconmonstr-home-4" d="M13.333,10.094l7.778,7.238v7H5.556v-7l7.778-7.238Zm0-3.036-10,9.306V26.556h20V16.364Zm2.222,16.163H11.111V16.556h4.444Zm11.111-9.836-1.512,1.628L13.333,4.033,1.512,15,0,13.37,13.333,1,26.667,13.387Z" transform="translate(0 -1)" fill="#fff"/>
                </svg>
              </span>
              <span>Onaylanan Sevk Hareketleri</span>
            </a-menu-item> 
          -->

          <a-menu-item @click="openPage('/photo/photoList?sourceType=3')" v-if="this.RoleList.search('Sevk Sorumlusu') > -1 || this.RoleList.search('Admin') > -1 || this.RoleList.search('Yönetici') > -1 || this.RoleList.search('Operasyon Yöneticisi') > -1 || this.RoleList.search('Operasyon Asistanı') > -1">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17.5" viewBox="0 0 21 17.5">
                <path id="iconmonstr-photo-camera-4" d="M4.375,3.75H1.75V2.875H4.375Zm7,5.25A2.625,2.625,0,1,0,14,11.625,2.628,2.628,0,0,0,11.375,9ZM21,4.625V19.5H0V4.625H5.189a1.749,1.749,0,0,0,1.456-.78L7.875,2h7l1.23,1.845a1.749,1.749,0,0,0,1.456.78ZM4.375,8.125A.875.875,0,1,0,3.5,9,.875.875,0,0,0,4.375,8.125Zm11.375,3.5A4.375,4.375,0,1,0,11.375,16,4.375,4.375,0,0,0,15.75,11.625Z" transform="translate(0 -2)" fill="#fff"/>
              </svg>
            </span>
              <span  class="title">Katalog Çekimleri</span>
          </a-menu-item>

          <a-menu-item @click="openPage('/photo/photoList?sourceType=10')" v-if="userIdGet == 1001877 || this.RoleList.search('Admin') > -1">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17.5" viewBox="0 0 21 17.5">
                <path id="iconmonstr-photo-camera-4" d="M4.375,3.75H1.75V2.875H4.375Zm7,5.25A2.625,2.625,0,1,0,14,11.625,2.628,2.628,0,0,0,11.375,9ZM21,4.625V19.5H0V4.625H5.189a1.749,1.749,0,0,0,1.456-.78L7.875,2h7l1.23,1.845a1.749,1.749,0,0,0,1.456.78ZM4.375,8.125A.875.875,0,1,0,3.5,9,.875.875,0,0,0,4.375,8.125Zm11.375,3.5A4.375,4.375,0,1,0,11.375,16,4.375,4.375,0,0,0,15.75,11.625Z" transform="translate(0 -2)" fill="#fff"/>
              </svg>
            </span>
              <span  class="title">ismakinesi.com</span>
          </a-menu-item>

          <!-- 
          <a-menu-item @click="openPage('/About')">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17.5" viewBox="0 0 21 17.5">
                <path id="iconmonstr-photo-camera-4" d="M4.375,3.75H1.75V2.875H4.375Zm7,5.25A2.625,2.625,0,1,0,14,11.625,2.628,2.628,0,0,0,11.375,9ZM21,4.625V19.5H0V4.625H5.189a1.749,1.749,0,0,0,1.456-.78L7.875,2h7l1.23,1.845a1.749,1.749,0,0,0,1.456.78ZM4.375,8.125A.875.875,0,1,0,3.5,9,.875.875,0,0,0,4.375,8.125Zm11.375,3.5A4.375,4.375,0,1,0,11.375,16,4.375,4.375,0,0,0,15.75,11.625Z" transform="translate(0 -2)" fill="#fff"/>
              </svg>
            </span>
            <span  class="title">İmza Örnek</span>
          </a-menu-item>
          
          <a-menu-item @click="openPage('/konum')">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                <path id="iconmonstr-email-13" d="M12,9.212a5.525,5.525,0,0,1-.6,2.312c-.883,1.732-2.68,1.721-2.68-.123a5.205,5.205,0,0,1,.6-2.257C10.219,7.459,12,7.723,12,9.212ZM21,10.5A10.5,10.5,0,1,1,10.5,0,10.5,10.5,0,0,1,21,10.5ZM16.625,9.455c0-2.84-2.3-4.9-5.474-4.9-4.346,0-6.776,2.755-6.776,6.189,0,3.213,2.159,5.7,6.146,5.7A8.72,8.72,0,0,0,15,15.319l-.6-.867a6.863,6.863,0,0,1-3.724.994c-3,0-5.016-1.924-5.016-4.789,0-5,5.6-5.913,8.062-4.437a3.984,3.984,0,0,1,.509,6.2c-.9.723-1.924.7-1.593-.661,0,0,1.061-3.928,1.185-4.353H12.631l-.237.833A1.667,1.667,0,0,0,10.721,7.1c-1.766,0-3.257,1.916-3.257,4.185,0,1.514.8,2.453,2.083,2.453a2.326,2.326,0,0,0,2.068-1.2c-.262,2.536,5.009,1.362,5.009-3.084Z" fill="#fff"/>
              </svg>
            </span>
            <span  class="title">Konum</span>
          </a-menu-item> -->
    
          <a-menu-item @click="openPage('/')">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                <path id="iconmonstr-email-13" d="M12,9.212a5.525,5.525,0,0,1-.6,2.312c-.883,1.732-2.68,1.721-2.68-.123a5.205,5.205,0,0,1,.6-2.257C10.219,7.459,12,7.723,12,9.212ZM21,10.5A10.5,10.5,0,1,1,10.5,0,10.5,10.5,0,0,1,21,10.5ZM16.625,9.455c0-2.84-2.3-4.9-5.474-4.9-4.346,0-6.776,2.755-6.776,6.189,0,3.213,2.159,5.7,6.146,5.7A8.72,8.72,0,0,0,15,15.319l-.6-.867a6.863,6.863,0,0,1-3.724.994c-3,0-5.016-1.924-5.016-4.789,0-5,5.6-5.913,8.062-4.437a3.984,3.984,0,0,1,.509,6.2c-.9.723-1.924.7-1.593-.661,0,0,1.061-3.928,1.185-4.353H12.631l-.237.833A1.667,1.667,0,0,0,10.721,7.1c-1.766,0-3.257,1.916-3.257,4.185,0,1.514.8,2.453,2.083,2.453a2.326,2.326,0,0,0,2.068-1.2c-.262,2.536,5.009,1.362,5.009-3.084Z" fill="#fff"/>
              </svg>
            </span>
            <span  class="title">crmdestek@hasel.com</span>
          </a-menu-item>

        </a-menu>
      
      </div>

    </a-drawer>
  </div>

  
</template>
<script>

export default {
  data() {
    return {
      userIdGet:  localStorage.getItem('userId'),
      current: ['mail'],
      openKeys: ['sub1'],
      visible: false,
      NameSurname: localStorage.getItem('Name'),
      personelTitle: null,
      RoleList: localStorage.getItem('RoleList'),
    };
  },
  created() {
    this.personelTitle = localStorage.getItem('Title')
    var UserDataControl = localStorage.getItem('getData')
    if(!UserDataControl) {
        this.getUserDetail()
    }
  },
  watch: {
    openKeys(val) {
      console.log('openKeys', val);
    },
  },
  methods: {
    logout() {
        var logoutData = {}
        logoutData.isAllLogout = true

        this.$axios.post('/token/logout/true' + "?isAllLogout=true");
        this.$message
                      .loading('Lütfen bekleyin..', 2.5)
                      .then(() => this.$message.success('Çıkış Yapıldı', 2.5))
                      
        window.localStorage.clear();
    },
    getUserDetail() {
        var getUserId = localStorage.getItem('userId')
        this.$axios.get("/user/GetUser/" + getUserId).then(resp => {
                var Title = resp.data.Entity.Title
                console.log(Title)
                localStorage.setItem('Title', Title);
                localStorage.setItem('getData', true);

                this.personelTitle = Title
                

         }).catch(e => { console.log(e) })
    },
    setMessage() {
        alert("menü")
    },
    handleClick(e) {
      console.log('click', e);
    },
    titleClick(e) {
      console.log('titleClick', e);
    },
    afterVisibleChange(val) {
      console.log('visible', val);
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    openPage: function (link) {
        this.$router.push(link)
    },

  },
};
</script>

<style>
    .ant-drawer-body {
        padding: 24px 0 !important;
    }
</style>