<template>
  <div>
     <menus ref="openMenu"></menus>

    <div class="mheader" style="height: 65px; overflow: hidden;">
      <div class="row">
          <div class="col-xs-2">
              <svg @click="openMenu" class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
                <path id="Menu" d="M-6160,12V10.285h8.751V12Zm0-5.143V5.143h14V6.857Zm0-5.143V0h14V1.715Z" transform="translate(6160)" fill="#fff"/>
              </svg>
          </div>
          <div class="col-xs-8">
              <span class="title">
                HASEL CRM
              </span>
          </div>
      </div>
    </div>
    <div v-if="this.RoleList.search('Sevk') > -1 || this.RoleList.search('Admin') > -1 || this.RoleList.search('Admin') > -1 || this.RoleList.search('Yönetici') > -1 || this.RoleList.search('Operasyon Yöneticisi') > -1 || this.RoleList.search('Operasyon Asistanı') > -1" class="content shippingList ProductListPage" style="padding-top: 65px; padding-bottom: 0px;">
         <div style="padding: 0 25px">
                      <h4 style="margin:20px 0">Sevk İşlemleri</h4>
            <a-row :gutter="16">
              <a-col :span="12" @click="openPage('/shippings?IsApproved=false')">
                <a-card>
                  <a-statistic
                    title="Onay Bekleyen"
                    :value="count2"
                    class="demo-class"
                    :value-style="{ color: '#ff4633' }">
                    <template #prefix>
                      <a-icon type="arrow-down" />
                    </template>
                  </a-statistic>
                </a-card>
              </a-col>

              <a-col :span="12" @click="openPage('/shippings?IsApproved=true')">
                <a-card>
                  <a-statistic
                    title="Onaylanan"
                    :value="count1"
                    :value-style="{ color: '#3f8600' }"
                    style="margin-right: 30px">
                    <template #prefix>
                      <a-icon type="arrow-up" />
                    </template>
                  </a-statistic>
                </a-card>
              </a-col>
            </a-row>
          </div>
    </div>


     <div v-if="this.RoleList.search('Servis') > -1 || this.RoleList.search('Admin') > -1 || this.RoleList.search('Admin') > -1 || this.RoleList.search('Yönetici') > -1 || this.RoleList.search('Operasyon Yöneticisi') > -1 || this.RoleList.search('Operasyon Asistanı') > -1" class="content shippingList ProductListPage" style="padding-top: 70px; padding-bottom: 0px;">
         <div style="padding: 0 25px">
           <h4 style="margin-bottom:20px">Servis İşlemleri</h4>
            <a-row :gutter="16">

                <a-col :span="12" @click="openPage('/service?status=0')">
                <a-card>
                  <a-statistic
                    title="İşe Başlandı"
                    :value="serviceStatusDataList.IseBaslanan"
                    class="demo-class"
                    :value-style="{ color: '#3f8600' }">
                    <template #prefix>
                      <a-icon type="arrow-down" />
                    </template>
                  </a-statistic>
                </a-card>
              </a-col>


              <a-col :span="12" @click="openPage('/service?status=4')">
                <a-card>
                  <a-statistic
                    title="Randevu Bekliyor"
                    :value="serviceStatusDataList.RandevuBekleyen"
                    class="demo-class"
                    :value-style="{ color: '#ff4633' }">
                    <template #prefix>
                      <a-icon type="arrow-down" />
                    </template>
                  </a-statistic>
                </a-card>
              </a-col>

              
              <a-col :span="12" @click="openPage('/service?status=5')">
                <a-card>
                  <a-statistic
                    title="Randevu Verildi"
                    :value="serviceStatusDataList.RandevuVerilen"
                    class="demo-class"
                    :value-style="{ color: '#3f8600' }">
                    <template #prefix>
                      <a-icon type="arrow-down" />
                    </template>
                  </a-statistic>
                </a-card>
              </a-col>


            <a-col :span="12" @click="openPage('/service?status=8')">
                <a-card>
                  <a-statistic
                    title="Onay Bekliyor"
                    :value="serviceStatusDataList.OnayBekleyen"
                    class="demo-class"
                    :value-style="{ color: '#3f8600' }">
                    <template #prefix>
                      <a-icon type="arrow-down" />
                    </template>
                  </a-statistic>
                </a-card>
              </a-col>

              <a-col :span="12" @click="openPage('/service?status=2')">
                <a-card>
                  <a-statistic
                    title="Müşteri Onayı Bekliyor"
                    :value="serviceStatusDataList.MusteriOnayiBekleyen"
                    class="demo-class"
                    :value-style="{ color: '#3f8600' }">
                    <template #prefix>
                      <a-icon type="arrow-down" />
                    </template>
                  </a-statistic>
                </a-card>
              </a-col>


              <a-col :span="12" @click="openPage('/service?status=11')">
                <a-card>
                  <a-statistic
                    title="Fatura Kesilebilir"
                    :value="serviceStatusDataList.FaturaKesilebilir"
                    class="demo-class"
                    :value-style="{ color: '#3f8600' }">
                    <template #prefix>
                      <a-icon type="arrow-down" />
                    </template>
                  </a-statistic>
                </a-card>
              </a-col>



            </a-row>
          </div>
    </div>

  </div>
</template>

<script>

  // function notifyMe() {
  //     if (!Notification) {
  //         alert('Lütfen Chrome, Firefox, Opera, Internet Explorer veya Spartan\'ın güncel bir versiyonunu kullanınız...');
  //         return;
  //     }
      
  //     if (Notification.permission !== "granted")
  //         Notification.requestPermission();

  //     var notification = new Notification('Hoşgeldiniz!', {
  //         icon: 'https://www.hasel.com/assets/layoutmedia/linde-hasel-logo.svg?v=3',
  //         body: "Bizimle iletişime geçmek için tıklayın!",
  //     });
  //     notification.onclick = function () {
  //         window.open("https://www.hasel.com/tr/iletisim");
  //     }
  // }

  // window.onload = notifyMe;


  import moment from "moment";
  import Menus from './Menu';
  export default {
    name: "App",
    data() {
      return {
        moment,
        count1: 0,
        count2: 0,
        shippingId : 0,
        
        // Filter
        filter: { Skip: 0, PageSize: 2 },
        details: {},

        serviceStatusDataList: {},

        RoleList: localStorage.getItem('RoleList'),
      };
    },
    components: {
      Menus
    },
    created() {
      this.shippingId = this.$route.query.shippingId
      this.serviceStatusData()
      this.getItems()
    },
    methods: {

        openPage: function (link) {
            this.$router.push(link)
        },

        openMenu () {
          this.$refs.openMenu.showDrawer()
        },

        async serviceStatusData() {
          await this.$axios.get("/ServiceRequests/GetServiceSummary?").then(resp => {
              this.serviceStatusDataList= resp.data.Entity
              //alert(this.count.IsApprovedTrue)
          }).catch((error) => { console.log(error) })

        },

        async getItems() {
          this.filter.IsApproved = true
          await this.$axios.get("/Shippings/GetShippingList?", { params: this.filter }).then(resp => {
              this.count1= resp.data.Count
              //alert(this.count.IsApprovedTrue)
          }).catch((error) => { console.log(error) })

          this.filter = {}

          this.filter.IsNotApproved = true
          this.$axios.get("/Shippings/GetShippingList?", { params: this.filter }).then(resp => {
              this.count2 = resp.data.Count
          }).catch((error) => { console.log(error) })
          
        },

    },
  };
</script>

<style>
.ant-card-bordered {
    border: 1px solid #e8e8e8;
    height: 121px;
    margin-bottom: 20px;
}
</style>