<template>
<div>
    <div class="subModule" style="margin:0">

        <div class="moduleHeader pageHeader">
             <div class="left">
                <h3>Ürünler</h3>
            </div>
            
            <div class="right" v-if="this.iseditable == true && this.shippingdata.ShippingStatus == false">
                <a @click="productSelectModalClick">
                    <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
                        <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#656565"/>
                    </svg>
                </a>
            </div>
             
            <div class="clear"></div>
        </div>

        <div class="moduleContent">
            <div class="warning" v-if="this.warningControl" style="padding:25px 25px">
                <span>
                    <svg class="text-left" id="Warning" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
                        <path id="Path_35" data-name="Path 35" d="M7,0a7,7,0,1,0,7,7A7.021,7.021,0,0,0,7,0Zm.963,10.675H6.037V9.012H8.05v1.663ZM8.05,4.2,7.525,8.05H6.475L5.95,4.2V3.325H8.137V4.2Z" fill="#ff1119"/>
                    </svg>
                    Sevke kayıtlı ürün bulunamadı
                </span>
            </div>

            <div class="ShippingProductList row">
                <div class="col-md-12">
                    <div class="itemProduct row" v-for="item in dataquery" :key="item.Id">
                        <a class="href" @click="productClick(item)">
                            <div class="col-xs-1" :class="{ approved: item.PhotoStatus }">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                    <g id="Group_58" data-name="Group 58" transform="translate(0 0)">
                                        <g id="Rectangle_1482" data-name="Rectangle 1482" transform="translate(0 0)" fill="#fff" stroke="#2ecc71" stroke-width="1">
                                        <rect width="12" height="12" rx="4" stroke="none"/>
                                        <rect x="0.5" y="0.5" width="11" height="11" rx="3.5" fill="none"/>
                                        </g>
                                        <path id="Checkbox" d="M2.379,4.138,0,1.759l.483-.483,1.9,1.862L5.517,0,6,.483Z" transform="translate(3 4)" fill="#2ecc71"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="col-xs-6" style="padding-right: 0;">
                                <div class="title">
                                    <span class="serino" v-if="item.SerialNo">{{ item.SerialNo }}</span>
                                    <span class="serino" v-else>Ürün Id: {{ item.ProductId }}</span>
                                    <span class="serino" v-if="item.RentalRefNo">{{ item.RentalRefNo }}</span>
                                    <span class="serino" :title="item.ProductDisplayName">{{ item.ProductDisplayName.split('/')[0] }} / {{ item.ProductDisplayName.split('/')[1] }}</span>
                                    <!-- <span class="serino" v-if="item.CreateUserName">{{ item.CreateUserName }} ({{new Date(item.CreateDate).toLocaleString().split(' ')[0]}})</span> -->

                                </div>
                            </div>

                            <div class="col-xs-4" style="float: right;">
                                <div class="row">
                                    <div class="col-xs-6 shippingCol6">
                                        <div class="box box_1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 12 12">
                                                <path id="Path_103" data-name="Path 103" d="M136,12a6,6,0,1,1,6-6A6.018,6.018,0,0,1,136,12Zm3-9-2.925,1.35a3.84,3.84,0,0,0-1.725,1.725L133,9l2.925-1.35a3.84,3.84,0,0,0,1.725-1.725Z" transform="translate(-130)" fill="#a8a7a7" fill-rule="evenodd"/>
                                            </svg>
                                            <span v-if="item.WorkTimeValue">{{ item.WorkTimeValue }}</span>
                                            <span v-if="!item.WorkTimeValue"> 0 </span>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 shippingCol6">
                                        <div class="box box_1" style="   border-left: 1px solid #eee; padding-left: 12px;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 12 11">
                                                <path id="Path_110" data-name="Path 110" d="M6,7.333A1.467,1.467,0,1,0,4.5,5.867,1.488,1.488,0,0,0,6,7.333Zm0-4.4A2.976,2.976,0,0,1,9,5.867a3,3,0,0,1-6,0A2.976,2.976,0,0,1,6,2.933ZM10.5,11h-9a1.5,1.5,0,0,1-1.05-.44A1.432,1.432,0,0,1,0,9.533v-6.6A1.488,1.488,0,0,1,1.5,1.467H2.7L3.825.367A1.256,1.256,0,0,1,4.725,0H7.35a1.217,1.217,0,0,1,.825.367L9.3,1.467h1.2A1.488,1.488,0,0,1,12,2.933v6.6A1.488,1.488,0,0,1,10.5,11Z" fill="#a8a7a7" fill-rule="evenodd"/>
                                            </svg>
                                            <span v-if="item.ProductPhotoList"> {{ item.ProductPhotoList.length }}</span>
                                            <span v-if="!item.ProductPhotoList"> 0 </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </div>

                </div>

            </div>
        </div>
    </div>

    <a-drawer
      placement="right"
      :closable="false"
      :visible="productSelectModal"
      wrapClassName = "productSelectModal"
      @close="productModalClose">
      <productlist  @parentmodalclose="productModalClose" @parentgetproduct="productSelectItem=$event"></productlist>
    </a-drawer>


</div>
</template>

<script>
import productlist from '../product/ProductList';
export default {
  data() {
        return {
            dataquery: [],
            warningControl: false,
            shippingId: null,

            // Ürün Seç
            productlist: [],
            productSelectItem : {},
            productSelectControl: false,
            productSelectModal : false,
            newProductId : null,
            locationcheck: null,

        };
    },
    props: ['shippingdata','iseditable'],
    components: {
      productlist
    },
    created() {
        this.getItems()
        this.shippingId = this.shippingdata.ShippingId 

    },
    watch: {
        productSelectItem() {
            //console.log(this.productSelectItem)
            this.productlist = this.productSelectItem
            this.newProductId = this.productlist[0].key
            this.locationcheck = this.productlist[0].LocationId
            
            console.log("Ürün id: " + this.newProductId + "- " + "Sevk Lokasyon: " + this.shippingdata.FromLocationId + " - " + "Ürün lokasyon: "  + this.locationcheck)

            if (this.locationcheck == this.shippingdata.FromLocationId || this.locationcheck == null) {
                this.addProductShipping()
            } else {
                this.$message.error({ content: 'Bu ürün başka lokasyonda bulunmaktadır. Bu ürünü sevke ekleyemezsiniz...'});
            }
            
        }
        
    },
    methods: {
        // Ürün seç
        addProductShipping() {
            var newDate = {}
            
            newDate.CompanyId = 1
            newDate.CompanyLocationId = 1
            newDate.ProductId = this.newProductId
            newDate.ProductPhotoList = []
            newDate.ShippingId = this.$route.params.id

            this.$axios.post("/shippings/AddOrUpdateProduct?", newDate).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.$message.success({ content: 'Sevke ürün eklendi.'});
                    this.getItems()
                }
                else {
                    this.$message.success({ content: resp.data.ExceptionMessage});
                }
            }).catch(e => { console.log(e) })
        },
        
        productSelectModalClick() {
            this.productSelectModal = true
            this.$root.productSelectModal = true
        },

        productModalClose() {
            this.productSelectModal = false;
        },
        // Ürün seç

        productClick(item)  {
            //console.log(item)

            if(item.WorkTimeId > 1 ) {
                let url = '/photo/photoDetail?shippingId=' + this.shippingId + '&dataProductId=' + item.WorkTimeId + '&sourceType=1'
                //console.log(url)
                this.$router.push(url)
            }

            if(item.WorkTimeId == null ) {
                if(this.iseditable == true && this.shippingdata.ShippingStatus == false) {
                    let url = '/photo/photoCreate?shippingId=' + this.shippingId + '&dataProductId=' + item.Id + '&sourceType=1'
                    //console.log(url)
                    this.$router.push(url)
                } else {
                    var dataError = 'Bu ürünün çalışma saatini güncelleme yetkiniz bulunmamaktadır.'
                    if(this.shippingdata.ShippingStatus == true) dataError = 'Sevk onaylı olduğu için çalışma saati girişi yapılamaz.'
                    this.$message.error(dataError);
                }
                
            }
        },
        getItems() {
            this.$axios.get("/Shippings/GetShippingProductList/" + this.shippingdata.ShippingId).then(resp => {
                this.dataquery = resp.data.List, 
                this.dataquery.length = resp.data.Count,
                this.warningControl = (this.dataquery.length <= 0) ? true : false
                this.$emit('productlist', this.dataquery)
            })
        },
    },
};
</script>
