<template>
  <div>
    <menus ref="openMenu"></menus>
    <div class="mheader" style="height: 63px; overflow: hidden;">
      <div class="row">
          <div class="col-xs-2">
              <svg @click="prevGo" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                <g id="back" transform="translate(-1)">
                  <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                </g>
              </svg>
          </div>
          <div class="col-xs-8">
              <span class="title">
                 {{ this.pageTitle }}
              </span>
          </div>
          <div class="col-xs-2 text-right formBtn">
                <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                    <a-form-item :wrapper-col="{ span: 12 }">
                        <a-button type="primary" html-type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                            </svg>
                        </a-button>
                    </a-form-item>
                </a-form>
          </div>
      </div>
    </div>

    <div class="content" style="padding-top: 63px;">
        <div class="formStyle">
            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">

                <div class="pageHeader">
                    <h3>Genel Bilgi</h3>
                </div>

                <a-form-item label="Sevk Türü">
                    <a-select
                        v-decorator="[
                        'shippingType',
                        { rules: [{ required: true, message: 'Lütfen sevk türü seçiniz!' }] },
                        ]"
                        placeholder="Seçim yapınız"
                        @change="shippingTypeChange($event)">
                         <a-select-option v-for="c1 in shippingTypeList" :key="c1.Id">
                            {{ c1.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                

                <div class="pageHeader">
                    <h3>Gönderen Bilgileri</h3>
                </div>

                <a-form-item label="Gönderen Firma">
                     <a-select
                        mode="default"
                        placeholder="Firma seçiniz"
                        :showSearch="true"
                        label-in-value
                        :filter-option="false"
                        v-decorator="['fromCompanyId', { initialValue: fromCompanyData[0], rules: [{ required: true, message: 'Gönderen firma alanı zorunludur!' }] }]"
                        @search="fromCompanySearch"
                        @change="fromCompamySearchChange($event)">
                        <a-icon slot="suffixIcon" type="search" />
                        <a-select-option v-for="c1 in fromCompanyData" :key="c1.Id">
                            {{ c1.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>


                <a-form-item label="Gönderen Lokasyon">
                    <a-select
                        label-in-value
                        v-decorator="['fromLocationId', { initialValue: { label: this.item.FromLocationName, key: this.item.FromLocationId }, rules: [{ required: true, message: 'Gönderen lokasyon alanı zorunludur!' }] }]"
                        placeholder="Seçim Yapınız"
                        @change="fromLocationChange($event)" @mouseenter="fromLocationChange">
                        <a-select-option v-for="item in fromLocationsList" :key="item.Id" :value="item.Id">
                            {{ item.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <div style="width:55%; float:left;">
                    <a-form-item label="Sevk Tarihi">
                        <a-date-picker 
                        placeholder="Sevk tarihi" 
                        v-decorator="['fromDate', { initialValue: this.fromDate, rules: [{ required: true, message: 'Sevk tarihi zorunludur!' }] }]"
                        @change="fromDateChange" />
                    </a-form-item>
                </div>

                <div style="width:45%; float:left; padding-left:0px; margin-top: -5px; position: relative;">
                    <a-form-item label="Sevk Saati">
                        <a-select
                        label-in-value
                        v-decorator="['fromHours', {  initialValue: fromHourList[0], rules: [{ required: true, message: 'Sevk saati zorunludur!' }] }]"
                        placeholder="Sevk Saati"
                        @change="fromHourChange($event)">
                            <a-select-option v-for="item in hourList" :key="item" :value="item">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </div>

                <div class="clearfix"></div>

                <div class="pageHeader">
                    <h3>Alıcı Bilgileri</h3>
                </div>

                <a-form-item label="Alıcı Firma">
                     <a-select
                        mode="default"
                        placeholder="Arama Yap"
                        :showSearch="true"
                        label-in-value
                        :filter-option="false"
                        v-decorator="['toCompanyId', {  initialValue: toCompanyData[0], rules: [{ required: true, message: 'Alıcı firma alanı zorunludur!' }] }]"
                        @search="toCompanySearch"
                        @change="toCompamySearchChange($event)">
                        <a-icon slot="suffixIcon" type="search" />
                        <a-select-option v-for="c2 in toCompanyData" :key="c2.Id">
                        {{ c2.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="Alıcı Lokasyon">
                    <a-select
                        label-in-value
                        v-decorator="['ToLocationId', { initialValue: { label: this.item.ToLocationName, key: this.item.ToLocationId }, rules: [{ required: true, message: 'Gönderen lokasyon alanı zorunludur!' }] }]"
                        placeholder="Seçim Yapınız"
                        @change="toLocationChange($event)" @mouseenter="toLocationChange">
                        <a-select-option v-for="item in toLocationsList" :key="item.Id" :value="item.Id">
                            {{ item.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

               
                <div style="width:55%; float:left;">
                      <a-form-item label="Alıcı Tarihi">
                        <a-date-picker 
                        placeholder="Alıcı tarihi" 
                        v-decorator="['toDate', { initialValue: this.toDate, rules: [{ required: true, message: 'Alıcı tarihi zorunludur!' }] }]"
                        @change="fromDateChange" />
                    </a-form-item>
                </div>

                <div style="width:45%; float:left; padding-left:0px; margin-top: -5px; position: relative;">
                    <a-form-item label="Alıcı Saati">
                        <a-select
                        label-in-value
                        v-decorator="['toHours', {  initialValue: toHourList[0], rules: [{ required: true, message: 'Alıcı saati zorunludur!' }] }]"
                        placeholder="Alıcı Saati"
                        @change="fromHourChange($event)">
                            <a-select-option v-for="item in hourList" :key="item" :value="item">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </div>

                <div class="clearfix"></div>
                
            </a-form>
        </div>
    </div>


  </div>
</template>


<script>
  import Menus from '../Menu';
  export default {
    name: "App",
    data() {
      return {
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'coordinated' }),

        // From Company
        fromCompanyData: [],
        selectFromCompany: [],
        fromCompanyId : '',
        fromLocationsList: [],

        // To Company
        toCompanyData: [],
        selectToCompany: [],
        toCompanyId : '',
        toLocationsList: [],

        shippingTypeList: [{ Id: 1, Name: 'Giden Sevk' }, { Id: 2, Name: 'Gelen Sevk' }, { Id: 3, Name: 'Transfer' }],

        // Hours
        hourList: ['00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45', '03:00', '03:15', '03:30', '03:45', '04:00', '04:15', '04:30', '04:45', '05:00', '05:15', '05:30', '05:45', '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45', '08:00', '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '15:45', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45'],

        // Data 
        addShippingData: {},
        shippingAdd: {},

        // Form
        createId: null,

        editId : 0,
        editPage : false,

        item: {},

        fromDate: null,
        toDate : null,
        fromHourList: [],
        toHourList: [],

        pageTitle : "Yeni Sevk Kayıtı",

        successText: null,

      };
    },
    components: {
      Menus
    },
    created() {
        this.editId = this.$route.params.id
        if(this.editId > 1) {
           this.editPage  = true
           this.getShippingDetail()
           this.pageTitle = "Sevk Düzenle #" + this.editId
        }
        
    },
    methods: {
        openMenu () {
          this.$refs.openMenu.showDrawer()
        },

        prevGo () {
          this.$router.push("/shippings")
          //this.$router.go(-1)
        },

        shippingTypeChange(event) {
            this.addShippingData.shippingTypeId = event
            console.log(this.addShippingData.shippingTypeId)
        },

        // From Compamny
        fromCompamySearchChange(event) {
            this.addShippingData.fromCompanyId = event.key
            this.getFromCompanyLocations()
            console.log(this.addShippingData.fromCompanyId)
        },

        fromLocationChange(event) {
            this.addShippingData.fromLocationId = event
            console.log(event)
        },

        fromDateChange(date, dateString) {
            console.log(date, dateString);
        },

        fromHourChange(event) {
            this.addShippingData.fromHour = event
            console.log(event)
        },

        fromCompanySearch(searchFromCompany) {
            if (searchFromCompany.length >= 3) {
                this.$axios.get("/company/searchcompany/" + searchFromCompany + "/0/10/").then(resp => {
                    this.fromCompanyData =  resp.data.List;
                });
            }
        },

        async getFromCompanyLocations() {
            await this.$axios.get("/location/GetLocationsForEmployeeByCompany/" + this.addShippingData.fromCompanyId).then(response => { this.fromLocationsList = response.data.List });
        },

        // To Company
        toCompamySearchChange(event) {
            this.addShippingData.toCompanyId = event.key
            this.gettoCompanyLocations()
            console.log(this.addShippingData.toCompanyId)
        },

         toLocationChange(event) {
            this.addShippingData.toLocationId = event
            console.log(event)
        },

        toCompanySearch(searchToCompany) {
            if (searchToCompany.length >= 3) {
                this.$axios.get("/company/searchcompany/" + searchToCompany + "/0/10/").then(resp => {
                    this.toCompanyData =  resp.data.List;
                });
            }
        },

        toDateChange(date, dateString) {
            console.log(date, dateString);
        },

        toHourChange(event) {
            this.addShippingData.toHour = event
            console.log(event)
        },

        async gettoCompanyLocations() {
            await this.$axios.get("/location/GetLocationsForEmployeeByCompany/" + this.addShippingData.toCompanyId).then(response => { this.toLocationsList = response.data.List });
        },


        // Edit Page Data Query
        getShippingDetail() {
            this.$axios.get("/Shippings/GetShipping/" + this.editId).then(response => {
                this.item = response.data.Entity   
                
                this.form.setFieldsValue({ shippingType: this.item.ShippingType })

                this.fromCompanyData = [{ key: this.item.FromCompanyId, label: this.item.FromCompanyName }]
                this.toCompanyData = [{ key: this.item.ToCompanyId, label: this.item.ToCompanyName }]

                //this.fromLocationsList = [{ key: this.item.FromLocationId, label: this.item.FromLocationName }]
                this.toLocationsList = [{ key: this.item.ToLocationId, label: this.item.ToLocationName }]

                this.fromHourList = [ { key: this.item.FromHours } ]
                this.toHourList = [ { key: this.item.ToHours } ]

                this.fromDate =  this.item.FromDate
                this.toDate =  this.item.ToDate

                this.addShippingData.fromCompanyId = this.item.FromCompanyId
                this.addShippingData.toCompanyId = this.item.ToCompanyId
            
                this.getFromCompanyLocations()
                this.gettoCompanyLocations()

                //this.form.setFieldsValue({ toDate: this.item.ToDate })
                 
                //this.form.setFieldsValue({ fromCompanyId: this.item.FromCompanyId })


            })
        },

        // Form
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {

                    if(this.editPage == true) {
                        values.Id  = this.item.Id
                    }

                    values.toCompanyId  = values.toCompanyId.key
                    values.fromCompanyId  = values.fromCompanyId.key

                    values.ToLocationId  = values.ToLocationId.key
                    values.fromLocationId  = values.fromLocationId.key

                    values.fromHours  = values.fromHours.key
                    values.toHours  = values.toHours.key

                    if(this.editPage == false) {
                        this.successText = "Yeni sevk oluşturuldu."
                    } else {
                        this.successText = "Sevk düzenlendi."
                    }

                    this.$axios.post("/Shippings/addOrUpdate", values).then(resp => {
                        if (resp.data.IsSuccessful == true) {
                             this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.success(this.successText, 2.5))
                            this.createId = resp.data.Entity

                            
                            var url = "/shipping/detail/" + this.createId
                            this.$router.push(url)

                            this.shippingAdd = {}
                            this.addShippingData = {}
                        }
                        else {
                            this.$message
                                .loading('Lütfen bekleyin..', 2.5)
                                .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                        }
                    }).catch(e => { console.log(e) })


                } else {
                        this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error('Hata oluştu tekrar deneyin!', 2.5))
                }
            });

        },

        
    },
  };
</script>

<style></style>