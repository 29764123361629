<template>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-xs-12">
          <VueSignaturePad
            id="signature"
            width="100%"
            height="500px"
            ref="signaturePad"
            :options="options"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-xs-3">
          <button class="btn btn-outline-secondary" @click="clear">Temizle</button>
        </div>
        <!-- 
          <div class="col-md-3 col-xs-3">
            <button class="btn btn-outline-primary" @click="save">Save</button>
          </div> 
        -->
      </div>
    </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    options: {
      penColor: "#333"
    }
  }),
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      //alert("Open DevTools see the save data.");
      console.log("kontrol " + isEmpty);
      console.log(data);
    },
    resume() {
      this.options = {
        penColor: "#333"
      };
    }
  }
};
</script>

<style>
    #signature {
        border: double 3px transparent;
        border-radius: 5px;
        background-image: linear-gradient(white, white),
            radial-gradient(circle at top left, #eee, #eee);
        background-origin: border-box;
        background-clip: content-box, border-box;
    }
</style>