<template>
  <div>
    <div class="mheader" style="height: 130px; overflow: hidden;">
      <div class="row">

          <div >
              <div class="col-xs-2">
                  <svg @click="modalClose"  class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                    <g id="back" transform="translate(-1)">
                      <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                    </g>
                  </svg>
              </div>
              <div class="col-xs-8">
                  <span class="title">
                     Ürün Seçimi
                  </span>
              </div>
               <div class="col-xs-2 text-right">
                  <span style="float:right;">
                      <a-icon @click="filterDialog" style="font-size: 18px; margin-left:20px;" type="filter" />
                  </span>
              </div>
          </div>

          <productheader v-if="this.$root.productSelectModal == false"></productheader>

          <div class="clear"></div>
          <div class="col-xs-12">
              <div class="searchInput" style="width: 100%; display: inline-block; float: left;">
                <a-input-search placeholder="Arama yapın" @search="onSearch"/>
              </div>
          </div>

          
      </div>
    </div>

    <div class="content shippingList ProductListPage" style="padding-top: 130px;">
         <div class="ShippingProductList row">
            <div class="col-md-12">
                <div class="itemProduct row" v-for="item in dataquery" :key="item.SerialNo">
                    <a class="href" @click="goDetail(item)">
                        <div class="col-xs-12">
                            <div class="title">
                                <span class="serino" v-if="item.SerialNo">{{ item.SerialNo }}</span>
                                <span class="serino" v-else>{{ item.Pno }}</span>
                                <span class="info" :title="item.DisplayName">{{ item.DisplayName }}</span>
                            </div>
                        </div>
                    </a>
                </div>

            </div>

        </div>
    </div>


    
    <a-drawer
      placement="right"
      :closable="false"
      :visible="filterVisible"
      wrapClassName="filterDialog"
      @close="filterClose">

     <div class="mheader" style="height: 66px; overflow: hidden;">
        <div class="row">
            <div class="col-xs-2">
                <svg @click="filterClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                  <g id="back" transform="translate(-1)">
                    <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                  </g>
                </svg>
            </div>
            <div class="col-xs-8">
                <span class="title">
                   Filtre
                </span>
            </div>
            <div class="col-xs-2 text-right formBtn">
                  <svg @click="filterSubmit" xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                      <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                  </svg>
            </div>
        </div>
      </div>

       <div class="content" style="padding-top: 63px;">
        <div class="formStyle" style="margin-top: 25px;">
            <form class="ant-form ant-form-horizontal">

                
                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Ürün Seri No</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-input pressEnter="filterSubmit()" v-model="filter.SerialNo" placeholder="Ürün Seri No" />
                        </div>
                    </div>
                </div>

                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Referans No" class="">Ürün Referans No</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-input pressEnter="filterSubmit()" v-model="filter.ProjectCode" placeholder="Ürün Referans No" />
                        </div>
                    </div>
                </div>


            </form>
        </div>
    </div>
      
    </a-drawer>


  </div>
</template>

<script>
  import productheader from '../product/ProductHeader';
  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'coordinated' }),
        moment,
        dataquery: [],
        shippingId : 0,

        // Ürün Ekle
        addProductModal : false,
        categoryList:  [{ Name: 'Arıza', Id: 1 }, { Name: 'Bakım', Id: 2 }, { Name: 'Teslimat', Id: 3 }, { Name: 'Demo', Id: 4 }, { Name: 'Eğitim', Id: 5 }, { Name: 'İnformasyon', Id: 6 }, { Name: 'Ekspertiz', Id: 7 }, { Name: 'Revizyon', Id: 8 }, { Name: 'Çalışma Saati', Id: 9 }],
        categorySelect: [],

        
        // Filter
        filter: { Skip: 0, PageSize: 20 },

        filterVisible: false,
      };
    },
    components: {
      productheader
    },
    created() {
      this.shippingId = this.$route.query.shippingId
      this.productSelect = this.$route.query.productSelect

      this.getItems()
      this.getCategoryList()
    },
    methods: {

        // Filter
      filterDialog() {
        this.filterVisible = true
      },

      filterClose() {
        this.filterVisible = false
      },

      filterSubmit() {
        this.getItems()
        this.filterClose()
      },


        // Kategori Listesi
        getCategoryList() {
            this.$axios.get("common/getallcategories").then(resp => {
                this.categoryList = resp.data.List
            });
        },

        // Ürün Ekle
        addProductAction() {
          this.addProductModal = true;
        },
        addProductModalClose() {
          this.addProductModal = false;
        },
        // Ürün Ekle
        
        onSearch(value) {
            console.log(value);
            this.filter.SerialNo = value
            this.getItems()
        },
        goDetail(item) {    
    
            if(this.productSelect == false) {
              if (this.shippingId > 0) {
                  var url = "/shipping/detail/" + this.shippingId + "?productId=" + item.Id
                  this.$router.push(url)
              } 
            } else {
               var productItem = [{ key: item.Id, label: item.DisplayName, LocationId: item.LocationId, IsEquipment: item.IsEquipment, }]
               this.$emit('parentgetproduct', productItem)
               this.$emit('parentmodalclose')
               //this.productSelectModal = false
            }
        },
        modalClose() {
          this.$emit('parentmodalclose')
        },
        getItems() {
            this.$axios.get("/product/GetProductsByFilter?", { params: this.filter }).then(resp => {
                if (resp.data.Count > 0) {
                    //  if(this.filter.SerialNo) {
                    //     this.$message
                    //     .loading('Lütfen bekleyin..', 2.5)
                    //     .then(() => this.$message.success(this.filter.SerialNo + " " + 'aramanızla ilişkili ürünler yüklendi.', 2.5))
                    // }
                    
                    this.dataquery = resp.data.List
                }
            }).catch((error) => { console.log(error) })
        },

        // Form
        handleSubmit(e) {

            e.preventDefault();
            this.form.validateFields((err, values) => {
              console.log(values)
            });

        },

    },
  };
</script>



<style>
    .addProductModal .ant-drawer-content-wrapper {
      width: 100% !important;
    }

    .addProductModal  .ant-drawer-body {
      padding: 0px 0 !important;
    }

    .addProductModal {
          z-index: 99999 !important;
    }

</style>