<template>
  <div>

    <div class="mheader" style="height: 65px; overflow: hidden;">
      <div class="row">
          <div class="col-xs-2">
              <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
                <path id="Menu" d="M-6160,12V10.285h8.751V12Zm0-5.143V5.143h14V6.857Zm0-5.143V0h14V1.715Z" transform="translate(6160)" fill="#fff"/>
              </svg>
          </div>
          <div class="col-xs-8">
              <span class="title">
                HASEL CRM
              </span>
          </div>
      </div>
    </div>
    <div class="content shippingList ProductListPage" style="padding-top: 65px;">

      <!-- <span @click="getLocation">konum al</span>

        <div v-if="location">
            Konum: {{ location.coords.latitude }}, {{ location.coords.longitude}}
        </div> -->

         <gmap-map :center="center" :zoom="14" style="width: 100%; min-height: 500px; height: 100%;" @dblclick="onMapClick($event)">
            <gmap-info-window :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                <!-- :options="infoOptions"  -->
                <div class="infoWindow" style="width: 300px;" v-if="selectedLocationData !== null">
                    <div>
                        aaa
                    </div>
                </div>
            </gmap-info-window>
            <gmap-marker :key="i" v-for="(m,i) in markers" :clickable="true"   :position="getPosition(m)" :icon="m.icon" @click="toggleInfoWindow(m,i)"></gmap-marker>

            <gmap-marker v-if="this.location" :position="{lat: this.location.coords.latitude, lng: this.location.coords.longitude}"  :icon="iconReresized"></gmap-marker>
            
            <gmap-marker :key="s" v-for="(m,s) in newLocations" @drag="newDataCoordinates" :draggable="true" :position="getPosition(m)" :icon="m.icon" :clickable="true" @click="toggleInfoWindow(m,s)"></gmap-marker>
            
        </gmap-map>
        

     </div>

  </div>
</template>


<script>

  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        moment,
        count: {},
        shippingId : 0,

        tester: null,
        
         // Harita 
        mapVisible: true,

        location:null,
        gettingLocation: false,
        errorStr:null,

        center: {},
        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,

        infoOptions: {
          content: '',
        },

        markers:[{ Location : {
          Latitude: 40.908,
          Longitude : 29.38
        }}],

        selectedLocationData: null,
        iconReresized: {
            url: require('../assets/map-mypin.gif'),
            size: { width: 100, height: 100, f: "px", b: "px" },
            scaledSize: { width: 50, height: 50, f: "px", b: "px" }
        },

        newLocations: [],

        locationSelectModal: false,

        companyNoData : [],
        locationSelectData: {},
        locationsList: [],
        selectChangeData: [],
        // Harita 

      };
    },
    created() {
        //this.getLocation()
        
    },
    mounted () {
       this.getLocation()
    },
    watch: {
        location() {
            this.center = {
                lat: this.location.coords.latitude,
                lng: this.location.coords.longitude
            }
        },

        center(val) {
          console.log(val)
          //console.log(val)
        }

    },
    methods: {
        // Harita

        // Yeni Konum
        onMapClick(event) {
            
            if(this.newLocations.length == 0) {
                var newLat = event.latLng.lat()
                var newLng = event.latLng.lng()

                this.newLocations.push({ 
                    Location:  {
                       Latitude: newLat , Longitude: newLng 
                    },
                    icon : {
                        url: require('../assets/map-pin.svg'),
                        size: { width: 40, height: 40, f: "px", b: "px" },
                        scaledSize: { width: 40, height: 40, f: "px", b: "px" }
                    },
                });

                console.log( this.newLocations)
            } else {
                console.log("lokasyon ekleme işlemi yapılamaz.")
            }

        },

        newDataCoordinates(location) {
            this.newLocations = []
            this.infoWinOpen = false

            this.newLocations.push({
                Location:  {
                    Latitude: location.latLng.lat() , Longitude: location.latLng.lng()
                },
                icon : {
                    url: require('../assets/map-pin.svg'),
                    size: { width: 40, height: 40, f: "px", b: "px" },
                    scaledSize: { width: 40, height: 40, f: "px", b: "px" }
                },
            });
            //console.log(this.newLocations)
        },

        getPosition: function(marker) {
          //console.log(marker)
          return {
            lat: Number(marker.Location.Latitude),
            lng: Number(marker.Location.Longitude),
          }
        },
        
        toggleInfoWindow: function(marker, idx) {
            this.infoWindowPos = {
                lat: Number(marker.Location.Latitude),
                lng: Number(marker.Location.Longitude)
            },

            this.infoOptions.content =  `<a v-on:click.stop="openDialog(${marker.CustomerName})"> </a>` +  marker.CustomerName;
            this.selectedLocationData = marker
            console.log(this.selectedLocationData)

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }

            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
        
        getLocation() {
            //do we support geolocation
            if(!("geolocation" in navigator)) {
              this.errorStr = 'Geolocation is not available.';
              return;
            }

            this.gettingLocation = true;
            
            // get position
            navigator.geolocation.watchPosition(pos => {
                this.gettingLocation = false;
                this.location = pos;
            }, err => {
                this.gettingLocation = false;
                this.errorStr = err.message;
            })
        },

        // Harita

    },
  };
</script>

<style></style>