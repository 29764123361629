<template>
  <div>
    <menus ref="openMenu"></menus>
    <div class="mheader" style="height: 63px; overflow: hidden;">
      <div class="row">
          <div class="col-xs-2">
              <svg @click="prevGo" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                    <g id="back" transform="translate(-1)">
                        <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                    </g>
              </svg>
          </div>
          <div class="col-xs-8">
              <span class="title">
                 {{ this.pageTitle }}
              </span>
          </div>
          <div class="col-xs-2 text-right formBtn" v-if="disableFormButton">
                <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                    <a-form-item :wrapper-col="{ span: 12 }">
                        <a-button type="primary" html-type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                            </svg>
                        </a-button>
                    </a-form-item>
                </a-form>
          </div>
      </div>
    </div>

    <div class="content" style="padding-top: 63px;">
        <div class="formStyle">
            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">

                <div class="pageHeader">
                    <h3>Talep Bilgileri</h3>
                </div>

                <a-form-item label="Servis Türü">
                    <a-select
                        label-in-value
                        v-decorator="['ProductTypeId', { initialValue: productTypeListData[0], rules: [{ required: true, message: 'Operasyon alanı zorunludur!' }] }]"
                        :disabled="disableProductType"
                        placeholder="Servis türü seçiniz"
                        notFoundContent= "Servis türü seçiniz">
                        <a-select-option v-for="item in productTypeList" :key="item.Id" :value="item.Id">
                            {{ item.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <div @click="productSelectModalClick">
                     <a-form-item label="Ürün seçiniz">
                        <a-select
                            label-in-value
                            :filter-option="false"
                            placeholder = "Ürün seçiniz"
                            notFoundContent= ""
                            :disabled="productDisable"
                            v-decorator="['ProductId', { initialValue: productlistData[0], rules: [{ required: true, message: 'Firma seçimi alanı zorunludur!' }] }]">
                            <a-select-option v-for="item in productlist" :key="item.key">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </div>

                <a-form-item label="Operasyon">
                    <a-select
                        label-in-value
                        v-decorator="['OperationId', { initialValue: operationSelect[0], rules: [{ required: true, message: 'Operasyon alanı zorunludur!' }] }]"
                        :disabled="(OperationDisable) ? true : false"
                        placeholder="Operasyon seçiniz"
                        notFoundContent= "Lütfen operasyon seçiniz">
                        <a-select-option v-for="item in operations" :key="item.Id" :value="item.Id">
                            {{ item.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

               <a-form-item label="Bölge">
                    <a-select
                        label-in-value
                        v-decorator="['AreaId', { initialValue: areasListSelect[0], rules: [{ required: true, message: 'Bölge alanı zorunludur!' }] }]"
                        :disabled="disableArea"
                        placeholder="Bölge seçiniz"
                        notFoundContent= "Lütfen bölge seçiniz">
                        <a-select-option v-for="item in areasList" :key="item.Id" :value="item.Id">
                            {{ item.AreaName }}
                        </a-select-option>
                    </a-select>
                </a-form-item>


                <a-form-item label="Firma Adı">
                     <a-select
                        mode="default"
                        placeholder="Firma arayınız"
                        :showSearch="true"
                        label-in-value
                        :filter-option="false"
                        :notFoundContent= "notFoundContent"
                        v-decorator="['CompanyId', { initialValue: companyDataSelect[0], rules: [{ required: true, message: 'Firma alanı zorunludur!' }] }]"
                        @inputKeydown="searchNoContent($event)"
                        @change="companySearchChange($event)">
                        <a-icon slot="suffixIcon" type="search" />
                        <a-select-option v-for="c1 in companyData" :key="c1.Id">
                            {{ c1.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                

                <div v-if="this.disableGroupSelect == false">
                    <a-form-item label="Grup Seçiniz"> 
                            <a-select
                                label-in-value
                                v-decorator="['ServiceGroupId', { initialValue: UserGroupSelect[0], rules: [{ required: true, message: 'Grup seçim alanı zorunludur!' }] }]"
                                placeholder="Grup seçiniz"
                                notFoundContent= "Grup seçiniz">
                                <a-select-option v-for="item in userGroupList" :key="item.Id" :value="item.GroupId">
                                    {{ item.GroupName }}
                                </a-select-option>
                            </a-select>
                    </a-form-item>
                </div>

                <div>
                    <a-form-item label="Şef Seçiniz"> 
                            <a-select
                                label-in-value
                                v-decorator="['ChiefId', { initialValue: chiefSelect[0],  rules: [{ required: true, message: 'Şef seçim alanı zorunludur!' }] }]"
                                placeholder="Şef seçiniz"
                                notFoundContent= "Şef seçiniz">
                                <a-select-option v-for="item in chiefs" :key="(item.UserId != undefined  && item.UserId >  0 ? item.UserId : item.Id )" :value="(item.UserId != undefined && item.UserId > 0 ? item.UserId : item.Id )">
                                    {{ (item.UserName != undefined || item.UserName != null ? item.UserName : item.Name ) }}
                                </a-select-option>
                            </a-select>
                    </a-form-item>
                </div>
                           
                <a-form-item label="Talep Türü">
                    <a-select
                        label-in-value
                        v-decorator="['RequestType', { initialValue:  RequestTypeListSelect[0], rules: [{ required: true, message: 'Talep türü alanı zorunludur!' }] }]"
                        :disabled="disableRequestType"
                        placeholder="Talep Türü"
                        notFoundContent= "Talep türü seçiniz"
                        @change="RequestTypeChange($event)">
                        <a-select-option v-for="item in RequestTypeList" :key="item.Id" :value="item.Id">
                            {{ item.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                

                <!-- <div style="display:none;">
                    <a-form-item label="Faturalandırma">
                        <a-select
                            label-in-value
                            :disabled="InvoiceDisable"
                            v-decorator="['InvoiceType', { initialValue: InvoiceTypeListSelect[0], rules: [{ required: true, message: 'Faturalandırma alanı zorunludur!' }] }]"
                            placeholder="Faturalandırma seçiniz"
                            notFoundContent= "Faturalandırma seçiniz">
                            <a-select-option v-for="item in InvoiceTypeList" :key="item.Id" :value="item.Id">
                                {{ item.Name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </div> -->


                <div v-if="this.dataForm.RequestType == 1 || this.dataForm.RequestType == 12 || this.dataForm.RequestType == 13 || this.dataForm.RequestType == 15">
                    <a-form-item label="Tahmini Bitiş Tarihi">
                        <a-date-picker 
                        placeholder="Tahmini Bitiş Tarihi" 
                        :defaultValue="moment(FaultEndDate)" :format="dateFormat"
                        v-decorator="['FaultEndDate', { initialValue: FaultEndDate, rules:  [{ required: true, message: 'Tahmini bitiş tarihi zorunludur!' }]}]" 
                        @change="dateChange" />
                    </a-form-item>
                </div>

                <a-form-item label="Bakım Paketi Seçiniz" v-if="this.dataForm.RequestType == 2"> 
                    <a-select
                        label-in-value
                        :disabled="disabledMaintenance"
                        v-decorator="['MaintenanceTypeId', { initialValue: serviceMaintenanceListSelect[0], rules: [{ required: this.disabledMaintenanceRequest, message: 'Talep türü alanı zorunludur!' }] }]"
                        placeholder="Bakım paketi seçiniz"
                        notFoundContent= "Bakım paketi seçiniz"
                        @change="serviceMaintenanceChange($event)">
                        <a-select-option v-for="item in serviceMaintenanceData" :key="item.Id" :value="item.Id">
                            {{ item.MaintenanceName }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                
                <a-form-item label="Satış Garantili">
                    <a-checkbox v-model="dataForm.IsWarranty">
                        {{ dataForm.IsWarranty == false ? 'Hayır' : 'Evet' }}
                    </a-checkbox>
                </a-form-item>

                <a-form-item label="Şikayet">
                      <a-textarea v-decorator="['Problem', { initialValue: dataForm.Problem }, ]" placeholder="Şikayet" :rows="6" />
                </a-form-item>
             
                <div class="pageHeader" v-if="this.editPage == false">
                    <h3>Randevu Bilgileri</h3>
                </div>

                
                <a-form-item label="Lokasyon" v-if="this.editPage == false">
                    <a-select
                        label-in-value
                        v-decorator="['CompanyLocationId', { initialValue: locationsList[0], rules: [{ required: true, message: 'Lokasyon alanı zorunludur!' }] }]"
                        :disabled="RentalProductDisable"
                        placeholder="Lokasyon seçiniz"
                        notFoundContent= "Lütfen firma seçiniz"
                        @change="locationChange($event)">
                        <a-select-option v-for="item in locationsList" :key="item.Id" :value="item.Id">
                            {{ item.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

               <div v-if="this.editPage == false">
                    <a-form-item label="Randevu Tarihi">
                        <a-date-picker 
                        placeholder="Randevu tarihi" 
                        :defaultValue="moment(appointmentDate)"  :format="dateFormat"
                        v-decorator="['appointmentDate', { initialValue: appointmentDate, rules:  [{ required: true, message: 'Randevu tarihi zorunludur!' }]}]" 
                        @change="dateChange" />
                    </a-form-item>
                </div>

                <div v-if="this.editPage == false">
                    <a-form-item label="Randevu Saati">
                        <a-select
                        label-in-value
                        v-decorator="['toHours', {  rules: [{ required: true, message: 'Randevu saati zorunludur!' }] }]"
                        placeholder="Saat"
                        @change="fromHourChange($event)">
                            <a-select-option v-for="item in hourList" :key="item" :value="item">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </div>

                <div v-if="this.editPage == false">
                        <a-form-item label="Teknisyen Seçiniz"> 
                            <a-select
                                label-in-value
                                :disabled="disableTechnician"
                                v-decorator="['TechnicianId', { initialValue: TechnicianListSelect[0], rules: [{ required: true, message: 'Teknisyen seçim alanı zorunludur!' }] }]"
                                placeholder="Teknisyen seçiniz"
                                notFoundContent= "Teknisyen seçiniz"
                                @change="technicianChange($event)">
                                <a-select-option v-for="item in TechnicianList" :key="item.Id" :value="item.Id">
                                    {{ item.Name }}
                                </a-select-option>
                            </a-select>
                    </a-form-item>
                </div>

                <div class="clearfix"></div>
                
            </a-form>
        </div>
    </div>

    <a-modal
        :visible="warningModal"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        okText="Evet"
        cancelText="İptal">
        <p>{{ this.warningText }}</p>
    </a-modal>

    <a-drawer
    placement="right"
    :closable="false"
    :visible="productSelectModal"
    wrapClassName = "productSelectModal"
    @close="productModalClose">
    
        <productlist :modaldata="modalData" @parentmodalclose="productModalClose" @parentgetitem="selectItem=$event"></productlist>

    </a-drawer>


  </div>
</template>

<script>
  import moment from "moment";
  import productlist from '../general/generalList';
  import Menus from '../Menu';
  export default {
    name: "App",
    data() {
      return {
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'coordinated' }),

        disableFormButton: true,
        disableGroupSelect : false,

        // Company
        companyData: [],
        companyDataSelect: [],
        companyId : '',
        locationsList: [],
        productlist: [],
        operations: [],
        AppointmentCompanId: null,

        ProductId: null,

        // Disable
        disableProductType: true,
        RentalProductDisable:true,
        productDisable:false,

        // Select
        operationSelect: [],
        productTypeListData: [],
        RequestTypeListSelect : [],
        ResultTypeListSelect : [],
        productlistData: [],
        RequestDate : null,
        chiefs: [],
        userGroupList:[],

        // Data 
        editedItem: {},
        dataForm: {},
        CompanyId: null,

        // Form
        createId: null,

        // Hours
        toHourList: [],
        hourList: ['00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45', '03:00', '03:15', '03:30', '03:45', '04:00', '04:15', '04:30', '04:45', '05:00', '05:15', '05:30', '05:45', '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45', '08:00', '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '15:45', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45'],


        // Disable Setting
        disableCustom: false,
        OperationDisable:false,

        // Edit
        editId: 0,
        editPage : false,
        pageTitle : "Talep Ekle",

        
        RequestTypeList: [{ Name: 'Arıza', Id: 1 }, { Name: 'Periyodik Bakım', Id: 2 }, { Name: 'Hasar', Id: 15 }, { Name: 'Teslimat Kontrol', Id: 3 }, { Name: 'Yenileme', Id: 11 }, { Name: '2.El Satış Makine Hazırlık ', Id: 12 }, { Name: 'Refurbishment', Id: 13 }],
        RequestTypeDataList: [{ Name: 'Arıza', Id: 1 }, { Name: 'Periyodik Bakım', Id: 2 }, { Name: 'Hasar', Id: 15 }, { Name: 'Teslimat Kontrol', Id: 3 }, { Name: 'Yenileme', Id: 11 }, { Name: '2.El Satış Makine Hazırlık ', Id: 12 }, { Name: 'Refurbishment', Id: 13 }],
       
        ResultTypeList: [{ Name: 'Talep Alındı', Id: 1 }, { Name: 'Teklif Verildi', Id: 2 }, { Name: 'Parça Bekliyor', Id: 3 }, { Name: 'Servis Verildi', Id: 4 }, { Name: 'Faturası Kesildi', Id: 5 }, { Name: 'Talep İptal', Id: 6 }],

        productTypeList:  [{ Name: 'Kiralama', Id: 1 }, { Name: 'Müşteri', Id: 2 }],

        // Faturalandırma
        InvoiceTypeListSelect: [],
        InvoiceTypeList:  [{ Name: 'Müşteri', Id: 2 }, { Name: 'Kiralama', Id: 1 }, { Name: 'Satış Garanti', Id: 3 } ],

        productSelectControl: false,
        productSelectModal : false,

        productSelectItem : {},

        notFoundContent: "",

        appointmentDate: new Date().toLocaleDateString('en-US'),
        dateFormat: 'DD/MM/YYYY',

        // General Modal
        modalData: {},
        selectItem : [],
        companySelectModal : false,
        companyselect: [],

        ServiceEntityId: null,

        // Bakım
        serviceMaintenanceData: [],
        disabledMaintenance: true,
        serviceMaintenanceListSelect: [],
        disabledMaintenanceRequest: false,

        // Bölge
        areasList: [],
        areasListSelect : [],
        disableArea: false,

        // Teknisyen
        TechnicianId: null,
        TechnicianList: [],
        TechnicianListSelect: [],
        RoleList: localStorage.getItem('RoleList'),
        userId: localStorage.getItem('userId'),
        getFormData: {},
        disableTechnician : false,
        getchiefFilter:{},

        UserGroupSelect: [],
        chiefSelect:[],

        chiefDisable:true,

        serviceStatus: false,
        serviceStatusData: null,
        warningModal: false,
        warningText:null,
        confirmLoading: false,
        FormDataPost: null,
        LastWorkTimeValue:null,
        LastMaintenanceWorkTimeValue:null,

        InvoiceDisable:true,

        disableRequestType: false,

        // Bakım geçmemişse çalışma saati istenir
        currentWorkTimeVisible:false,

        IsEquipment:false,

        MaintenanceStatus: null,
        FaultStatus: null,

        FaultEndDate: new Date().toLocaleDateString('en-US'),
      };
    },
    components: {
      Menus,
      productlist
    },
    created() {
        this.getAreaList()

        //this.getShippingDetail()
        this.productSelectControl = this.$route.query.productSelect

        this.editId = this.$route.params.id

        if(this.editId > 1) {
           this.editPage  = true
           this.pageTitle = "Servis Talebi Düzenle #" + this.editId
           this.productDisable = true
           this.getProductById()
        } 

        // Kiralama aktifse
        this.productTypeListData = [{ key: 1, label: "Kiralama", name: "Kiralama" }]

        this.getOperationList()

        this.modalData = { PageTitle: "Ürün Seçimi", pageControl: "1", control: 3, Prev: this.$route.path, apiUrl: "rentalproducts/RentalServiceProducts"}

        if(this.RoleList.search('Servis Teknisyeni') > -1) {
           this.getFormData.TechnicianName = localStorage.getItem('Name')
           this.getFormData.Id = localStorage.getItem('userId')
           this.TechnicianId = localStorage.getItem('userId')

           this.TechnicianListSelect = [{ key: this.TechnicianId, label:  this.getFormData.TechnicianName, name:  this.getFormData.TechnicianName }]
           this.disableTechnician = true
       }

       this.getTechnicians()
       
       if(this.editPage == false) {
        this.getGroupList()
       }

    },
     watch: {
        productSelectItem() {
            //console.log(this.productSelectItem)
            this.productlist = this.productSelectItem
            //console.log(this.productlist)
        },

            // General List
        selectItem() {
            this.productlistData = this.selectItem

            this.FaultStatus = this.productlistData[0].item.FaultStatus // Arıza durum
            this.MaintenanceStatus = this.productlistData[0].item.MaintenanceStatus // Bakım durum
            
            this.LastWorkTimeValue = this.productlistData[0].item.LastWorkTimeValue
            this.LastMaintenanceWorkTimeValue = this.productlistData[0].item.LastMaintenanceWorkTimeValue

            this.IsEquipment = this.productlistData[0].item.IsEquipment

            this.companyDataSelect = [{ key: this.productlistData[0].item.CompanyId, label: this.productlistData[0].item.CompanyName }]

            this.AppointmentCompanId = this.productlistData[0].item.CompanyId
            this.locationsList = [{ key: this.productlistData[0].item.LocationId, label: this.productlistData[0].item.Location.CityName + " /" + this.productlistData[0].item.Location.RegionName}]

            //console.log(this.productlistData)

            this.ProductId = this.productlistData[0].item.ProductId

            // Bakım otomatik seçme
            if((this.LastWorkTimeValue - this.LastMaintenanceWorkTimeValue) > 450 ) {
                this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error('Bu ürünün periyodik bakımı geldiğinden talep periyodik bakım olarak açılacaktır. ', 2.5))

                this.form.setFields({
                    RequestType: [{ key: 2, label: "Periyodik Bakım" }]
                });
                this.dataForm.RequestType = 2
                this.RequestTypeListSelect = [{ key: 2, label: "Periyodik Bakım", name: "Periyodik Bakım" }]
                this.disableRequestType = true
            } else {
                delete this.RequestTypeListSelect[0]
                this.form.setFields({
                    RequestType: [{ key: null, label: null }]
                });
                this.disableRequestType = false
            }
            // Bakım otomatik seçme


            //console.log(" ürün 5" + this.ProductId)

            // Bakım uyarısı
            if (this.MaintenanceStatus === "1-Ürünün son bakım tarihi geçmiş.") {
                this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error('Bu ürünün periyodik bakımı geldiğinden talep periyodik bakım olarak açılacaktır.', 2.5))
                            
                // if(this.IsEquipment == false) {
                //     this.currentWorkTimeVisible = true
                // }
                this.form.setFields({
                    RequestType: [{ key: 2, label: "Periyodik Bakım" }]
                });

                this.dataForm.RequestType = 2

                this.RequestTypeListSelect = [{ key: 2, label: "Periyodik Bakım", name: "Periyodik Bakım" }]
                this.disableRequestType = true
            }
            
            if(this.productlistData[0].item.OperationId != null) {
               this.operationSelect = [{ key: this.productlistData[0].item.OperationId, label: this.productlistData[0].item.OperationName}]
               this.OperationDisable = true
            } else {
                this.operationSelect = []
                this.OperationDisable = false
            }

            // if(this.productlistData[0].item.AreaId != null) {
            //     this.areasListSelect = [{ key: this.productlistData[0].item.AreaId, label:  this.productlistData[0].item.AreaName, name:  this.productlistData[0].item.AreaName }]
            //     this.disableArea = true
            // }

            if(this.dataForm.RequestType == 2) {
                this.serviceMaintenanceList()
            } 


            this.shippingCompanies()

            //console.log(this.companyData)

        },

        CompanyId() {
            //console.log(this.CompanyId)
            if (this.CompanyId == 1 || this.CompanyId == 151) {
                this.RequestTypeList = this.RequestTypeList.filter(x => x.Id != 15)
                console.log(this.RequestTypeList)
            }

            if (this.CompanyId != 1) {

                this.RequestTypeList = this.RequestTypeDataList
            }

            if (this.dataForm.RequestType == 15 && this.CompanyId== 1) {

                this.form.setFieldsValue({
                    RequestType: [],
                });
                  
            }
        }
        
    },
    methods: {
         moment,


        // Sevk ara
        async shippingCompanies() {
            console.log("Ürün id = " + this.ProductId)
            

            this.loadingDialog = true
            await this.$axios.get("/Shippings/GetShippingList?Skip=0&PageSize=100&ProductId=" + this.ProductId ).then(resp => {
                if (resp.data.IsSuccessful == true) {

                    this.companyData = resp.data.List
                    this.companyData.map((data, i) => {
                        this.companyData[i].Name = data.ToCompanyName
                        this.companyData[i].Id = data.ToCompanyId
                    })

                    this.companyData = this.companyData.reduce((acc, current) => {
                        const x = acc.find(item => item.Id === current.Id);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);

                    this.form.setFields({
                        CompanyId: [{ key: this.productlistData[0].item.CompanyId, label: this.productlistData[0].item.CompanyName }]
                    });

                    this.CompanyId = this.productlistData[0].item.CompanyId

                    this.companyDataSelect = [{ key: this.productlistData[0].item.CompanyId, label: this.productlistData[0].item.CompanyName }]


                } 
            });
            
        },



        // Şef
        async getGroupList() {
           var groupFilter = { Skip: 0, PageSize: 10 }
           groupFilter.UserId =  this.userId

           if(this.editPage && this.editedItem.Appointment != null) {
                groupFilter.UserId =  this.editedItem.Appointment.TechnicianId
           }

           this.$axios.get("/ServiceGroup/GetGroupUserList", { params: groupFilter }).then(response => {
                this.userGroupList = response.data.List
                if(response.data.Count > 0) {
                    this.chiefDisable = false
                    this.UserGroupSelect = [{ key: this.userGroupList[0].GroupId, label: this.userGroupList[0].GroupName }]
                    this.disableGroupSelect = false
                } else {
                    this.disableGroupSelect = true
                }

                this.getChiefList()

                // this.chiefs = this.userGroupList.filter(function (chieffilter) {
                //     return chieffilter.UserRoleNames.search('Şef') > -1;
                // });
                
            });
        },

        
        async getChiefList() {

            if(this.userGroupList.length > 0 ) {
                var ChiefFilter = { Skip: 0, PageSize: 100, GroupId: this.userGroupList[0].GroupId  }

                await this.$axios.get("/ServiceGroup/GetGroupUserList", { params: ChiefFilter }).then(response => {
                    this.chiefs = response.data.List

                    this.chiefs = this.chiefs.filter(function (chieffilter) {
                        return chieffilter.UserRoleNames.search('Şef') > -1;
                    });

                });
                
            } else {

                await this.$axios.get("/user/GetChiefs").then(response => {
                    this.chiefs = response.data.List
                    // if (this.chiefs.length == 1 && this.editPage )
                    //     this.newAppointmentData.ChiefName = { Id: this.chiefs[0].Id, Name: this.chiefs[0].Name }
                });    
            }
            
            // if(this.editId > 1) {
            //     if(this.RoleList.search('Şef') > -1) {
            //         this.getchiefFilter = { technicianId: this.editedItem.Appointment.TechnicianId }
            //     }
            // }
            // await this.$axios.get("/user/GetChiefs", { params: this.getchiefFilter }).then(response => {
            //     this.chiefs = response.data.List
            //     if (this.chiefs.length == 1 && this.editPage )
            //         this.newAppointmentData.ChiefName = { Id: this.chiefs[0].Id, Name: this.chiefs[0].Name }
            // });              
        },

        // Teknisyen
        technicianChange(event) {
            //console.log(event)
            this.TechnicianId = event.key
            //this.newAppointmentData.TechnicianName = event.label
        },

        async getTechnicians() {
            await this.$axios.get("/user/GetTechnicians").then(response => {
                if (response.data.IsSuccessful)
                    this.TechnicianList = response.data.List
                else {
                    this.$message.error('Teknisyeneler çekilirken hata oluştu.');
                }
            });
        },

        searchNoContent(event) {
            if(event.key > 3) {
                this.notFoundContent = "Kayıt bulunamadı"
            }
        },

        openMenu () {
          this.$refs.openMenu.showDrawer()
        },

        prevGo () {
            //this.$router.go(-1)
            if(this.editedItem.Id > 1) {
                var prevGo = "/service/" + this.editedItem.Id
                this.$router.push(prevGo)
            } else {
                this.$router.go(-1)
            }
            
        },
        
        productSelectModalClick() {
            if(this.productDisable == false) {
                this.productSelectModal = true
                this.$root.productSelectModal = true
            }
        },

        productModalClose() {
            this.productSelectModal = false;
        },

        // Shipping Detail
        getProductById() {
            this.$axios.get("/ServiceRequests/Get/" +  this.editId).then(resp => {
                this.editedItem = resp.data.Entity
                this.dataForm =  this.editedItem

                this.companyDataSelect = [{ key: this.dataForm.CompanyId, label: this.dataForm.CompanyName }]
                this.locationsList = [{ key: this.dataForm.LocationId, label: this.dataForm.LocationName }]
                this.productlistData= [{ key: this.dataForm.ProductId, label: this.dataForm.ProductDisplayName + " / " + this.dataForm.ProductSerialNo, name:this.dataForm.ProductDisplayName + "/" + this.dataForm.ProductSerialNo }]
                this.operationSelect = [{ key: this.dataForm.OperationId, label: this.dataForm.SerialNo, name:this.dataForm.OperationName }]
                this.RequestTypeListSelect = [{ key: this.dataForm.RequestType, label: this.dataForm.SerialNo, name:this.dataForm.RequestTypeName }]
                this.ResultTypeListSelect = [{ key: this.dataForm.ResultType, label: this.dataForm.SerialNo, name:this.dataForm.ResultTypeName }]
                
                this.chiefSelect = [{ key: this.dataForm.ChiefId, label: this.dataForm.ChiefName, name: this.dataForm.ChiefName  }]
                this.UserGroupSelect = [{ key: this.dataForm.ServiceGroupId, label: this.dataForm.ServiceGroupName }]

                if(this.dataForm.Appointment != null) {
                    this.appointmentDate = this.dataForm.Appointment.AppointmentDate
                    this.toHourList = [{ key: this.dataForm.Appointment.AppointmentHour, label:  this.dataForm.Appointment.AppointmentHour, name:  this.dataForm.Appointment.AppointmentHour }]
                }

                this.InvoiceTypeListSelect = [{ key: this.dataForm.InvoiceType, label: this.dataForm.InvoiceTypeName, name:this.dataForm.InvoiceTypeName }]

                if(this.dataForm.AreaId != null) {
                    this.areasListSelect = [{ key: this.dataForm.AreaId, label:  this.dataForm.AreaName, name:  this.dataForm.AreaName }]
                    this.disableArea = true
                }

                if(this.editedItem.MaintenanceTypeId != null) {
                   this.serviceMaintenanceListSelect = [{ key: this.editedItem.MaintenanceTypeId, label:  this.editedItem.MaintenanceTypeName, name:  this.editedItem.MaintenanceTypeName }]
                   this.serviceMaintenanceList()
                }
                
                if(this.editedItem.Appointment != null) {
                    this.disableCustom = true
                    this.disabledMaintenance = true
                }
 
                if(this.editedItem.ResultType >= 8 ) {
                    this.disableCustom = true
                    this.disabledMaintenance = true
                }

                if(this.editedItem.RequestType == 2 && this.editedItem.MaintenanceTypeId == null) {
                    this.disabledMaintenance = false
                    if (this.serviceMaintenanceData.length > 0) {
                        this.disabledMaintenanceRequest = true
                    }
                }

                if(this.editedItem.OperationId == null) {
                    this.OperationDisable = false
                }

                //this.getChiefList()

                 if(this.editPage == true) {
                    this.getGroupList()
                }

                this.FaultEndDate =  (this.dataForm.FaultEndDate != null) ? moment(new Date(this.dataForm.FaultEndDate).toLocaleString('en-US')) : null
                
            });
        },

        // Servis Türleri
        async GetServiceRequestTypeList() {
            await this.$axios.get("/area/GetAuthorizedAreaList").then(response => {
                this.areasList = response.data.List
                if (this.areasList.length == 1) {
                    this.areasListSelect = [{ key: this.areasList[0].Id, label:  this.areasList[0].AreaName, name:  this.areasList[0].AreaName }]
                    this.disableArea = true
                }
            });
        },

        // Bölge
        async getAreaList() {
            await this.$axios.get("/area/GetAuthorizedAreaList").then(response => {
                this.areasList = response.data.List
                if (this.areasList.length == 1) {
                    this.areasListSelect = [{ key: this.areasList[0].Id, label:  this.areasList[0].AreaName, name:  this.areasList[0].AreaName }]
                    this.disableArea = true
                }
            });
        },

        // From Compamny
        companySearchChange(event) {
            this.dataForm.companyId = event.key

            this.CompanyId = event.key
            //console.log(event)

            if(this.editPage == false) {
                this.FindInterviewForOpenRequest()
            } 

            //this.getcompanyLocation()
        },

        
        locationChange(event) {
            this.dataForm.locationId = event
            //console.log(event)
        },

        RequestTypeChange(event) {
            this.dataForm.RequestType = event.key

            if(event.key == 1) {
                if(this.FaultStatus === "1-Ürüne ait arıza kaydı var.") {
                    this.warningText = "Bu ürüne ait açılmış servis talebi vardır. Bu talebe yönlendirileceksiniz."
                    this.serviceStatus = true
                }
            } 

            if(event.key != 1) this.serviceStatus = false
            
            this.FaultEndDate = moment(new Date().toLocaleString('en-US'))

            if(event.key == 2) {
                // if (this.MaintenanceStatus === "2-Bakım tarihi geçmemiş." && this.IsEquipment == false) {
                //     this.currentWorkTimeVisible = true
                // }
                this.serviceMaintenanceList()
            } else {
                this.disabledMaintenance = true
                this.disabledMaintenanceRequest = false
                this.serviceMaintenanceListSelect = []
            }

            //if(event.key != 2) this.currentWorkTimeVisible = false

            // if (this.dataForm.RequestType == 15) {
            //     this.InvoiceTypeListSelect = [{ key: 2, label: "Müşteri", name: "Müşteri", Name : "Müşteri"  }]

            //      this.form.setFields({
            //         InvoiceType: [{ key: 2, label: "Müşteri" }]
            //     });
                
            //     this.InvoiceDisable = true
            // }

            // if (this.dataForm.RequestType != 15) {
            //     this.InvoiceTypeListSelect = [{ key: 1, label: "Kiralama", name: "Kiralama" }]
            //       this.form.setFields({
            //         InvoiceType: [{ key: 1, label: "Kiralama", Name : "Kiralama" }]
            //     });
            //     this.InvoiceDisable = true
            // }

        },

        serviceMaintenanceChange(event) {
            console.log(event)
        },

        serviceWorkTimeChange(event) {
            console.log(event)
            // if((event - this.LastWorkTimeValue) > 450 ) {
            //    this.$message
            //                 .loading('Lütfen bekleyin..', 2.5)
            //                 .then(() => this.$message.error('Bu ürünün bakım tarihi geçdiği için başka talep türü açılamaz!', 2.5))

            //     this.form.setFields({
            //         RequestType: [{ key: 2, label: "Periyodik Bakım" }]
            //     });
            //     this.RequestTypeListSelect = [{ key: 2, label: "Periyodik Bakım", name: "Periyodik Bakım" }]
            //     this.disableRequestType = true
            // } else {
            //     delete this.RequestTypeListSelect[0]
            //     this.form.setFields({
            //         RequestType: [{ key: null, label: null }]
            //     });
            //     this.disableRequestType = false
            // }

        },

        dateChange(date, dateString) {
            console.log(date, dateString);
        },

        fromHourChange(event) {
            this.dataForm.fromHour = event
            console.log(event)
        },

        async serviceMaintenanceList() {
            //console.log(this.productlistData[0])
            await this.$axios.get("/ServiceMaintenance/GetProductMaintenanceTypeList/" +  this.productlistData[0].key).then(response => {
                if (response.data.IsSuccessful == true) {
                    this.serviceMaintenanceData = response.data.List

                    if (this.serviceMaintenanceData.length > 0) {
                        this.serviceMaintenanceData.unshift({ MaintenanceName: 'Kontrol', Id: null })
                        this.disabledMaintenance = false
                        this.disabledMaintenanceRequest = true
                    } else {
                        this.disabledMaintenance = false
                        this.disabledMaintenanceRequest = true
                        this.serviceMaintenanceData = [{ MaintenanceName: 'Kontrol', Id: null }]
                    }
                }
            });

            if(this.editPage == true && this.editedItem.Appointment != null) {
                this.disableCustom = true
                this.disabledMaintenance = true
            }
            
        },

        // Açık talep için son 15 gün içinde bir görüşme aranıyor.
        async FindInterviewForOpenRequest() {
            var operationId = 1
            var userId = localStorage.getItem("userId");
            await this.$axios.get('/interview/FindInterviewForServiceRequest/' + this.dataForm.companyId + '/' + operationId + '/' + userId).then(response => {
                if (response.data.Entity <= 0) {
                    this.addInterviewDialog = true
                    this.$root.addInterviewDialog = true
                }
                else {
                    this.addedInterviewId = response.data.Entity
                }
            });
        },

        companySearch(searchCompany) {
            if (searchCompany.length >= 3) {
                this.$axios.get("/company/searchcompany/" + searchCompany + "/0/10/").then(resp => {
                    this.companyData =  resp.data.List;
                });
            }
        },

        async getOperationList() {
            await this.$axios.get("/common/GetAuthorizedOperations/" + localStorage.getItem('userId')).then(response => { this.operations = response.data.List });
        },

        async getcompanyLocation() {
            await this.$axios.get("/location/GetLocationsForEmployeeByCompany/" + this.dataForm.companyId).then(response => { this.locationsList = response.data.List });
        },

        productSearch(searchProduct) {
            console.log(searchProduct)
            /*if (searchProduct.length >= 3) {
                this.$axios.get("/company/searchcompany/" + searchProduct + "/0/10/").then(resp => {
                    this.productlist =  resp.data.List;
                });
            }*/
        },
        
        //  warning(e) {
        //     this.$confirm({
        //         title: 'Bu ürüne ait açılmış bir servis talebi vardır!',
        //         content: 'Talebe servis randevusu oluşturmak ister misiniz?',
        //         okText: 'Randevu Oluştur',
        //         cancelText: 'İptal',
        //         icon: 'warning',
        //         onOk() {
        //             this.getTest(e)
        //         },
        //         onCancel() {},
        //     });
        // },

        // Form

        handleOk() {
            this.serviceStatus = false

            this.confirmLoading = true

            // if(this.dataForm.RequestType == 2) {
            //     if (this.MaintenanceStatus === "2-Bakım tarihi geçmemiş.") {
            //         this.dataForm.RequestType = 14
            //         this.RequestTypeListSelect = [{ key: 14, label: "Ek Bakım", name: "Ek Bakım" }]
            //     }
            // }


            this.handleSubmit(this.FormDataPost)

            setTimeout(() => {
                this.warningModal = false
                this.confirmLoading = false
            }, 2000);

            

        },
        handleCancel() {
            this.warningModal = false;
        },
        
        handleSubmit(e) {


            e.preventDefault()
                this.form.validateFields((err, values) => {
                    if (!err) {

                        console.log('Form alanları: ', values);
                        
                        var formData = {}


                        // Bakım
                        if(this.dataForm.RequestType == 2 && this.editPage == false) {
                            if (this.MaintenanceStatus == "2-Bakım tarihi geçmemiş." && this.IsEquipment == false) {
                                formData.CurrentWorkTime = values.CurrentWorkTime
                            }
                        }

                        
                        // if(this.dataForm.RequestType == 2 && this.editPage == false) {
                        //     if (this.MaintenanceStatus == "2-Bakım tarihi geçmemiş." && this.IsEquipment == false) {
                        //         formData.CurrentWorkTime = values.CurrentWorkTime
                        //         if((values.CurrentWorkTime - this.LastWorkTimeValue) < 450 ) {
                        //             this.serviceStatus = true
                        //             this.warningText = 'Bu ürünün periyodik bakımı henüz gelmemiştir, devam etmeniz durumunda servis talebi "Ek Bakım" olarak kaydedilecektir. Onaylıyor musunuz?'
                        //         } else {
                        //             this.serviceStatus = false
                        //         }
                        //     }

                        //     if (this.MaintenanceStatus == "2-Bakım tarihi geçmemiş." && this.IsEquipment == true) {
                        //         this.serviceStatus = true
                        //         this.warningText = 'Bu ürünün periyodik bakımı henüz gelmemiştir, devam etmeniz durumunda servis talebi "Ek Bakım" olarak kaydedilecektir. Onaylıyor musunuz?'
                        //     }
                        // } 
                        
                        if(this.serviceStatus == true) {
                            this.FormDataPost = e
                            this.warningModal = true
                            this.disableFormButton = true

                        } else {
                            this.confirmLoading = false
                        
                            this.disableFormButton = false

                            values.MaintenanceTypeId = (values.MaintenanceTypeId == undefined) ? null : values.MaintenanceTypeId.key

                            formData.ProductType  = Number(values.ProductTypeId.key)
                            formData.CompanyId  = values.CompanyId.key
                            formData.LocationId  = values.CompanyLocationId.key
                            formData.ProductId  = values.ProductId.key
                            formData.OperationId  = Number(values.OperationId.key)
                            formData.Problem  = values.Problem
                            formData.RequestType  = Number(this.dataForm.RequestType)
                            formData.MaintenanceTypeId = values.MaintenanceTypeId
                            formData.AreaId = values.AreaId.key
                            //formData.InvoiceType = values.InvoiceType.key

                            formData.ChiefId = values.ChiefId.key

                            formData.IsWarranty = this.dataForm.IsWarranty

                            if(this.disableGroupSelect == false) formData.ServiceGroupId = values.ServiceGroupId.key

                            formData.FaultEndDate = new Date(values.FaultEndDate).toLocaleString('en-US')

                            if(formData.RequestType != 1 || formData.RequestType != 12 || formData.RequestType != 13 || formData.RequestType != 15) {
                                delete formData.FaultEndDate
                            }

                            if(this.editPage == true) {
                                formData.ResultType = this.dataForm.ResultType
                                formData.Id  = this.editedItem.Id
                                formData.InterviewId = this.dataForm.InterviewId
                            } else {
                                // Randevu Datası
                                formData.AppointmentDate = new Date(values.appointmentDate).toLocaleDateString('en-US'),
                                formData.AppointmentHour = values.toHours.key.trim()
                                formData.TechnicianId = this.TechnicianId //Number(localStorage.getItem('userId'))
                                formData.ContactId = null
                                formData.WorkTimeId = null
                                
                                formData.IsMobile  = true
                            }

                            console.log(formData)
                            
                            this.$axios.post("/ServiceRequests/addOrUpdate?", formData ).then(resp => {
                                if (resp.data.IsSuccessful == true) {

                                    this.ServiceEntityId = resp.data.Entity 

                                    var url3 = '/service/' + this.ServiceEntityId
                                    this.$router.push(url3)

                                    // if(this.editPage == false) {
                                    //     this.appointmentAdd(this.ServiceEntityId, values, formData)
                                    // } else {
                                    //     var url4 = '/service/' + this.dataForm.Id
                                    //     this.$router.push(url4)
                                    // }

                                }
                                else {
                                    this.disableFormButton = true
                                    this.$message
                                        .loading('Lütfen bekleyin..', 2.5)
                                        .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                                }
                            }).catch(e => { console.log(e) })
                        
                        
                        } 


                    } else {
                        this.disableFormButton = true
                            this.$message
                                .loading('Lütfen bekleyin..', 2.5)
                                .then(() => this.$message.error('Lütfen form alanlarını kontrol ediniz!', 2.5))
                    }
                });


        },

        // appointmentAdd(data, values, formData) {
        //     // Randevu Ekle
        //     var appointmentData = {}
        //     appointmentData.RequestId = data
        //     appointmentData.AppointmentDate = new Date(values.appointmentDate)
        //     appointmentData.AppointmentHour = values.toHours.key.trim()
        //     appointmentData.TechnicianId = Number(localStorage.getItem('userId'))
        //     appointmentData.LocationId = formData.LocationId
        //     appointmentData.ContactId = null
        //     appointmentData.WorkTimeId = null

        //     this.$axios.post("/Offers/AddOrUpdateAppointment?", appointmentData).then(resp => {
        //         if (resp.data.IsSuccessful == true) {
        //             this.$message
        //             .loading('Lütfen bekleyin..', 2.5)
        //             .then(() => this.$message.success("Randevu ekleme başarılı.", 2.5))
        //             this.createId = resp.data.Entity

                    
        //             var url3 = '/service/' + this.ServiceEntityId
        //             this.$router.push(url3)
                    
                    
        //         }
        //         else {
        //             this.$message
        //             .loading('Lütfen bekleyin..', 2.5)
        //             .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
        //         }

        //     }).catch(e => { console.log(e) })
        //     // Randevu Ekle
        // }

        
    },
  };
</script>

<style>
    .productSelectModal .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .productSelectModal  .ant-drawer-body {
        padding: 0px 0 !important;
    }

</style>