<template>
  <div>
    <menus ref="openMenu"></menus>

   <div class="subModule" style="margin:0;">
       <div class="pageHeader moduleHeader blue2 header3">
             <div class="left">
                <h3>
                    <!-- <span class="usedStyle red" :class="{ green: usedquerydata == 1 }"></span>  -->
                    {{ this.pageTitle }}
                </h3>
            </div>

            <div class="right">
                <a @click="newDocument">
                    <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
                        <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#656565"/>
                    </svg>
                </a>
                
                <input style="display:none" class="file-input" name="imageFile"  ref="fileInputFile"  type="file" @change="onSelectFile">
            </div>
            <div class="clear"></div>
        </div>
    </div>

   <div class="content shippingList ProductListPage" style="padding-bottom: 0; margin-bottom: 50px;">
         <div class="ShippingProductList row">
            <div class="col-md-12" v-if="this.dataquery.length > 0" >
                <div class="itemProduct row" v-for="item in dataquery" :key="item.SerialNo">
                    <a target="_blank" class="href" v-bind:href="'http://crmapi.hasel.com/documents/' + item.CustomerId + '/' + item.FileName"  :download="item.FileName">
                        <div class="col-xs-12">

                            <div class="title servicedetail">
                                <div class="row">
                                    <div class="col-xs-12 catalogphotodata" style="margin-top:8px">
                                        <span class="productname" style="margin-top: 0;" v-if="item.FileName">{{ item.FileName }}</span>
                                    </div>
                                    <div class="col-xs-5" style="margin-top:10px" v-if="item.CreateName">
                                        <div class="icon yellow" style="display: inline-block; margin-right: 10px; float: left; color:#444;"><i class="far fa-user"></i></div>
                                        <span class="productname" v-if="item.CreateName" >{{ item.CreateName }}</span>
                                    </div>

                                    <div class="col-xs-7" style="margin-top:10px" v-if="item.CreateDate">
                                        <div class="icon yellow" style="display: inline-block; margin-right: 10px; float: left; color:#444;"><i class="far fa-clock"></i></div>
                                        <span class="productname" v-if="item.CreateDate" >{{ new Date(item.CreateDate).toLocaleString() }}</span>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </a>
                </div>

            </div>

            <div v-else class="col-md-12" >
              <div class="photoGroupItem">
                  <div class="moduleContent">
                    <div class="warning" style="padding:25px 25px ">
                        <span>
                            <svg class="text-left" id="Warning" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
                                <path id="Path_35" data-name="Path 35" d="M7,0a7,7,0,1,0,7,7A7.021,7.021,0,0,0,7,0Zm.963,10.675H6.037V9.012H8.05v1.663ZM8.05,4.2,7.525,8.05H6.475L5.95,4.2V3.325H8.137V4.2Z" fill="#ff1119"/>
                            </svg>
                           Döküman kaydı bulunamadı!
                        </span>
                    </div>
                  </div>
                </div>
            </div>

        </div>
        

    </div>


  </div>
</template>

<script>
  import Menus from '../Menu';
  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        moment,
        pageTitle : "Belgeler",
        dataquery: [],

        // Param
        serviceId: null,

        // File
        selectedFile: null,
    
        // Filter
        filter: { Skip: 0, PageSize: 2 },
        
      };
    },
    components: {
      Menus
    },
    created() {
      this.serviceId  = this.$route.params.id
      this.getItems()
    },
    props: ['querydata'],

    watch: {
      
    },
    methods: {

      // Document
      newDocument() {
         this.$refs.fileInputFile.click()
      },

      async onSelectFile() {
          let input = this.$refs.fileInputFile
          this.selectedFile = input.files[0]
          //console.log(this.selectedFile)
          this.addFile()
      },

      addFile() {
          this.querydata.documentType = 11
          const formData = new FormData();
          formData.append("files", this.selectedFile)
          this.$axios.post("/request/FileUploadRequest?customerId=" + this.querydata.customerId + '&fileType=' + this.querydata.documentType + '&companyId=' + this.querydata.companyId + '&moduleTypeId=' + this.querydata.moduleTypeId + '&queryId=' + this.querydata.queryId, formData).then(resp => {
              if (resp.data.IsSuccessful == true) {
                  this.snackbar = true;
                  this.getItems();
                  this.selectedFile = null
              }
              else {
                  this.snackbartext = resp.data.ExceptionMessage;
                  this.snackbar = true
                  this.close();
              }

          }).catch(e => { console.log(e) })
      },

      // // General
      // goDetail(item) {
      //   let url = '/photo/photoDetail?dataProductId=' + item.Id + '&sourceType=2&serviceId=' + this.serviceId
      //   console.log(url)
      //   this.$router.push(url)
      // },

      openMenu () {
        this.$refs.openMenu.showDrawer()
      },
      
      getItems() {
          this.$axios.get("/customerFile/GetFilesByModuleTypeIdAndQueryId?moduleTypeId=" + this.querydata.moduleTypeId + "&queryId=" + this.querydata.queryId).then(resp => {
              this.dataquery = resp.data.List, this.dataquery.length = resp.data.Count, this.loadingDialog = false
          }).catch((error) => { console.log(error) })
          
      },
    },
  };
</script>

<style></style>