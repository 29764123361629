<template>
    <!--
      <router-link to="/" tag="a" class="link">Anasayfa</router-link>
      <router-link to="/about/1" tag="a" class="link">Hakkında</router-link>
    -->
    <div style="height: 100%;">
      <a-spin :spinning="spinning">
          <div class="spin-content">
              <router-view></router-view>
          </div>
      </a-spin>
      <!--
        <div class="bottomMenu" v-if="this.$route.path != '/'">
            <ul>
              <li style="background: #ff3e2a;">
                <a href="#">
                  Devam Eden
                </a>
              </li>
              <li style="background: #27ae60;">
                <a href="#">
                  Tamamlanan
                </a>
              </li>
            </ul>
        </div>
       -->
    </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      spinning: true
    };
  },
  created() {
      document.title = "Hasel Mobil";

      //console.log(this.$route.path)
      if(this.$route.path) {
        setTimeout(() => {
          this.spinning = false
        }, 1500);
      }
  },
  watch: {
    $route() {
      //console.log(this.$route.path)
      this.spinning = true
      if(this.$route.path) {
        setTimeout(() => {
          this.spinning = false
        }, 1500);
      }
    }
  }
  
}
</script>