<template>
  <div>    
    <menus ref="openMenu"></menus>

    <div style="height: 100%;" class="imageLoading">
        <a-spin :spinning="spinning">
            <div class="spin-content">
                                
                    <div class="mheader" style="height: 63px; overflow: hidden;">
                    <div class="row">
                        <div class="col-xs-2">
                            <svg @click="prevGo" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                                <g id="back" transform="translate(-1)">
                                <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                                </g>
                            </svg>
                        </div>
                        <div class="col-xs-8">
                            <span class="title">
                                Fotoğraf Detay
                            </span>
                        </div>
                        <div class="col-xs-2 text-right formBtn">

                                <div v-if="this.sourceType == 3">

                                    <a-dropdown :trigger="['click']" v-if="this.rolControl == true || this.IsEditable == true">
                                         <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                            <svg class="href"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="3" height="15" viewBox="0 0 3 15">
                                                <defs>
                                                <clipPath id="clip-path">
                                                    <rect width="3" height="15" fill="none"/>
                                                </clipPath>
                                                </defs>
                                                <g id="Options" clip-path="url(#clip-path)">
                                                <path id="Union_1" data-name="Union 1" d="M0,13.5A1.5,1.5,0,1,1,1.5,15,1.5,1.5,0,0,1,0,13.5Zm0-6A1.5,1.5,0,1,1,1.5,9,1.5,1.5,0,0,1,0,7.5Zm0-6A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" fill="#fff"/>
                                                </g>
                                            </svg>
                                        </a>
                                        <a-menu slot="overlay">
                                            <a-menu-item key="1" v-if="this.rolControl == true">
                                                <a @click="photoApproval(1)">Onayla</a>
                                            </a-menu-item>
                                            
                                            <a-menu-item key="1" v-if="this.IsEditable == true">
                                                <a v-bind:href="'/photo/edit/'+ this.dataProductId + '?dataProductId=' + this.dataProductId + '&sourceType=' + this.sourceType">Düzenle</a>
                                            </a-menu-item>
                                            
                                            <a-menu-item key="2" v-if="this.IsEditable == true">
                                                <a @click="deleteWorkTimimes">Sil</a>
                                            </a-menu-item>
                                        </a-menu>
                                    </a-dropdown>
                                
                                </div>


                                <div v-if="this.startControl == 1 && this.sourceType == 2">
                                    <svg @click="photoCheck" xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                        <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                                    </svg>
                                </div>

                                <div v-if="this.sourceType == 1 && this.IsEditable == true && this.photoStatus == false">

                                    <a-dropdown :trigger="['click']">
                                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                            <svg class="href"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="3" height="15" viewBox="0 0 3 15">
                                                <defs>
                                                <clipPath id="clip-path">
                                                    <rect width="3" height="15" fill="none"/>
                                                </clipPath>
                                                </defs>
                                                <g id="Options" clip-path="url(#clip-path)">
                                                <path id="Union_1" data-name="Union 1" d="M0,13.5A1.5,1.5,0,1,1,1.5,15,1.5,1.5,0,0,1,0,13.5Zm0-6A1.5,1.5,0,1,1,1.5,9,1.5,1.5,0,0,1,0,7.5Zm0-6A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" fill="#fff"/>
                                                </g>
                                            </svg>
                                        </a>
                                        <a-menu slot="overlay">
                                            <a-menu-item key="1">
                                                <a v-bind:href="'/photo/edit/'+ this.dataProductId + '?shippingId=' + this.shippingId + '&dataProductId=' + this.dataProductId + '&sourceType=' + this.sourceType">Düzenle</a>
                                            </a-menu-item>
                                            <a-menu-item key="2"  @click="deleteWorkTimimes">
                                                <a>Sil</a>
                                            </a-menu-item>
                                        </a-menu>
                                    </a-dropdown>
                                
                                </div>
                        </div>
                    </div>
                    </div>

                    <div class="content tabstyle" style="padding-top: 63px;">
                        <input style="display: none;" class="file-input imageFile" ref="fileInput2" type="file" @input="onSelectFile()" accept="camera/*" capture="camera">
                        <div>
                            <a-tabs default-active-key="1" @change="callback">
                                <a-tab-pane key="1" tab="Detay">

                                    <div class="moduleHeader  photoGroupItem" v-for="group in newGroupData" :key="group.GroupName">
                                        
                                        <div class="left">
                                            <div class="pageHeader">
                                                <h3>{{ group.GroupName }}</h3>
                                            </div>

                                            <!-- <div class="right" style="position: absolute; right: 25px; margin-top: -37px;" >
                                                <a @click="chooseImageOther(group)" v-if="IsEditable == true && photoStatus == false">
                                                    <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
                                                        <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#656565"/>
                                                    </svg>
                                                </a>
                                            </div> -->
                                        </div>

                                        <div class="row" style="padding:0 25px">
                                            <!-- Photo Item -->
                                            <div  class="photoItem col-xs-3" v-for="(item) in group.children" :key="item.Id">
                                                    <div class="photoContent">
                                                    <div @click="showModal(item)">
                                                        <div class="photoImg">
                                                            <a-avatar v-if="item.ImageFile" :src="item.ImageFile" :size="64" shape="square" icon="camera" />
                                                            <a-avatar v-else shape="square" :size="64"  icon="camera" />
                                                        </div>
                                                        <div class="title">
                                                            <span>
                                                                {{ item.ImageLabel }}
                                                            <span style="color:red; display:inline-block;" v-if="item.Required">*</span>
                                                            </span>
                                                            <!-- <p>{{ item.ImageNote }}</p> -->
                                                        </div>
                                                        <!-- <div class="col-xs-4">
                                                            <a-rate v-model="item.Rating" allow-half count="4" />
                                                        </div> -->
                                                        <div class="clear"></div>
                                                    </div>
                                                    <div class="clear"></div>
                                                </div>
                                            </div>
                                            <!-- Photo Item -->
                                        </div>

                                    </div>

                                    <div class="photoGroupItem">
                                        <div class="moduleContent" v-if="this.warningControl <= 0">
                                            <div class="warning" style="padding:25px 25px ">
                                                <span>
                                                    <svg class="text-left" id="Warning" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
                                                        <path id="Path_35" data-name="Path 35" d="M7,0a7,7,0,1,0,7,7A7.021,7.021,0,0,0,7,0Zm.963,10.675H6.037V9.012H8.05v1.663ZM8.05,4.2,7.525,8.05H6.475L5.95,4.2V3.325H8.137V4.2Z" fill="#ff1119"/>
                                                    </svg>
                                                    Ürüne kayıtlı fotoğraf kayıtları bulunamadı!
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </a-tab-pane>

                                <a-tab-pane key="2" tab="Bilgi" force-render>
                                    <div class="pageHeader">
                                        <h3>Genel Bilgiler</h3>
                                    </div>
                                    <div class="detailBox">
                                        <ul>
                                            <li>
                                                <span>Müşteri</span>
                                                <span>{{ this.editedItem.CompanyName }}</span>
                                            </li>
                                            <li>
                                                <span>Lokasyon</span>
                                                <span>{{ this.editedItem.LocationName }}</span>
                                            </li>
                                            <li>
                                                <span>Ürün</span>
                                                <span>{{ this.editedItem.ProductDisplayName }}</span>
                                            </li>
                                            <li>
                                                <span>Çalışma Saati</span>
                                                <span>{{ this.editedItem.WorkTimeValue }}</span>
                                            </li>
                                        </ul>
                                    </div>

                                    
                                    <div class="pageHeader">
                                        <h3>Diğer</h3>
                                    </div>
                                    <div class="detailBox">
                                        <ul>
                                            <li>
                                                <span>Oluşturan</span>
                                                <span>{{ this.editedItem.CreateUserName }} ( {{new Date(this.editedItem.CreateDate).toLocaleString() }} )</span>
                                            </li>
                                            <li >
                                                <span>Güncelleyen</span>
                                                <span v-if="this.editedItem.UpdateDate">{{ this.editedItem.UpdateUserName }} ( {{new Date(this.editedItem.UpdateDate).toLocaleString() }} )</span>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </a-tab-pane>
                            </a-tabs>
                        </div>


                        <!-- Photo Modal Start -->
                        <div>
                            <a-drawer
                                placement="left"
                                :closable="false"
                                :visible="photoModal"
                                wrapClassName="photoModalStyle"
                                @close="modalClose">
                                  
                                <div style="height: 100%;" class="imageLoading">
                                    <a-spin :spinning="spinning">
                                        <div class="spin-content">
                                            <!-- Resim -->
                                            <div class="imageIsset">
                                                <div class="row">
                                                    <div class="col-xs-6 text-left">
                                                        <svg style="margin: 0 25px;" @click="modalClose" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                                                            <g id="back" transform="translate(-1)">
                                                            <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div class="col-xs-6 text-right" v-if="this.photoStatus != true && this.IsEditable == true">

                                                        <span style="color:#fff;" class="modalEdit" @click="uploadSimilarPhoto(editedImageItem)">

                                                            <input style="display:none;" class="file-input imageFile" ref="fileInputSimilar" type="file" @input="onSelectFile()" accept="image/*" capture="camera">
                                                            <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
                                                                <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#fff"/>
                                                            </svg>
                                                        </span>

                                                        <span class="modalEdit" @click="modalEditData">
                                                            <svg id="Compose" xmlns="http://www.w3.org/2000/svg" width="17.496" height="17" viewBox="0 0 17.496 17">
                                                                <rect id="Rectangle_324" data-name="Rectangle 324" width="16" height="16" transform="translate(0 1)" fill="none"/>
                                                                <path id="Shape" d="M.01,16.491l.873-3.937,3.456,3.469L.485,16.96a.4.4,0,0,1-.095.012A.4.4,0,0,1,.01,16.491Zm1.809-4.877,8.749-8.782L14.026,6.3,5.274,15.083Zm9.65-9.683L12.946.448a1.526,1.526,0,0,1,2.161,0L16.649,2a1.185,1.185,0,0,1,0,1.675L14.926,5.4Z" transform="translate(0.5)" fill="#fff"/>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                
                                                <span class="photogroupTitle">{{ this.editedImageItem.GroupLabel }}</span>
                                                <div class="imgBox">
                                                    <div v-if="editedImageItem.ImageFile" @click="imgEditAction" class="photoEditBox"></div>

                                                    <div v-if="!editedImageItem.ImageFile && this.IsEditable == true && this.photoStatus == false" class="image-input"
                                                        :style="{ 'background-image': `url(${imageData})` }"
                                                        @click="chooseImage">
                                                        <span v-if="!imageData" class="placeholder">
                                                            <div class="photoUploadStyle" v-if="!editedImageItem.ImageFile" >
                                                                    <a-icon type="camera" />
                                                                    <div class="clearfix"></div>
                                                                    <p>Fotoğraf Çek</p>
                                                            </div>
                                                        </span>
                                                        <input v-if="!editedImageItem.ImageFile" class="file-input imageFile" ref="fileInput" type="file" @input="onSelectFile()" accept="image/*" capture="camera">
                                                    </div>
                                                    
                                                    <div v-else class="image-input"
                                                        :style="{ 'background-image': `url(${(editedImageItem.ImageFile != null) ? editedImageItem.ImageFile : defaultImage})` }">
                                                    </div>
                                                
                                                </div>

                                                <div class="photoModalFooter">
                                                    <div class="row">
                                                        <div class="col-xs-8 text-left">
                                                            <div class="photoRating">
                                                                <span class="photoTitle">{{ this.editedImageItem.ImageLabel }}  </span>
                                                                <!-- <a-rate v-model="editedImageItem.Rating" allow-half count="4" /> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-4 text-right">
                                                            <div class="photoPagination">
                                                                <span>{{ this.itemActiveIndex +1  }}/{{ this.productTagList.length  }}</span>
                                                            </div>
                                                        </div>

                                                        <div class="col-xs-12">
                                                            <p style="color:#fff; padding: 0 25px;">
                                                                {{ this.editedImageItem.ImageNote }} 
                                                            </p>
                                                        </div>

                                                        <!-- <div class="modalExamplePhoto">
                                                            <svg @click="examplePhotoVisible = true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                <path id="iconmonstr-info-7" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM9.967,16.01c.564-1.789,1.632-3.932,1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.9,5.335-2.326,4.113.613-.763,1.835-1.309,3.074-1.621,4.03-.455,1.393.694.828,1.819-.211.153.25.2.331.356.619-2.5,2.378-5.271,2.588-4.408-.146Zm4.742-8.169a1.3,1.3,0,0,1-1.761-.022,1.14,1.14,0,0,1,.164-1.661,1.3,1.3,0,0,1,1.761.022A1.141,1.141,0,0,1,14.709,7.841Z" fill="#fff"/>
                                                            </svg>
                                                        </div> -->

                                                    </div>
                                                </div>

                                            </div>
                                            <!-- Resim -->

                                            <span id="prevOwl" v-if="this.itemActiveIndex > 0" class="prev owl-item" @click="prevChange" >
                                                <a-icon type="arrow-left" />
                                            </span>

                                            <span style="opacity: 0.2;" id="prevOwl" v-if="this.itemActiveIndex == 0" class="prev owl-item" >
                                                <a-icon type="arrow-left" />
                                            </span>

                                            <span style="opacity: 0.2;" v-if="this.productTagList.length - 1 == this.itemActiveIndex" id="nextOwl" class="next owl-item">
                                                <a-icon type="arrow-right" />
                                            </span>

                                            <span v-else id="nextOwl" class="next owl-item" @click="nextChange">
                                                <a-icon type="arrow-right" />
                                            </span>
                                        </div>
                                    </a-spin>
                                </div>
                                    
                                </a-drawer>
                        </div>
                        <!-- Photo Modal Stop -->

                        <!-- Photo Note & Rating Edit -->
                        <div>
                            <a-drawer
                                placement="bottom"
                                :closable="false"
                                :visible="photoEditDataModal"
                                wrapClassName="photoEditDataModal"
                                @close="photoEditDataModalClose">
                                
                                    <div class="mheader" style="height: 63px; overflow: hidden;">
                                        <div class="row">
                                            <div class="col-xs-2">
                                                <svg @click="photoEditDataModalClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                                                    <g id="back" transform="translate(-1)">
                                                    <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div class="col-xs-8">
                                                <span class="title">
                                                    {{ this.editedImageItem.ImageLabel }}
                                                </span>
                                            </div>
                                            <div class="col-xs-2 text-right formBtn">
                                                <div>
                                                    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="photoEditDataAdd">
                                                        <a-form-item :wrapper-col="{ span: 12 }">
                                                            <a-button type="primary" html-type="submit">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13.918" viewBox="0 0 18 13.918">
                                                                    <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                                                                </svg>
                                                            </a-button>
                                                        </a-form-item>
                                                    </a-form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="content" style="padding-top: 63px;">
                                        <div class="formStyle">
                                            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="photoEditDataAdd">

                                                <div class="pageHeader">
                                                    <h3>Genel Bilgiler</h3>
                                                </div>

                                                <!-- <a-form-item label="Değerlendirme">
                                                    <a-select mode="default"
                                                        v-model="editedImageItem.Rating"
                                                        placeholder="Değerlendirme"
                                                        :filter-="false">
                                                        <a-select-option v-for="c1 in ratingList" :key="c1" :value="c1" >
                                                            {{ c1 }}
                                                        </a-select-option>
                                                    </a-select>
                                                </a-form-item> -->

                                                <a-form-item label="Not">
                                                    <a-textarea v-model="editedImageItem.ImageNote" placeholder="Not"
                                                        :auto-size="{ minRows: 3, maxRows: 5 }"
                                                        />
                                                </a-form-item>

                                                <div class="clearfix"></div>

                                            </a-form>
                                        </div>
                                    </div>

                                </a-drawer>
                        </div>
                        <!-- Photo Note & Rating Edit -->
                        
                        <!-- Example Photo -->
                        <a-drawer
                            title="Örnek Fotoğraf"
                            placement="bottom"
                            :closable="true"
                            wrapClassName="examplePhotoModal"
                            :visible="examplePhotoVisible"
                            @close="examplePhotoVisible = false">
                            <img style="height: 100%; width: 100%;" :src="editedImageItem.ExamplePhoto">
                        </a-drawer>
                        <!-- Example Photo -->

                    </div>

                    <a-drawer v-if="this.photoStatus != true && this.IsEditable == true" placement="bottom" 
                    :closable="false"
                    :visible="bottomBtnDialog"
                    wrapClassName="bottomBtnDialog"
                    @close="PhotoActionModelClose">
                        <ul class="modalBtnDiyalog">
                            <li>
                                <span href="#" @click="chooseImage">
                                    <a-icon type="camera" />
                                    Yeniden Çek
                                    <input style="display:none" class="file-input" name="imageFile"  ref="fileInput"  type="file" @input="onSelectFile()"  accept="image/*" capture="camera">
                                </span>
                            </li>
                            <li>
                                <span href="#" @click="imageDelete">
                                    <a-icon type="delete" />
                                    Sil
                                </span>
                            </li>
                            <li>
                                <span @click="PhotoActionModelClose">
                                    <a-icon type="close" />
                                    Kapat
                                </span>
                            </li>
                        </ul>
                    </a-drawer>

            </div>
        </a-spin>
    </div>
    
   
    
  </div>
</template>


<script>

const resizeImage = (base64Str, maxWidth = 400, maxHeight = 350) => {
  return new Promise((resolve) => {
    let img = new Image()
    img.src = base64Str
    img.onload = () => {
      let canvas = document.createElement('canvas')
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }
      canvas.width = width
      canvas.height = height
      let ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)
      
      resolve(canvas.toDataURL("image/jpeg",0.9))
      //"image/jpeg",0.7
    }
  })
}

  import Menus from '../Menu';
  const key = 'updatable';
  export default {
    name: "App",
    data() {
      return {
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'coordinated' }),

        // Url Params
        shippingId : 0,
        dataProductId: 0,
        sourceType: 0,

        // Modal
        photoModal : false,
        photoEditDataModal : false,

        imageData: null,

        dataquery : [],
        mergeData : [],
        productTagList : [],
        newGroupData: [],
        imageLabelNullData: [],

        editedItem : {},

        // Image Item
        editedImageItem: {},
        addDataPhoto : {},

        smilarPhoto: false,

        photoCreateId : null,

        // Btn Diyalog Bottom
        bottomBtnDialog : false,

        // Rating
        ratingList : ["1","2","3","4"],

        // Edit Buton
        inVisible : false,

        warningControl: 1,

        // Example Photo Modal
        examplePhotoVisible : false,

        itemActiveIndex: 0,

        newImageControl :false,

        // Custom
        pageControl : "",
        startControl : 0,

        // Zorunlu resimleri kontrol etme
        requiredPhoto : [],

        //Work Order
        serviceId: null,

        // Sevk
        ShippingDetail: false,

        // Diğer Fotoğraflar

        // Yetki
        IsEditable: false,
        photoStatus: false,

        spinning: false,

        defaultImage: "http://mobil.hasel.com/uploads/noimage.jpg",

        // Randevu Bilgileri
        appointmentId: null,

        // Diğer Fotoğraf
        otherImageUpload: false,
        otherPhotoLabelId: 13, // Diğer fotoğraf etiketi idsi

        // Rol
        rolList : localStorage.getItem('RoleList'),
        rolControl: false,

        // Randevu Kontrol
        EndDateControl: false,

      };
    },
    components: {
      Menus
    },
    created() {
        this.shippingId = this.$route.query.shippingId
        this.dataProductId = this.$route.query.dataProductId
        this.sourceType = this.$route.query.sourceType
        this.itemActiveIndex = 0

        this.pageControl = this.$route.query.page
        this.startControl = this.$route.query.start
        this.serviceId = this.$route.query.serviceId
        this.appointmentId = this.$route.query.workOrderId
        this.appointmentControl = this.$route.query.appointment
        this.EndDateControl = this.$route.query.EndDateControl

        if(this.shippingId > 0) {
            this.getShipping()
        }

        if(this.startControl == '1') {
            this.$message.info({ content: 'Lütfen gerekli olan fotoğrafları yükleyerek onaylayınız..', key, duration: 2 });
        }

        this.getProductById()
        //console.log(this.$route.query)

        // Katalog çekimi sorumlusu kontrolü
        this.rolControl = (this.rolList.indexOf("Katalog Sorumlusu") >= 0) ? true : false
        //console.log(this.rolList.indexOf("Katalog Sorumlusu"))
        //console.log("rol : " + this.rolControl )
    },
    watch: {
        // itemActiveIndex() {
        //     //console.log("index " + this.itemActiveIndex)
        //     var datalength = this.productTagList.length - 1
        //     if(this.itemActiveIndex == 0  ) {
        //         document.getElementById('prevOwl').style.opacity = '0.2'
        //     } else {
        //         document.getElementById('prevOwl').style.opacity = '1'
        //     }

        //     if(this.itemActiveIndex == datalength  ) {
        //         document.getElementById('nextOwl').style.opacity = '0.2'
        //     } else {
        //         document.getElementById('nextOwl').style.opacity = '1'
        //     }
        //     //console.log( this.editedImageItem )
            
        // },
    },
    methods: {

        // Get Shipping
        async getShipping() {
            await this.$axios.get("/Shippings/GetShipping/" + this.shippingId).then(response => {
                this.ShippingDetail = response.data.Entity
                this.photoStatus = this.ShippingDetail.ShippingStatus
            })
        },

        callback(key) {
            //console.log(key);
            if(key == 1) { this.inVisible = false  } 
            if(key == 2) { this.inVisible = true  } 
        },

        openMenu () {
          this.$refs.openMenu.showDrawer()
        },

        prevGo () {
            if(this.sourceType == 1) {
                var prevGo = "/shipping/detail/" + this.shippingId
                this.$router.push(prevGo)
            }

            if(this.sourceType == 2) {
                var prevGo5 = "/service/" + this.serviceId

                if(this.appointmentControl == 1) {
                    this.$router.go(-1)
                }
                
                this.$router.push(prevGo5)
                console.log(prevGo5)
            }

            if(this.sourceType == 3) {
                var prevGo2 = "/photo/photoList?sourceType=3"
                this.$router.push(prevGo2)
            }

            if(this.sourceType == 10) {
                var prevGo4 = "/photo/photoList?sourceType=10"
                this.$router.push(prevGo4)
            }

        },

        deleteWorkTimimes() {
            if (confirm('Ürüne ait fotoğraf kayıtlarını silmek istediğinizden emin misiniz?')) {

                this.$axios.post("/WorkTimes/Delete/" + this.dataProductId).then(resp => {
                    if (resp.data.IsSuccessful == true) {
                        this.$message.error({ content: 'Silme işlemi başarılı..', key, duration: 2 });
                        var prevGo5 = "/shipping/detail/" + this.shippingId 

                        if(this.sourceType == 3) {
                            prevGo5 = "photoList?sourceType=3"
                        }
                        this.$router.push(prevGo5)
                    } else {
                        this.$message.error({ content: resp.data.ExceptionMessage, key, duration: 2 });
                    }
                }).catch(e => { console.log(e) })
                this.snackbar = true;
            }
        },

        photoCheck() {
            this.requiredPhoto = this.productTagList.filter(function (data) {
                return data.Required == true && data.ImageFile == undefined
            })

            //console.log("zorunlu fotoğraf sayısı: " + this.requiredPhoto.length)

            if(this.requiredPhoto.length == 0) {
                var prevGo3 = "/service/" + this.serviceId + "?photocontrol=1"
                this.$router.push(prevGo3)
            } else {
                this.$message.error({ content: 'Lütfen zorunlu fotoğrafları yükleyiniz..', key, duration: 2 });
            }
        },

        nextChange() {
            this.imageData = null

            if(this.smilarPhoto == false) {
                this.editedImageItem = {}
            }

            var datalength = this.productTagList.length - 1
            if(this.itemActiveIndex < datalength  ) {
                this.editedImageItem = this.productTagList[this.itemActiveIndex+1]
                this.itemActiveIndex = this.itemActiveIndex + 1
            } 
            //console.log( this.itemActiveIndex)

             //console.log(this.editedImageItem)
        },

        prevChange() {
            this.imageData = null
            this.editedImageItem = {}


            if(this.itemActiveIndex > 0) {
                this.editedImageItem = this.productTagList[this.itemActiveIndex-1]
                this.itemActiveIndex = this.itemActiveIndex - 1
            }

            if(this.editedImageItem.ImageFile == null) {
                this.imageData = null  
            }
            //console.log( this.itemActiveIndex)
             //console.log(this.editedImageItem)
        },

        showModal(item) {

            //console.log(item)

            this.newImageControl = false
            this.imageData = null
            this.editedImageItem = {}
            this.editedImageItem = item
            //console.log(this.editedImageItem)
            this.photoModal = true
            
            // if(this.editedImageItem.ImageFile == undefined && this.ShippingDetail.IsEditable == false) {
            //     this.$message.info('Fotoğraf kayıtı bulunamadı.');
            // } else {
            //     this.photoModal = true
            // }
            
            this.itemActiveIndex = item.PhotoIndex
            //console.log("modal - " + this.itemActiveIndex )
            //console.log(this.editedImageItem)

        },

        modalClose() {
           this.photoModal = false;
        },

        chooseImage() {
            this.$refs.fileInput.click()
        },

        imgEditAction() {
            this.bottomBtnDialog = true
            //console.log("sss")
        },

        PhotoActionModelClose() {
            this.bottomBtnDialog = false;
        },

        modalEditData() {
            this.photoEditDataModal = true
        },

        photoEditDataModalClose() {
            this.photoEditDataModal = false
        },

        uploadSimilarPhoto(event) {
            
            this.spinning = true

            console.log(event)

            //console.log(event)
           
            //this.otherImageUpload = true
            this.smilarPhoto = true
            this.editedImageItem = {}

            this.editedImageItem.GroupId = event.GroupId
            this.editedImageItem.ImageLabelId = event.ImageLabelId
            this.editedImageItem.ImageNote = null
            this.editedImageItem.GroupLabel = event.GroupLabel
            this.editedImageItem.ImageLabel = event.ImageLabel

            this.$refs.fileInputSimilar.click()
        },

        // Diğer Fotoğraf
        chooseImageOther(event) {
            //console.log(event)
            this.otherImageUpload = true

            this.editedImageItem = {}

            this.editedImageItem.GroupId = event.children[0].GroupId
            this.editedImageItem.ImageLabelId = null
            this.editedImageItem.ImageNote = null
            
            //console.log(this.editedImageItem)
            
            this.$refs.fileInput2.click()
        
        },
        // Diğer Fotoğraf

        async onSelectFile() {
            this.spinning = true
            let input = this.$refs.fileInput

            // Diğer Fotoğraf
            if(this.otherImageUpload == true) input = this.$refs.fileInput2

            if(this.smilarPhoto == true) input = this.$refs.fileInputSimilar

            const files = await input.files
            if (files && files[0]) {
                    const reader = await new FileReader
                    reader.onload = e => {
                        
                        this.imageData = e.target.result
                        //console.log(this.imageData)
                        
                        this.bottomBtnDialog = false
                        this.newImageControl = true

                        resizeImage(e.target.result, 1920, 1080).then((result) => {
                            this.imageData  = result
                            this.editedImageItem.ImageFile = result
                            //console.log( this.editedImageItem.ImageFile )
                            this.addPhoto()
                        });

                    }
                reader.readAsDataURL(files[0])
                this.$emit('input', files[0])
            }
        },

        async getProductById() {
            await this.$axios.get("/WorkTimes/Get/" + this.dataProductId).then(resp => {
                this.editedItem = resp.data.Entity
                this.editedItem.ProductSerialNo = this.editedItem.SerialNo
                this.editedItem.getPhotoProductId = this.editedItem.ProductId
                this.dataquery = this.editedItem.WorkTimePhotoList

                this.IsEditable = this.editedItem.IsEditable

                this.getGroup()

                if(this.EndDateControl == 'true') {
                    this.IsEditable = true 
                    this.photoStatus = true
                }

                // if(this.sourceType == 1 && this.editedItem.Source == 3) {
                //     this.spinning = true
                //     this.$message.err({ content: 'Bu ürüne ait katalog çekimi gereklidir. Katalog çekimi için ilgili sayfaya yönlendiriliyorsunuz. Lütfen bekleyin.', key, duration: 10 });
                //     var url4 = "/photo/photoDetail?dataProductId=" + this.editedItem.Id + "&sourceType=3"
                //     this.$router.push(url4)
                //     this.spinning = false
                // }

            });
        },

        async getGroup() {
            await this.$axios.get("/Shippings/GetProductTagList/" + this.editedItem.getPhotoProductId).then(resp => {
                this.mergeData =  resp.data.List
                this.warningControl = this.mergeData.length
            });
            this.PhotoTagMerge()
        },

        PhotoTagMerge() {
            for( var i = 0; i < this.mergeData.length; i++){ 
                var result = this.dataquery.filter(datafilter => datafilter.ImageLabelId == this.mergeData[i].ImageLabelId);
                if(result.length > 0) {
                    this.mergeData[i].delete = true
                } 
            }

            this.mergeData = this.mergeData.concat(this.dataquery);

            setTimeout(() => {
                this.productTagList = this.mergeData
                this.groups()
            }, 100);
            
        },

        groups(){
            for (i = 0; i < this.productTagList.length; i++) {
                this.productTagList[i].GroupLabel = this.productTagList[i].GroupName;
            } 

            var data = this.productTagList

            this.newGroupData = Array.from(
            data.reduce((a,{GroupName, ...rest})=>{
                return a.set(GroupName, [rest].concat(a.get(GroupName)||[]));
            }, new Map())
            ).map(([GroupName,children])=>({GroupName,children})) 

            var newData = []
            for (var i = 0; i < this.newGroupData.length; i++) {
                var datachildren = this.newGroupData[i].children
                datachildren = datachildren.filter(data => data.delete != true);
                this.newGroupData[i].children = datachildren.reverse()
                datachildren.sort(this.sortControl);
                for (var k = 0; k < datachildren.length; k++) {
                    newData.push(datachildren[k]);
                }
            }

            for (var n = 0; n < newData.length; n++) {
                var photoIndexData = newData[n]
                Object.assign(photoIndexData, {PhotoIndex: n  });
            }
            this.productTagList = newData
        },

        sortControl(a, b) {
            const bandA = a.Sort
            const bandB = b.Sort

            let comparison = 0;
            if (bandA > bandB) {
                comparison = 1;
            } else if (bandA < bandB) {
                comparison = -1;
            }
            return comparison;
        },

        imageDelete() {
            if (confirm('Resmi silmek istediğinizden emin misiniz?')) {
                /*this.editedImageItem.ImageFile = null*/
                this.$axios.post("/WorkTimes/DeletePhoto/" + this.editedImageItem.Id).then(resp => {
                    if (resp.data.IsSuccessful == true) {
                        this.editedImageItem.ImageFile = null
                        this.bottomBtnDialog = false
                        this.imageData = null

                        delete this.editedImageItem.Id
                        this.$message.success({ content: 'Fotoğraf silme işlemi başarılı..', key, duration: 2 });

                    } else {
                        this.$message.error({ content: 'Lütfen tekrar deneyiniz..', key, duration: 2 });
                    }
                }).catch(e => { console.log(e) })
            }

            if(this.editedImageItem.ImageLabelId == this.otherPhotoLabelId) {
                this.spinning = true 
                setTimeout(() => {
                    this.getProductById()
                }, 1000);
                this.modalClose()
                this.spinning = false 
            }
            
        },

        async addPhoto() {

            if(this.newImageControl == true) {
                this.addDataPhoto.ImageFile = this.editedImageItem.ImageFile
            } else {
                this.addDataPhoto.ImageFile = null
            }

            this.addDataPhoto.WorkTimeId = Number(this.dataProductId)
            this.addDataPhoto.ImageLabelId = this.editedImageItem.ImageLabelId
            this.addDataPhoto.ImageNote =  this.editedImageItem.ImageNote
            this.addDataPhoto.Rating =  Number(this.editedImageItem.Rating)
            this.addDataPhoto.GroupId = this.editedImageItem.GroupId

            this.addDataPhoto.Id = this.editedImageItem.Id

            await this.$axios.post("/WorkTimes/AddOrUpdatePhoto?", this.addDataPhoto).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.photoCreateId = resp.data.Entity
                    this.editedImageItem.Id = resp.data.Entity
                    this.otherImageUpload = false
                    console.log(this.editedImageItem)
                    setTimeout(() => { 
                        this.spinning = false 
                    }, 1500);
                    if(this.smilarPhoto == true) {
                        this.getProductById()
                        this.editedImageItem = this.addDataPhoto
                        this.nextChange()
                        this.prevChange()
                        this.smilarPhoto = false
                    }
                } else {
                    this.$message.error("Resim yüklenirken hata oluştu. Lütfen yeniden resim çekiniz.");
                    setTimeout(() => { 
                        this.spinning = false 
                    }, 1500);
                }
            }).catch(e => { console.log(e) })

            if(this.otherImageUpload == true) {
                this.$message.success({ content: 'Fotoğraf başarıyla yüklendi', key, duration: 2 });
                this.getProductById()
            }

            this.otherImageUpload = false
            
           
        },

        photoEditDataAdd(e) {
            e.preventDefault();

            this.photoEditDataModal = false

            this.addPhoto()
        },

        photoApproval(actionTypeId) {
            var photoApprovalData = { workTimeId: Number(this.dataProductId),  actionTypeId: actionTypeId }
            console.log(photoApprovalData)
         
            this.$axios.post("/WorkTimes/ApproveWorkTime/" + photoApprovalData.workTimeId + "/" + photoApprovalData.actionTypeId).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.photoCreateId = resp.data.Entity
                    this.$message.success("Onaylama işlemi başarılı.");
                } else {
                    this.$message.error("Onaylama aşamasında problem oluştu lütfen tekrar deneyiniz.");
                }
            }).catch(e => { console.log(e) })
       
        }

    },
  };
</script>

<style>
    .ant-drawer-body {
        padding: 0px 0 !important;
    }

    .imageLoading .ant-spin-blur {
        background: #fff !important;
        opacity: 0.9;
    }

    .imageIsset {
        padding: 25px 0;
    }

</style>