<template>
  <div>
    <menus ref="openMenu"></menus>

  <div class="mheader" style="height: 65px; overflow: hidden;">
    <div class="row">
        <div class="col-xs-2">
            <svg @click="openMenu" class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
              <path id="Menu" d="M-6160,12V10.285h8.751V12Zm0-5.143V5.143h14V6.857Zm0-5.143V0h14V1.715Z" transform="translate(6160)" fill="#fff"/>
            </svg>
        </div>
        <div class="col-xs-6">
            <span class="title">
                  {{ this.pageTitle }}
            </span>
        </div>
        <div class="col-xs-4 text-right">
            <a v-bind:href="'/photo/photoCreate?sourceType=' + this.sourceTypeId + '&productSelect=true'">
              <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
                  <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#fff"/>
              </svg>
            </a>
            <a-icon @click="filterDialog" style="font-size: 19px; margin-left:25px;" type="filter" />
        </div>
    </div>
  </div>

   <div class="content shippingList ProductListPage" style="padding-top:65px;">
         <div class="ShippingProductList row">
            <div class="col-md-12">
                <div class="itemProduct row" v-for="item in dataquery" :key="item.SerialNo">
                    <a class="href" @click="goDetail(item)">
                        <div class="col-xs-12">

                            <div class="title servicedetail">
                                <span class="productname company" style="margin-bottom:10px;" v-if="item.CompanyName">{{ item.CompanyName }}</span>
                                <div class="row">
                                    <div class="col-xs-12">
                                            <svg style="float:left; margin-right:5px;" xmlns="http://www.w3.org/2000/svg" width="14.423" height="14" viewBox="0 0 14.423 14">
                                                <g id="forklift_1_" data-name="forklift (1)" transform="translate(0 -7.5)">
                                                    <path id="Path_283" data-name="Path 283" d="M431.051,441.3h-1.912a2.192,2.192,0,0,1,0,.845h1.912a.423.423,0,0,0,0-.845Z" transform="translate(-417.051 -421.58)" fill="#b8b8b8"/>
                                                    <circle id="Ellipse_241" data-name="Ellipse 241" cx="1.358" cy="1.358" r="1.358" transform="translate(2.233 18.785)" fill="#b8b8b8"/>
                                                    <path id="Path_284" data-name="Path 284" d="M11.285,7.5a.423.423,0,0,0-.423.423v5.221L8.957,8.79A2.3,2.3,0,0,0,6.985,7.5H2.912A1.556,1.556,0,0,0,1.358,9.054v5.7A1.556,1.556,0,0,0,0,16.3v2.715a1.556,1.556,0,0,0,1.428,1.548,2.2,2.2,0,1,1,4.324.006H7.765a2.2,2.2,0,0,1,3.942-1.718V7.923A.422.422,0,0,0,11.285,7.5ZM9.649,14.741,7.511,12.6l.341-.341a.423.423,0,1,0-.6-.6l-.64.64h0l-.64.64a.423.423,0,1,0,.6.6l.341-.341,1.54,1.54H4.013v-3.2a.423.423,0,0,0-.845,0v3.2H2.2V9.054a.71.71,0,0,1,.709-.709H6.985a1.463,1.463,0,0,1,1.2.783l2.456,5.613H9.649Z" fill="#b8b8b8"/>
                                                    <circle id="Ellipse_242" data-name="Ellipse 242" cx="1.358" cy="1.358" r="1.358" transform="translate(8.569 18.785)" fill="#b8b8b8"/>
                                                </g>
                                            </svg>
                                          <span class="productname" v-if="item.ProductSerialNo" >{{ item.ProductSerialNo }} / {{ (item.RefNo !=null) ? item.RefNo : "" }}</span>
                                    </div>

                                    <div v-if="item.CreateDate">
                                        <div class="col-xs-6" style="margin-top:10px">
                                                <svg style="float:left; margin-right:5px;"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="14" viewBox="0 0 14 14">
                                                    <defs>
                                                        <clipPath id="clip-path">
                                                        <rect width="14" height="14" fill="none"/>
                                                        </clipPath>
                                                    </defs>
                                                    <g id="Calendar" clip-path="url(#clip-path)">
                                                        <rect id="Rectangle_557" data-name="Rectangle 557" width="14" height="14" fill="none"/>
                                                        <path id="Path_149" data-name="Path 149" d="M1.75,4.375V12.25h10.5V4.375ZM11.375,1.75h1.75A.827.827,0,0,1,14,2.625v10.5a.827.827,0,0,1-.875.875H.875A.827.827,0,0,1,0,13.125V2.625A.827.827,0,0,1,.875,1.75h1.75V.875A.827.827,0,0,1,3.5,0a.827.827,0,0,1,.875.875V1.75h5.25V.875a.875.875,0,0,1,1.75,0ZM10.5,10.5H8.75V8.75H10.5Zm-2.625,0H6.125V8.75h1.75ZM10.5,7.875H8.75V6.125H10.5Zm-2.625,0H6.125V6.125h1.75ZM5.25,10.5H3.5V8.75H5.25Z" fill="#b8b8b8" fill-rule="evenodd"/>
                                                    </g>
                                                </svg>
                                            <span class="productname" v-if="item.CreateDate" >{{ new Date(item.CreateDate).toLocaleString() }}</span>
                                        </div>

                                        <div class="col-xs-6" style="margin-top:10px">
                                                <svg style="float:left; margin-right:5px;"  id="Component_131_2" data-name="Component 131 – 2" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 14 14">
                                                    <rect id="Rectangle_305" data-name="Rectangle 305" width="14" height="14" transform="translate(0 0)" fill="none"/>
                                                    <path id="Path_114" data-name="Path 114" d="M5.088,7.3A2.152,2.152,0,0,0,7.244,5.148,2.219,2.219,0,0,0,5.088,2.915,2.152,2.152,0,0,0,2.932,5.071,2.273,2.273,0,0,0,5.088,7.3ZM1.469,1.452A5.118,5.118,0,0,1,8.707,8.69L5.088,12.309,1.469,8.69A5.258,5.258,0,0,1,1.469,1.452Z" transform="translate(0.975 0.05)" fill="#b8b8b8" fill-rule="evenodd"/>
                                                </svg>
                                            <span class="productname" v-if="item.LocationName">{{item.LocationName }}</span>
                                        </div>
                                    </div>

                                    <div class="catalogphotodata">
                                        <div class="col-xs-4" style="margin-top:5px">
                                            <a-icon type="dashboard" />
                                            <span class="productname" v-if="item.WorkTimeValue" >{{ item.WorkTimeValue}}</span>
                                        </div>

                                        <div class="col-xs-4" style="margin-top:5px">
                                            <a-icon type="camera" />
                                            <span class="productname" v-if="item.WorkTimePhotoList">{{ item.WorkTimePhotoList.length }}</span>
                                        </div>
                                         <div class="col-xs-4" style="margin-top:5px" :class="(item.Status) ? 'true':'false'">
                                            <a-icon type="check" />
                                            <span class="productname" >{{ (item.Status == true) ? "Onaylı" : "Onaysız" }}</span>
                                        </div>
                                    </div>


                                </div>
                                
                            </div>
                        </div>
                    </a>
                </div>

            </div>

        </div>
        
        <div class="onLoadBtn" v-if="showLoadingMore" slot="loadMore" :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }">
          <a-spin v-if="loadingMore" />
          <a-button v-else @click="onLoadMore">
              daha fazla...
          </a-button>
        </div>

    </div>


    
    <a-drawer
      placement="right"
      :closable="false"
      :visible="filterVisible"
      wrapClassName="filterDialog"
      @close="filterClose">

     <div class="mheader" style="height: 66px; overflow: hidden;">
        <div class="row">
            <div class="col-xs-2">
                <svg @click="filterClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                  <g id="back" transform="translate(-1)">
                    <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                  </g>
                </svg>
            </div>
            <div class="col-xs-8">
                <span class="title">
                   Filtre
                </span>
            </div>
            <div class="col-xs-2 text-right formBtn">
                  <svg @click="filterSubmit" xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                      <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                  </svg>
            </div>
        </div>
      </div>

       <div class="content" style="padding-top: 63px;">
        <div class="formStyle" style="margin-top: 25px;">
            <form class="ant-form ant-form-horizontal">


              <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Firma Adı</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                      <div class="ant-form-item-control">
                          <a-select
                              v-model="selectCompany"
                              mode="default"
                              placeholder="Firma Ara"
                              :showSearch="true"
                              label-in-value
                              :value="value"
                              :filter-option="false"
                              notFoundContent=""
                              :loading="true"
                              @search="companySearch"
                              @change="compamySearchChange($event)">
                              <a-icon slot="suffixIcon" type="search" />
                              <a-select-option v-for="d in data" :key="d.Id">
                                {{ d.Name }}
                              </a-select-option>
                            </a-select>                        
                        </div>
                    </div>
                </div>
                
                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Ürün Seri No</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-input pressEnter="filterSubmit()" v-model="filter.ProductSeriNo" placeholder="Ürün Seri No" />
                        </div>
                    </div>
                </div>

                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Referans No" class="">Referans No</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-input pressEnter="filterSubmit()" v-model="filter.RefNo" placeholder="Referans No" />
                        </div>
                    </div>
                </div>

                
               <div class="ant-row ant-form-item">
                  <div class="ant-col ant-form-item-label">
                      <label title="Ürün Seri No" class="">Onaysız</label>
                  </div>
                  <div class="ant-col ant-form-item-control-wrapper">
                    <div class="ant-form-item-control">
                          <a-switch @change="onChangeIsNotApproved" />                  
                      </div>
                  </div>
                </div>

            </form>
        </div>
    </div>
      
    </a-drawer>


  </div>
</template>

<script>
  import Menus from '../Menu';
  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        moment,
        testdata : "555",
        pageTitle : "Ürün Fotoğraf Çekimleri",
        dataquery: [],
        companyList: [],
        searchValue: '',
        searchCompany: null,
        
        // Filter
        filterVisible: false,
        filter: { Skip: 0, PageSize: 5 },

        data: [],
        value: '',
        selectCompany: [],

        // Scroll
        loading: true,
        loadingMore: false,
        showLoadingMore: true,

        // Param
        sourceTypeId : null,
        
      };
    },
    components: {
      Menus
    },
    created() {
      this.sourceTypeId = this.$route.query.sourceType
      this.filter = { Skip: 0, PageSize: 20, sourceType: this.sourceTypeId }
      console.log( this.filter)

      if(this.sourceTypeId == 3) {
        this.pageTitle = "Katalog Çekimleri"
      }
      
      if(this.sourceTypeId == 10) {
        this.pageTitle = "Katalog Çekimleri"
      }

      var onLoadMore = false
      this.getItems(onLoadMore)
      
    },
    watch: {
      
    },
    methods: {

      // Filter
      filterDialog() {
        this.filterVisible = true
      },

      filterClose() {
        this.filterVisible = false
      },
      
      companySearch(searchCompany) {
        if (searchCompany.length >= 3) {
           this.$axios.get("/company/searchcompany/" + searchCompany + "/0/10/").then(resp => {
              this.data =  resp.data.List;
          });
        }
      },

      filterSubmit() {
        this.filter.Skip = 0
        this.filter.PageSize = 20
        var onLoadMore = false
          this.getItems(onLoadMore)
      },

      onChangeIsNotApproved(checked) {
        console.log(checked)

        if(checked) this.filter.IsApproved = true, delete this.filter.IsNotApproved
        if(checked == false) this.filter.IsNotApproved = true, delete this.filter.IsApproved

      },
      // Filter

      goDetail(item) {
        let url = '/photo/photoDetail?dataProductId=' + item.Id + '&sourceType=' + this.sourceTypeId
        console.log(url)
        this.$router.push(url)
      },

      compamySearchChange(event) {
        //var onLoadMore = false
        console.log(event)
        this.filter.CompanyId = event.key
        //this.getItems(onLoadMore)
      },

      openMenu () {
        this.$refs.openMenu.showDrawer()
      },
      
      getItems(onLoadMore) {
          if(onLoadMore == true) {
            this.filter.Skip = this.filter.Skip + 20 
            this.filter.PageSize = 20
          }

          this.$axios.get("/WorkTimes/GetList", { params: this.filter }).then(resp => {
              if (resp.data.IsSuccessful) {
                  if(onLoadMore == false) {
                      this.dataquery = resp.data.List
                       this.filterVisible= false
                  } else {
                     this.dataquery = this.dataquery.concat(resp.data.List),
                     this.loadingMore = false
                  }
              } 
          }).catch((error) => { console.log(error) })
      },

      onLoadMore() {
        this.loadingMore = true
        var onLoadMore = true
        this.getItems(onLoadMore)
      },

    },
  };
</script>

<style></style>