<template>
  <div>
    <menus ref="openMenu"></menus>

    <div class="mheader" style="height: 65px; overflow: hidden;">
      <div class="row">
          <div class="col-xs-2">
              <svg @click="openMenu" class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
                <path id="Menu" d="M-6160,12V10.285h8.751V12Zm0-5.143V5.143h14V6.857Zm0-5.143V0h14V1.715Z" transform="translate(6160)" fill="#fff"/>
              </svg>
          </div>
          <div class="col-xs-6">
              <span class="title">
                 Servis Talepleri
              </span>
          </div>
          <div class="col-xs-4 text-right">
                <a href="/service/create">
                    <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
                        <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#fff"/>
                    </svg>
                </a>
               
                <span style="float:right;">
                    <a-icon @click="filterDialog" style="font-size: 18px; margin-left:20px;" type="filter" />
                </span>
          </div>
          <div class="clear"></div>
         
      </div>
    </div>

    <div class="tabstyle" style="padding-top: 63px;">
        <div>
            <a-tabs default-active-key="1" @change="appointmentFilter">
                <a-tab-pane key="1" tab="Açık"></a-tab-pane>
                <a-tab-pane key="2" tab="Tamamlandı"></a-tab-pane>
            </a-tabs>
        </div>
    </div>


    <div class="content shippingList ProductListPage" >
        
         <div class="ShippingProductList row">
            <div class="col-md-12">
                <div class="itemProduct row" v-for="item in dataquery" :key="item.Id">

                     <a class="href" @click="goDetail(item)">
                        <div class="col-xs-12">

                            <div class="title servicedetail">
                                <span class="productname company" style="margin-bottom:0px;" v-if="item.CompanyName">{{ item.CompanyName }}</span>
                                <div class="row">

                                    <div v-if="item.CreateDate">

                                        <div class="col-xs-12" style="margin-top:10px">
                                            <svg style="float:left; margin-right:5px;" xmlns="http://www.w3.org/2000/svg" width="14.423" height="14" viewBox="0 0 14.423 14">
                                                <g id="forklift_1_" data-name="forklift (1)" transform="translate(0 -7.5)">
                                                    <path id="Path_283" data-name="Path 283" d="M431.051,441.3h-1.912a2.192,2.192,0,0,1,0,.845h1.912a.423.423,0,0,0,0-.845Z" transform="translate(-417.051 -421.58)" fill="#b8b8b8"/>
                                                    <circle id="Ellipse_241" data-name="Ellipse 241" cx="1.358" cy="1.358" r="1.358" transform="translate(2.233 18.785)" fill="#b8b8b8"/>
                                                    <path id="Path_284" data-name="Path 284" d="M11.285,7.5a.423.423,0,0,0-.423.423v5.221L8.957,8.79A2.3,2.3,0,0,0,6.985,7.5H2.912A1.556,1.556,0,0,0,1.358,9.054v5.7A1.556,1.556,0,0,0,0,16.3v2.715a1.556,1.556,0,0,0,1.428,1.548,2.2,2.2,0,1,1,4.324.006H7.765a2.2,2.2,0,0,1,3.942-1.718V7.923A.422.422,0,0,0,11.285,7.5ZM9.649,14.741,7.511,12.6l.341-.341a.423.423,0,1,0-.6-.6l-.64.64h0l-.64.64a.423.423,0,1,0,.6.6l.341-.341,1.54,1.54H4.013v-3.2a.423.423,0,0,0-.845,0v3.2H2.2V9.054a.71.71,0,0,1,.709-.709H6.985a1.463,1.463,0,0,1,1.2.783l2.456,5.613H9.649Z" fill="#b8b8b8"/>
                                                    <circle id="Ellipse_242" data-name="Ellipse 242" cx="1.358" cy="1.358" r="1.358" transform="translate(8.569 18.785)" fill="#b8b8b8"/>
                                                </g>
                                            </svg>
                                            <span class="productname" v-if="item.ProductSerialNo" >{{ item.ProductSerialNo }} {{ (item.Product && item.Product.RefNo != null) ? " / " + item.Product.RefNo : "" }}</span>
                                        </div>

                                        <div class="clearfix"></div>

                                        <div class="col-xs-6" style="margin-top:10px">
                                                <svg style="float:left; margin-right:5px;"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="14" viewBox="0 0 14 14">
                                                    <defs>
                                                        <clipPath id="clip-path">
                                                        <rect width="14" height="14" fill="none"/>
                                                        </clipPath>
                                                    </defs>
                                                    <g id="Calendar" clip-path="url(#clip-path)">
                                                        <rect id="Rectangle_557" data-name="Rectangle 557" width="14" height="14" fill="none"/>
                                                        <path id="Path_149" data-name="Path 149" d="M1.75,4.375V12.25h10.5V4.375ZM11.375,1.75h1.75A.827.827,0,0,1,14,2.625v10.5a.827.827,0,0,1-.875.875H.875A.827.827,0,0,1,0,13.125V2.625A.827.827,0,0,1,.875,1.75h1.75V.875A.827.827,0,0,1,3.5,0a.827.827,0,0,1,.875.875V1.75h5.25V.875a.875.875,0,0,1,1.75,0ZM10.5,10.5H8.75V8.75H10.5Zm-2.625,0H6.125V8.75h1.75ZM10.5,7.875H8.75V6.125H10.5Zm-2.625,0H6.125V6.125h1.75ZM5.25,10.5H3.5V8.75H5.25Z" fill="#b8b8b8" fill-rule="evenodd"/>
                                                    </g>
                                                </svg>
                                            <span class="productname" v-if="item.Appointment != null" >{{ new Date(item.Appointment.AppointmentDate).toLocaleString().split(' ')[0] }}</span>
                                        </div>

                                        <div class="col-xs-6" style="margin-top:10px">
                                                <svg style="float:left; margin-right:5px;"  id="Component_131_2" data-name="Component 131 – 2" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 14 14">
                                                    <rect id="Rectangle_305" data-name="Rectangle 305" width="14" height="14" transform="translate(0 0)" fill="none"/>
                                                    <path id="Path_114" data-name="Path 114" d="M5.088,7.3A2.152,2.152,0,0,0,7.244,5.148,2.219,2.219,0,0,0,5.088,2.915,2.152,2.152,0,0,0,2.932,5.071,2.273,2.273,0,0,0,5.088,7.3ZM1.469,1.452A5.118,5.118,0,0,1,8.707,8.69L5.088,12.309,1.469,8.69A5.258,5.258,0,0,1,1.469,1.452Z" transform="translate(0.975 0.05)" fill="#b8b8b8" fill-rule="evenodd"/>
                                                </svg>
                                            <span class="productname"  v-if="item.Appointment != null && item.Appointment.Location != null">{{ item.Appointment.Location.CityName + " / " + item.Appointment.Location.RegionName + ((item.Appointment.Location.PlaceName != null) ? " / " + item.Appointment.Location.PlaceName : "") }}</span>
                                        </div>

                                    </div>

                                    <div class="clearfix"></div>



                                    <div class="catalogphotodata">
                                        <div class="col-xs-6" style="margin-top:5px">
                                            <a-icon type="info-circle" />
                                            <span class="productname" v-if="item.ResultTypeName" >{{ item.ResultTypeName}}</span>
                                        </div>
                                         <div class="col-xs-6" style="margin-top:5px" :class="(item.ResultType >= 8) ? 'true':'false'">
                                            <a-icon type="check" />
                                            <span class="productname" >{{ (item.ResultType >= 8) ? "Tamamlandı" : "Tamamlanmadı" }}</span>
                                        </div>
                                    </div>


                                </div>
                                
                            </div>
                        </div>
                    </a>

                    
                </div>

            </div>

        </div>

        <div class="onLoadBtn" v-if="showLoadingMore" slot="loadMore" :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }">
          <a-spin v-if="loadingMore" />
          <a-button v-else @click="onLoadMore">
              daha fazla...
          </a-button>
        </div>

    </div>


    <a-drawer
      placement="right"
      :closable="false"
      :visible="filterVisible"
      wrapClassName="filterDialog"
      @close="filterClose">

     <div class="mheader" style="height: 66px; overflow: hidden;">
        <div class="row">
            <div class="col-xs-2">
                <svg @click="filterClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                  <g id="back" transform="translate(-1)">
                    <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                  </g>
                </svg>
            </div>
            <div class="col-xs-8">
                <span class="title">
                   Filtre
                </span>
            </div>
            <div class="col-xs-2 text-right formBtn">
                  <svg @click="filterSubmit" xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                      <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                  </svg>
            </div>
        </div>
      </div>

       <div class="content" style="padding-top: 63px;">
        <div class="formStyle" style="margin-top: 25px;">
            <form class="ant-form ant-form-horizontal">
          
                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Firma</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                             <!-- <a-input-search placeholder="Arama yapın" @search="onSearch" />  -->
                                <a-select
                                    v-model="selectCompany"
                                    mode="default"
                                    placeholder="Firma Ara"
                                    :showSearch="true"
                                    label-in-value
                                    :value="value"
                                    :filter-option="false"
                                    @search="companySearch"
                                    @change="compamySearchChange($event)">
                                    <a-icon slot="suffixIcon" type="search" />
                                    <a-select-option v-for="d in data" :key="d.Id">
                                    {{ d.Name }}
                                    </a-select-option>
                                </a-select>
                        </div>
                    </div>
                </div>

                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Operasyon</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <!-- <a-input-search placeholder="Arama yapın" @search="onSearch" />  -->
                            <a-select
                                mode="default"
                                v-model="filter.operationsName"
                                placeholder="Operasyon Seçiniz "
                                :showSearch="true"
                                label-in-value
                                :value="value"
                                :filter-option="false"
                                @change="onChangeOperation($event)">
                                <a-icon slot="suffixIcon" type="search" />
                                <a-select-option v-for="d in operations" :key="d.Id">
                                {{ d.Name }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                </div>
                
                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Ürün Seri No</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-input pressEnter="filterSubmit()" v-model="filter.ProductSerialNo" placeholder="Ürün Seri No" />
                        </div>
                    </div>
                </div>

                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Referans No" class="">Ürün Referans No</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-input pressEnter="filterSubmit()" v-model="filter.RentalRefNo" placeholder="Ürün Referans No" />
                        </div>
                    </div>
                </div>

                
                <!-- 
                 <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Teknisyen</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-select
                                mode="default"
                                v-model="filter.TechnicianName"
                                placeholder="Teknisyen Seçiniz "
                                label-in-value
                                :value="value"
                                :filter-option="false"
                                @change="onChangeTechnician($event)">
                                <a-icon slot="suffixIcon" type="search" />
                                <a-select-option v-for="d in technicians" :key="d.Id">
                                    {{ d.Name }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                </div> -->


                 <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Servis Durumu</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <!-- <a-input-search placeholder="Arama yapın" @search="onSearch" />  -->
                            <a-select
                                mode="default"
                                v-model="filter.RequestStatusName"
                                placeholder="Servis Durumu Seçiniz "
                                label-in-value
                                :value="value"
                                :filter-option="false"
                                @change="onChangeRequestStatus($event)">
                                <a-icon slot="suffixIcon" type="search" />
                                <a-select-option v-for="d in ResultTypeList" :key="d.Id">
                                    {{ d.Name }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                </div>

                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Talep Nedeni</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <!-- <a-input-search placeholder="Arama yapın" @search="onSearch" />  -->
                            <a-select
                                mode="default"
                                v-model="filter.RequestTypeName"
                                placeholder="Talep Nedeni Seçiniz "
                                label-in-value
                                :value="value"
                                :filter-option="false"
                                @change="onChangeRequestType($event)">
                                <a-icon slot="suffixIcon" type="search" />
                                <a-select-option v-for="d in RequestTypeList" :key="d.Id">
                                    {{ d.Name }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                </div>


                <!-- <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                        <label title="Ürün Seri No" class="">Sorumlu Olduğum Talep Listesi</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
                            <a-switch default-checked  @change="onChangeIsGetOnlyRequests"  />                  
                        </div>
                    </div>
                </div> -->


                <div class="clearfix"></div>

            </form>
        </div>
    </div>
      
    </a-drawer>


  </div>
</template>

<script>
  import Menus from '../Menu';
  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        moment,
        RoleList: localStorage.getItem('RoleList'),
        filterVisible: false,
        testdata : "555",
        dataquery: [],
        companyList: [],
        searchValue: '',
        searchCompany: null,
        
        // Filter
        filter: { Skip: 0, PageSize: 5 },

        data: [],
        value: '',
        selectCompany: [],

        // Scroll
        loading: true,
        loadingMore: false,
        showLoadingMore: true,

        IsApprovedControl:null,

        // Filter
        operations: [],
        technicians: [],
        RequestDate: null,
        AppointmentDate: null,
        RequestTypeList: [{ Name: 'Arıza', Id: 1 }, { Name: 'Periyodik Bakım', Id: 2 }, { Name: 'Hasar', Id: 15 },  { Name: 'Ek Bakım', Id: 14 },   { Name: 'Teslimat Kontrol', Id: 3 }, { Name: 'Yenileme', Id: 11 }, { Name: 'Demo', Id: 4 }, { Name: 'Eğitim', Id: 5 }, { Name: 'İnformasyon', Id: 6 }, { Name: 'Revizyon', Id: 8 }, { Name: 'Çalışma Saati', Id: 9 }],
        ResultTypeList: [{ Name: 'Teklif Bekliyor', Id: 1 }, { Name: 'Müşteri Onayı Bekliyor', Id: 2 }, { Name: 'Parça Bekliyor', Id: 3 }, { Name: 'Randevu Bekliyor', Id: 4 }, { Name: 'Randevu Verildi', Id: 5 }, { Name: 'Servis Verildi', Id: 6 }, { Name: 'İslem Bekliyor', Id: 7 }, { Name: 'Fatura Kesilebilir', Id: 8 }, { Name: 'Fatura Kesildi', Id: 9 }, { Name: 'Talep İptal', Id: 10 } ],
      };
    },
    components: {
      Menus
    },

    created() {
        // if (this.RoleList.search('Servis Şefi') > -1) {
        //     this.filter.IsGetOnlyRequests = true
        // }
        
        this.IsApprovedControl = this.$route.query.IsApproved

        if(this.IsApprovedControl != undefined) {
            this.filter.IsApproved = this.IsApprovedControl
        }

        //alert(this.IsApprovedControl)

        if(this.$route.query.status > -1) {
            this.filter.RequestStatusType = this.$route.query.status
        }

        this.filter.AppointmentStatusId = 1

        var onLoadMore = false
        this.getItems(onLoadMore)
    
    },
    watch: {
        $route() {
            this.IsApprovedControl = this.$route.query.IsApproved

            if(this.IsApprovedControl != undefined) {
                this.filter.IsApproved = this.IsApprovedControl
            }

            //console.log("test" + this.$route.path)
            if(this.$route.path) {
                var onLoadMore = false
                this.getItems(onLoadMore)
            }
        },
        
    },
    
    methods: {

        onChangeIsGetOnlyRequests(checked) {
            //console.log(`a-switch to ${checked}`);
            this.filter.IsGetOnlyRequests = checked
        },

        appointmentFilter(key) {
           this.filter.AppointmentStatusId = key
           var onLoadMore = false
           this.getItems(onLoadMore)
        },

        goDetail(item) {    
            var url = "/service/" + item.Id
            this.$router.push(url)
        },

        // Filter
        async getOperationList() {
            await this.$axios.get("/common/GetAuthorizedOperations/" + localStorage.getItem('userId')).then(response => { this.operations = response.data.List });
        },

        async GetTechnicianList() {
            await this.$axios.get("/user/GetTechnicians").then(response => { this.technicians = response.data.List });
        },

        onChangeOperation(event) {
            this.filter.OperationId = event.key
            this.getItems()
        },

        onChangeTechnician(event) {
            this.filter.TechnicianId = event.key
            this.getItems()
        },

        onChangeRequestStatus(event) {
            this.filter.RequestStatusType = event.key
            this.getItems()
        },

        onChangeRequestType(event) {
            this.filter.RequestReasonType = event.key 
            this.getItems()
        },
        

      // Filter

      compamySearchChange(event) {
        var onLoadMore = false
        console.log(event)
        this.filter.CompanyId = event.key
        this.getItems(onLoadMore)
      },

      openMenu () {
        this.$refs.openMenu.showDrawer()
      },

      filterDialog() {
        this.filterVisible = true
        this.getOperationList()
        this.GetTechnicianList()
      },

      filterClose() {
        this.filterVisible = false
      },
      
      companySearch(searchCompany) {
        if (searchCompany.length >= 3) {
           this.$axios.get("/company/searchcompany/" + searchCompany + "/0/10/").then(resp => {
              this.data =  resp.data.List;
          });
        }
      },
      getItems(onLoadMore) {
          if(onLoadMore == true) {
            this.filter.Skip = this.filter.Skip + 5
          }

            this.$axios.get("/ServiceRequests/GetList?", { params: this.filter }).then(resp => {
              if (resp.data.IsSuccessful) {
                  if(onLoadMore == false) {
                      this.dataquery = resp.data.List

                        // var datas = this.dataquery.filter(data => data.Appointment != null)
                        // console.log(datas)

                      this.filterVisible= false
                  } else {
                     this.dataquery = this.dataquery.concat(resp.data.List),
                     this.loadingMore = false
                  }
              } 
          }).catch((error) => { console.log(error) })
      },

      onLoadMore() {
        this.loadingMore = true
        var onLoadMore = true
        this.getItems(onLoadMore)
      },

      filterSubmit() {
            this.filterVisible = false
           var onLoadMore = false
           this.getItems(onLoadMore)
      }

    },
  };
</script>

<style>
    .filterDialog .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .filterDialog  .ant-drawer-body {
        padding: 0px 0 !important;
    }

    .locationSelectModal .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .locationSelectModal  .ant-drawer-body {
        padding: 0px 0 !important;
    }

</style>