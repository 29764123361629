<template>
    <div>

        <menus ref="openMenu"></menus>
        <div class="col-xs-2">
            <div v-if="!this.shippingId">
                <svg  @click="openMenu" class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
                  <path id="Menu" d="M-6160,12V10.285h8.751V12Zm0-5.143V5.143h14V6.857Zm0-5.143V0h14V1.715Z" transform="translate(6160)" fill="#fff"/>
              </svg>
            </div>
            <div v-if="this.shippingId > 0">
                <svg @click="prevGo" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                  <path id="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" fill="#fff"/>
                </svg>
            </div>
        </div>
        <div class="col-xs-8">
            <span class="title" v-if="shippingId == 0">
              Ürün Listesi
            </span>
            <span v-if="shippingId > 0">
              Ürün Seçimi
            </span>
        </div>
        <div class="col-xs-2 text-right">
            <svg class="href" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
            <path id="Union_1" data-name="Union 1" d="M6.125,14V7.875H0V6.125H6.125V0h1.75V6.125H14v1.75H7.875V14Z" fill="#fff"/>
            </svg>
        </div>

    </div>
</template>

<script>
  import Menus from '../Menu';
  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        moment,
        shippingId: 0
      };
    },
    components: {
      Menus
    },
    created() {
        this.shippingId = this.$route.query.shippingId
        if(this.shippingId == undefined) {
          this.shippingId = 0
        } 
    },
    methods: {
        openMenu () {
        this.$refs.openMenu.showDrawer()
        },
        prevGo() {
          var url = "/shipping/detail/" + this.shippingId
          this.$router.push(url)
        }
    },
  };
</script>