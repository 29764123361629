<template>
  <div>
    <div class="mheader" style="height: 130px; overflow: hidden;">
      <div class="row">

          <div >
              <div class="col-xs-2">
                  <svg @click="modalClose"  class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                    <g id="back" transform="translate(-1)">
                      <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                    </g>
                  </svg>
              </div>
              <div class="col-xs-8">
                  <span class="title">
                     Yedek Parça Seçimi
                  </span>
              </div>
          </div>


          <div class="clear"></div>
          <div class="col-xs-12">
              <div class="searchInput" style="width: 100%; display: inline-block; float: left;">
                <a-input-search placeholder="Arama yapın" @search="onSearch"/>
              </div>
          </div>
      </div>
    </div>

    <a-spin :spinning="spinningModal">
      <div class="spin-content">
        <div class="content shippingList ProductListPage" style="padding-top: 130px;">
            <div class="ShippingProductList row">
                <div class="col-md-12">
                    <div class="itemProduct row" v-for="item in dataquery" :key="item.SparePartNumber">
                        <a class="href" @click="goDetail(item)">
                            <div class="col-xs-12">
                                <div class="title">
                                    <span class="serino" v-if="item.SparePartNumber">{{ item.SparePartNumber }}</span>
                                    <span class="info" v-if="item.SparePartName">{{ item.SparePartName }}</span>
                                    <span class="info" :title="item.ListPrice"><strong>Fiyat:</strong> {{ item.ListPrice }} Euro</span>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>

            </div>
        </div>
      </div>
    </a-spin>
   
  </div>
</template>

<script>
  import moment from "moment";
  export default {
    name: "App",
    data() {
      return {
        moment,
        dataquery: [],
        shippingId : 0,

        // Ürün Ekle
        addProductModal : false,

        // Filter
        filter: { Skip: 0, PageSize: 20 },

        // Looading
        spinningModal: false,

      };
    },
    created() {
      this.shippingId = this.$route.query.shippingId
      this.productSelect = this.$route.query.productSelect

      this.getItems()
    },
    watch: {
        sparePartSelectItem() {
            //console.log(this.productSelectItem)
            this.sparepartlist = this.sparePartSelectItem
            console.log(this.sparepartlist)
        },
    },
    methods: {

        // Yedek Parça Seçimi
        sparaPartSelectModalClick() {
            this.sparePartSelectSelectModal = true
            this.$root.sparePartSelectSelectModal = true
        },
        // Yedek Parça Seçimi

        // Ürün Ekle
        addProductAction() {
          this.addProductModal = true;
        },
        addProductModalClose() {
          this.addProductModal = false;
        },
        // Ürün Ekle
        
        onSearch(value) {
            console.log(value);
            this.filter.SparePartSearchText = value
            this.getItems()
        },
        goDetail(item) {    
           var spareItem = item

           // Gereksiz
           delete spareItem.ActionTypeId
           delete spareItem.CostPrice

           this.$emit('parentgetproduct', spareItem)
           this.$emit('parentmodalclose')
        },
        modalClose() {
          this.$emit('parentmodalclose')
        },
        getItems() {
            this.spinningModal = true
            setTimeout(() => { 
                this.$axios.get("/Offers/SearchSparePart?", { params: this.filter }).then(resp => {
                    if (resp.data.Count > 0) {
                        //  if(this.filter.SerialNo) {
                        //     this.$message
                        //     .loading('Lütfen bekleyin..', 2.5)
                        //     .then(() => this.$message.success(this.filter.SerialNo + " " + 'aramanızla ilişkili ürünler yüklendi.', 2.5))
                        // }

                        this.dataquery = resp.data.List
                        this.spinningModal = false
                    }
                }).catch((error) => { console.log(error) })
            }, 1000);
        },
        
    },
  };
</script>



<style>
    .addProductModal .ant-drawer-content-wrapper {
      width: 100% !important;
    }

    .addProductModal  .ant-drawer-body {
      padding: 0px 0 !important;
    }

    .addProductModal {
          z-index: 99999 !important;
    }

</style>

<style>
    .sparePartSelectSelectModal .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .sparePartSelectSelectModal  .ant-drawer-body {
        padding: 0px 0 !important;
    }
    
    .ant-spin-nested-loading > div > .ant-spin {
        max-height: 700px !important;
        height: 700px !important;
    }
</style>