<template>
  <div>
    <menus ref="openMenu"></menus>
    <div class="mheader" style="height: 63px; overflow: hidden;">
      <div class="row">
          <div class="col-xs-2">
              <svg @click="prevGo" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                <g id="back" transform="translate(-1)">
                  <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                </g>
              </svg>
          </div>
          <div class="col-xs-8">
              <span class="title">
                Servis Talep Detay - {{ this.$route.params.id }}
              </span>
          </div>
          <div class="col-xs-2 text-right" v-if="this.actionRemove == false">
                <div>
                    <a-dropdown :trigger="['click']">
                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                          <svg class="href"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="3" height="15" viewBox="0 0 3 15">
                              <defs>
                              <clipPath id="clip-path">
                                  <rect width="3" height="15" fill="none"/>
                              </clipPath>
                              </defs>
                              <g id="Options" clip-path="url(#clip-path)">
                              <path id="Union_1" data-name="Union 1" d="M0,13.5A1.5,1.5,0,1,1,1.5,15,1.5,1.5,0,0,1,0,13.5Zm0-6A1.5,1.5,0,1,1,1.5,9,1.5,1.5,0,0,1,0,7.5Zm0-6A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" fill="#fff"/>
                              </g>
                          </svg>
                        </a>
                        <a-menu slot="overlay">
                            <!-- <a-menu-item key="0" @click="productApproveAction" v-if="this.itemDetail.IsProductVerified != true">
                                <a>Ürünü Doğrula</a>
                            </a-menu-item> -->

                            <!-- <a-menu-item key="1" @click="startWork('photo')" v-if="this.itemDetail.Appointment !=null && this.itemDetail.Appointment.StartingDate == null">
                                <a>İşe Başla</a>
                            </a-menu-item>

                            <a-menu-item key="1" @click="openFinishModal" v-if="this.itemDetail.Appointment !=null && this.itemDetail.Appointment.EndDate == null && this.itemDetail.Appointment.StartingDate != null">
                                <a>İşe Bitir</a>
                            </a-menu-item> -->

                            <a-menu-item key="1" @click="serviceRequestEdit" v-if="this.itemDetail.ResultType <= 8">
                                <a>Düzenle</a>
                            </a-menu-item>
                            
                            <a-menu-item key="2" @click="serviceRequestDelete" v-if="this.itemDetail.ResultType < 6">
                                <a>Sil</a>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </div>
          </div>
      </div>
    </div>

    <div class="content tabstyle" style="padding-top: 63px;">
        <div>
            <a-tabs default-active-key="1" @change="callback">
                <a-tab-pane key="1" tab="Detay">
                    <div class="photoGroupItem">

                        <sparepartlist v-if="this.itemDetail.Id"  ref="dataReflesh" @getsparepartdata="sparePartListData=$event" :servicedetail="this.itemDetail" :appointmentenddate="this.EndDate" :appointmentstartdate="this.StartingDateData" :offerid="this.itemDetail.OfferId" :actionhidden="true" :sparecontrol="0" :workorderid="workorderId" :actionremove="(this.itemDetail.ResultType > 8) ? true : false"></sparepartlist>

                        <!-- <workorderlist v-if="this.itemDetail.Id" ref="dataReflesh" :workorderpostdata="workOrderPostData" :usedquerydata="0" :serviceform="0"></workorderlist> -->
                        <workorderlist v-if="this.itemDetail.Id" ref="dataReflesh" @parentmodalsuccess="getShippingDetailAction" :service="itemDetail" :workorderpostdata="workOrderPostData" :usedquerydata="0" :serviceform="1"></workorderlist>

                        <photolist v-if="this.itemDetail.Id" ref="dataReflesh" @parentmodalsuccess="getShippingDetailAction" :service="itemDetail" :workorderpostdata="workOrderPostData" :usedquerydata="0" :serviceform="1"></photolist>

                        <documentlist v-if="this.itemDetail.Id" ref="dataReflesh" :querydata="queryData"></documentlist>

                    </div>
                </a-tab-pane>
                
                <a-tab-pane key="2" tab="Bilgi" force-render>
                      <div class="pageHeader">
                        <h3>Genel Bilgiler</h3>
                     </div>
                     <div class="detailBox">
                        <ul>
                            <li>
                                <span>Servis Türü</span>
                                <span>{{ (this.itemDetail.ProductType == 1) ? "Kiralama" : "Müşteri" }}</span>
                            </li>

                             <li>
                                <span>Ürün</span> 
                                <span>{{ this.itemDetail.ProductDisplayName}}  / {{ this.itemDetail.ProductSerialNo}} 
                                  <!-- <p v-if="this.itemDetail.IsProductVerified == null" class="label label-warning" style="padding: 6px 7px; height: 23px;">Onay bekliyor</p>
                                  <p v-if="this.itemDetail.IsProductVerified == true" class="label label-success" style="padding: 6px 7px; height: 23px;">Onaylandı</p> -->
                                </span>
                            </li>

                            <li>
                                <span>Operasyon</span>
                                <span>{{ this.itemDetail.OperationName }}</span>
                            </li>

                            <li>
                                <span>Firma Adı</span>
                                <span>{{ this.itemDetail.CompanyName }}</span>
                            </li>

                            <li>
                                <span>Bölge</span>
                                <span>{{ this.itemDetail.AreaName }}</span>
                            </li>

                            <li>
                                <span>Lokasyon</span>
                                <span>{{ this.itemDetail.LocationName }}</span>
                            </li>

                            <li>
                                <span>Sorumlu Şef</span>
                                <span>{{ this.itemDetail.ChiefName }}</span>
                            </li>

                            <li>
                                <span>Talep Türü</span> 
                                <span>{{ RequestTypeList.filter(x=> x.Id == this.itemDetail.RequestType).map(v=> v.Name).toLocaleString()}}</span>
                            </li>

                             <li>
                                <span>Satış Garantili</span> 
                                <span>{{ this.itemDetail.IsWarranty == false ? 'Hayır' : 'Evet' }}</span>
                            </li>

                             <li>
                                <span>Bakım Paketi</span> 
                                <span>{{ this.itemDetail.MaintenanceTypeName }}</span>
                            </li>


                            <li>
                                <span>Talep Tarihi</span> 
                                <span>{{ new Date(this.itemDetail.CreateDate).toLocaleString()}}</span>
                            </li>
                           
                            <li>
                                <span>Durum</span> 
                                <span>{{ this.itemDetail.ResultTypeName }}</span>
                            </li>
                            <li>
                                <span>Şikayet</span> 
                                <span>{{ this.itemDetail.Problem }}</span>
                            </li>

                            <li><span>Oluşturan</span> <label>{{ this.itemDetail.CreateUserName }} ({{ new Date(this.itemDetail.CreateDate).toLocaleString() }})</label></li>
                            <li><span>Güncelleyen</span> <label v-if="this.itemDetail.UpdateUserName">{{ this.itemDetail.UpdateUserName }} ({{ new Date(this.itemDetail.UpdateDate).toLocaleString() }})</label></li>


                            <!-- <li>
                                <span>Açıklama</span>
                                <span>{{ this.itemDetail.Description }}</span>
                            </li> -->
                        </ul>
                     </div>
                    
                </a-tab-pane>


                <a-tab-pane key="3" tab="Geçmiş">
                    <div>
                        <!-- <a-collapse v-model="activeKey">
                            <a-collapse-panel v-for="(getItem) in historyServiceList" :key="getItem.Id" :header="'Talep Tarihi: ' + getItem.RequestDate + ' ' + 'Talep Türü: ' + getItem.RequestTypeName">
                               <p>aaa</p>
                            </a-collapse-panel>
                        </a-collapse> -->


                        <a-collapse>
                            <a-collapse-panel v-for="(getItem, index) in historyServiceList" :key="index" @change="notModalActive(getItem)" :header="'Talep Tarihi: ' + getItem.RequestDate + ' ' + 'Talep Türü: ' + getItem.RequestTypeName">
                                <a-timeline>
                                    <a-timeline-item v-for="(getNote, index) in getItem.CompletedAppointments" :key="index">
                                        <div v-html="getNote.WorkDoneDescription"></div>
                                        <div class="noteBox">
                                            <span> <a-icon type="user" /> {{ getNote.TechnicianName}}</span>
                                            <span v-if="getNote.EndDate"> <a-icon type="clock-circle" /> {{ new Date(getNote.EndDate).toLocaleString() }}</span>
                                        </div>
                                    </a-timeline-item>
                                </a-timeline>
                            </a-collapse-panel>
                        </a-collapse>
                        
                    </div>
                </a-tab-pane>
                
            </a-tabs>
        </div>
    </div>


    <!-- Ürün Model -->
    <a-drawer
      placement="right"
      :closable="false"
      wrapClassName="productApproveModal"
      :visible="productApproveModal"
      @close="productApproveModalClose">
      
          <div class="mheader" style="height: 66px; overflow: hidden;">
          <div class="row">
              <div class="col-xs-2">
                  <svg @click="productApproveModalClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                    <g id="back" transform="translate(-1)">
                      <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                    </g>
                  </svg>
              </div>
              <div class="col-xs-8">
                  <span class="title">
                    Ürünü Onayla
                  </span>
              </div>
              <div class="col-xs-2 text-right formBtn">
                  <a-dropdown :trigger="['click']">
                      <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                        <svg class="href"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="3" height="15" viewBox="0 0 3 15">
                            <defs>
                            <clipPath id="clip-path">
                                <rect width="3" height="15" fill="none"/>
                            </clipPath>
                            </defs>
                            <g id="Options" clip-path="url(#clip-path)">
                            <path id="Union_1" data-name="Union 1" d="M0,13.5A1.5,1.5,0,1,1,1.5,15,1.5,1.5,0,0,1,0,13.5Zm0-6A1.5,1.5,0,1,1,1.5,9,1.5,1.5,0,0,1,0,7.5Zm0-6A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" fill="#fff"/>
                            </g>
                        </svg>
                      </a>
                      <a-menu slot="overlay">
                          <a-menu-item key="0">
                              <a @click="sparePartUsed(itemProductDetail)">Doğrula</a>
                          </a-menu-item>
                          <a-menu-item key="1">
                              <a @click="productSelectModalClick">Ürün Seçimi</a>
                          </a-menu-item>
                      </a-menu>
                  </a-dropdown>

              </div>
          </div>
        </div>

        <div class="content" style="padding-top: 63px;">
          <div class="formStyle" style="margin-top: 25px;">
              <form class="ant-form ant-form-horizontal">
                  <div style="margin-top: -21px;">
                      <div class="pageHeader" style="margin-bottom: 0;">
                          <h3>Genel Bilgiler</h3>
                      </div>
                      <div class="detailBox">
                          <ul>
                            <li>
                                  <span>Ürün Id</span>
                                  <span>{{ this.itemProductDetail.Id}}</span>
                              </li>
                             <li>
                                  <span>Ürün Seri No</span>
                                  <span>{{ this.itemProductDetail.SerialNo}}</span>
                              </li>
                              <li>
                                  <span>Kategori</span>
                                  <span>{{ this.itemProductDetail.CategoryName}}</span>
                              </li>
                              <li>
                                  <span>Marka</span>
                                  <span>{{ this.itemProductDetail.MarkName}}</span>
                              </li>
                              <li>
                                  <span>Model</span>
                                  <span>{{ this.itemProductDetail.ModelName}}</span>
                              </li>
                              <li>
                                  <span>İmal Yılı</span>
                                  <span>{{ this.itemProductDetail.ManufactureYear}}</span>
                              </li>
                          </ul>
                      </div>
                  </div>

                   <!-- <div v-if="this.sparePartDialogControl == 1 || this.sparePartDialogControl == 2">
                        <div class="ant-row ant-form-item">
                            <div class="ant-col ant-form-item-label">
                                <label title="Ürün Seri No" class="">Ürün Seri No</label>
                            </div>
                            <div class="ant-col ant-form-item-control-wrapper">
                                <div class="ant-form-item-control">
                                    <a-input pressEnter="addWorkItemSubmit()"  v-model="workItemData.SparePartNumber" placeholder="Ürün Seri No" />
                                </div>
                            </div>
                        </div>

                                        
                        <div class="ant-row ant-form-item">
                            <div class="ant-col ant-form-item-label">
                                <label title="Adet" class="">Adet</label>
                            </div>
                            <div class="ant-col ant-form-item-control-wrapper">
                                <div class="ant-form-item-control">
                                    <a-input-number placeholder="Adet" pressEnter="addWorkItemSubmit()" v-model="Quantity" :min="1" :max="100" @change="QuantityChange($event)" />
                                </div>
                            </div>
                        </div>
                    </div> -->

                  <div class="clearfix"></div>
              </form>
          </div>
      </div>
    </a-drawer>
    <!-- Ürün Modal -->

  <!-- 
    <a-drawer placement="right"
      :closable="false"
      :visible="productSelectModal"
      wrapClassName = "productSelectModal"
      @close="productModalClose">
      <productlist @parentmodalclose="productModalClose" @parentgetproduct="productSelectItem=$event"></productlist>
    </a-drawer> -->

    <a-drawer
      placement="right"
      :closable="false"
      :visible="productVerification"
      wrapClassName = "productSelectModal"
      @close="productVerificationClose">
        <div class="mheader" style="height: 66px; overflow: hidden;">
          <div class="row">
              <div class="col-xs-2">
                  <svg @click="productVerificationClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                      <g id="back" transform="translate(-1)">
                        <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                      </g>
                  </svg>
              </div>
              <div class="col-xs-8">
                  <span class="title">
                    Ürünü Doğrula
                  </span>
              </div>
              <div class="col-xs-2 text-right formBtn">
                    <svg  @click="productSerialNoCheck"  xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                        <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                    </svg>
              </div>
          </div>
        </div>

        <div class="content" style="padding-top: 63px;">
            <div class="formStyle" style="margin-top: 25px;">
              <form class="ant-form ant-form-horizontal">
                  <div class="ant-row ant-form-item">
                    <a-alert message="Lütfen makinanın seri numarasını doğrulama işlemi için tekrar giriniz." type="warning" show-icon />
                  </div>
                  <div>
                      <div class="ant-row ant-form-item">
                          <div class="ant-col ant-form-item-label">
                              <label title="Ürün Seri No" class="">Ürün Seri No</label>
                          </div>
                          <div class="ant-col ant-form-item-control-wrapper">
                              <div class="ant-form-item-control">
                                  <a-input v-model="productCodeVerification" placeholder="Ürün Seri No" />
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="clearfix"></div>
              </form>
            </div>
        </div>
    </a-drawer>


    <!-- İş Bitir -->
    <a-drawer
      placement="right"
      :closable="false"
      :visible="finishWorkModal"
      wrapClassName = "finishWorkModal"
      @close="finishWorkModalClose">
            <div class="mheader" style="height: 66px; overflow: hidden;">
              <div class="row">
                  <div class="col-xs-2">
                      <svg @click="finishWorkModalClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                          <g id="back" transform="translate(-1)">
                            <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                          </g>
                      </svg>
                  </div>
                  <div class="col-xs-8">
                      <span class="title">
                        İş Bitir
                      </span>
                  </div>
                  <div class="col-xs-2 text-right formBtn">

                        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleFinishSubmit">
                            <a-form-item :wrapper-col="{ span: 12 }">
                                <a-button type="primary" html-type="submit">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                        <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                                    </svg>
                                </a-button>
                            </a-form-item>
                        </a-form>

                        
                  </div>
              </div>
            </div>

            <div class="content" style="padding-top: 63px;">
               <div class="formStyle" style="margin-top: 25px;">
                   <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleFinishSubmit">
                      <div>
                            <div class="textareacustom">
                                <a-form-item label="Yapılan İş">
                                    <a-textarea v-decorator="['WorkDescription', { initialValue: workFinishData.WorkDescription, rules: [{ required: true, message: 'Lütfen yapılan iş hakkında bilgisini giriniz.' }] }, ]" placeholder="Yapılan iş hakkında bilgi veriniz." :rows="4" />
                                </a-form-item>
                            </div>
                            
                            <a-form-item label="Çalışma Saati" v-if="this.IsEquipment == false">
                                <a-input-number placeholder="Çalışma saati" v-decorator="['WorkTimeValue', { initialValue: workFinishData.WorkTimeValue, rules: [{ required: true, message: 'Lütfen makinaya ait çalışma saati bilgisini giriniz.' }] }, ]" />
                            </a-form-item>

                            <a-form-item  v-if="this.IsEquipment == false">
                                <a-checkbox v-model="isReset">
                                    Makina çalışma saati sıfırlandı.
                                </a-checkbox>
                            </a-form-item>

                            <a-form-item label="Sms onayı gerekiyor mu?">
                                <a-radio-group v-decorator="['radio-group1', { rules: [{ required: true, message: 'Lütfen seçim yapınız!' }] }]" v-model="workFinishData.NotVerificationSms" @change="serviceNotVerificationSmsChange(workFinishData.NotVerificationSms)">
                                    <a-radio value="1">
                                        Evet
                                    </a-radio>
                                    <a-radio value="2">
                                        Hayır
                                    </a-radio>
                                </a-radio-group>
                            </a-form-item>


                            <div v-if="workFinishData.NotVerificationSms == 1">
                                <div @click="contactSelectAction">
                                    <a-form-item label="İlgili kişi">
                                        <a-select
                                            label-in-value
                                            :filter-option="false"
                                            placeholder = "İlgili kişi seçiniz"
                                            notFoundContent= ""
                                            v-decorator="['ContactId', { initialValue: contactselect[0], rules: [{ required: true, message: 'İlgili kişi alanı zorunludur!' }] }]">
                                            <a-select-option v-for="item in contactselect" :key="item.key">
                                                {{ item.label }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </div>

                                <a-form-item label="Telefon Numarası">
                                    <a-input type='number' v-model="gsmPhone" placeholder="(532) 000 00 00" v-decorator="['smsPhone', {  initialValue: smsPhone, rules: [{ validator: checkPhone}] }]" />
                                </a-form-item>
                            </div>
                            
                            <a-form-item label="Arıza giderildi mi?" v-if="this.itemDetail.RequestType == 1 || this.itemDetail.RequestType == 12 || this.itemDetail.RequestType == 13 || this.itemDetail.RequestType == 15">
                                <a-radio-group v-decorator="['radio-group', { rules: [{ required: true, message: 'Lütfen seçim yapınız!' }] }]" v-model="workFinishData.serviceRequestStatus" @change="serviceRequestStatusChange(workFinishData.serviceRequestStatus)">
                                    <a-radio value="1">
                                    Evet
                                    </a-radio>
                                    <a-radio value="2">
                                    Hayır
                                    </a-radio>
                                </a-radio-group>
                            </a-form-item>

                            <div v-if="this.itemDetail.RequestType == 1 || this.itemDetail.RequestType == 12 || this.itemDetail.RequestType == 13 || this.itemDetail.RequestType == 15 && workFinishData.serviceRequestStatus == 2">
                                <a-form-item label="Tahmini Teslim Tarihi">
                                    <a-date-picker 
                                    placeholder="Tahmini Teslim Tarihi" 
                                    :disabled="(workFinishData.serviceRequestStatus == 2 ? false : true)"
                                    :defaultValue="moment(FaultEndDate)"   :format="dateFormat"
                                    v-decorator="['FaultEndDate', { initialValue: FaultEndDate, rules:  [{ required: true, message: 'Tahmini teslim tarihi zorunludur!' }]}]" />
                                </a-form-item>
                            </div>

                            <div v-if="this.workFinishData.serviceRequestStatus == 2">
                                <a-form-item label="Randevu Tarihi">
                                    <a-date-picker 
                                    placeholder="Randevu tarihi" 
                                    :defaultValue="moment(appointmentDate)"  :format="dateFormat"
                                    v-decorator="['AppointmentDate', { initialValue: appointmentDate, rules:  [{ required: true, message: 'Randevu tarihi zorunludur!' }]}]" 
                                    @change="appointmentDatechange($event)" />
                                </a-form-item>
                            </div>

                            <div v-if="this.workFinishData.serviceRequestStatus == 2">
                                <a-form-item label="Randevu Saati">
                                    <a-select
                                    label-in-value
                                    v-decorator="['AppointmentHour', {  initialValue: hourListSelect[0], rules: [{ required: true, message: 'Randevu saati zorunludur!' }] }]"
                                    placeholder="Saat"
                                    @change="appointmentHourChange($event)">
                                        <a-select-option v-for="item in hourList" :key="item" :value="item">
                                            {{ item }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </div>

                            <div v-if="this.workFinishData.serviceRequestStatus == 2">
                                    <a-form-item label="Teknisyen Seçiniz"> 
                                        <a-select
                                            label-in-value
                                            v-decorator="['TechnicianId', { initialValue: TechnicianListSelect[0], rules: [{ required: true, message: 'Teknisyen alanı zorunludur!' }] }]"
                                            placeholder="Teknisyen seçiniz"
                                            notFoundContent= "Teknisyen seçiniz"
                                            @change="technicianChange($event)">
                                            <a-select-option v-for="item in TechnicianList" :key="item.Id" :value="item.Id">
                                                {{ item.Name }}
                                            </a-select-option>
                                        </a-select>
                                </a-form-item>
                            </div>

                            <div class="ant-row ant-form-item" v-if="this.dayWarning">
                                <a-alert :message="'Bir randevu kaydının aynı gün içerisinde bitirilmesi, devam eden işlerde yeni randevu kaydı oluşturulması gerekmektedir. Randevu bitiş tarihi ' + new Date(this.itemDetail.Appointment.setFinishDate).toLocaleString()  + ' olarak güncellenecektir.'" type="warning" show-icon />
                            </div>


                            <div class="ant-row ant-form-item" >
                                <a-alert message="Telefon numarasını başında '0' olmadan giriniz! Seçilen ilgili kişiye 6 haneli doğrulama sms bilgisi gönderilecektir. İlgili kişiye gelen kodu bir sonraki sayfada lütfen doğrulayınız." type="warning" show-icon />
                            </div>




                      </div>

                      <div class="clearfix"></div>
                   </a-form>

                </div>
            </div>
    </a-drawer>
    <!-- İş Bitir -->

    <!-- Müşteri Onay Modal -->
    <a-drawer
        placement="right"
        :closable="false"
        :visible="customerApprovalVisible"
        wrapClassName="customerApprovalDialog"
        @close="customerApprovalClose">

        <div class="mheader" style="height: 66px; overflow: hidden;">
            <div class="row">
                <div class="col-xs-2">
                    <svg @click="customerApprovalClose" class="href" xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                        <g id="back" transform="translate(-1)">
                            <path id="back-2" data-name="back" d="M21.048,6.023H3.251L7.5,1.667A.994.994,0,0,0,7.5.286a.935.935,0,0,0-1.347,0L.279,6.309a.994.994,0,0,0,0,1.381l5.872,6.023a.935.935,0,0,0,1.347,0,.994.994,0,0,0,0-1.381L3.251,7.977h17.8a.977.977,0,0,0,0-1.953Z" transform="translate(1)" fill="#fff"/>
                        </g>
                    </svg>
                </div>
                <div class="col-xs-8">
                    <span class="title">
                        Sms Onayı
                    </span>
                </div>
                <div class="col-xs-2 text-right formBtn">
                    <svg @click="smsVerification" xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                        <path id="Checkbox" d="M7.9,14.918,1,7.453,2.636,5.681,7.9,11.248,17.364,1,19,2.771Z" transform="translate(-1 -1)" fill="#fff"/>
                    </svg>
                </div>
            </div>
        </div>

        <div class="content" style="padding-top: 63px;">

            <div class="formStyle" style="margin-top: 25px;">
                <form class="ant-form ant-form-horizontal">
                    <div class="ant-row ant-form-item">
                        <div class="ant-col ant-form-item-label">
                            <label title="Adet" class="">Sms Kodu</label>
                        </div>
                        <div class="ant-col ant-form-item-control-wrapper">
                            <div class="ant-form-item-control">
                                <a-input-number v-model="smsVerificationCode" placeholder="Lütfen müşteri giden sms kodunu giriniz"/>
                            </div>
                        </div>
                    </div>
                    <a-progress v-if="this.smsCounter < 100" type="circle" :percent="smsCounter" status="exception" />
                    <a-result v-if="this.smsCounter > 100"
                        status="error"
                        title="Zaman Aşımı"
                        sub-title="Doğrulama için lütfen yeniden sms kodu isteyin.">
                        <template #extra>
                            <a-button @click="smsSend" key="console" type="primary">
                                Yeniden Sms Gönder
                            </a-button>
                        </template>
                    </a-result>

                    <div class="clearfix"></div>
                </form>
            </div>

        </div>
    </a-drawer>
    <!-- Müşteri Onay Modal -->



    <a-drawer placement="right" 
        :closable="false"
        :visible="contactSelectModal"
        wrapClassName = "productSelectModal"
        @close="contactSelectModalClose">
        <contactlist :modaldata="modalData" @parentmodalclose="contactSelectModalClose" @parentgetitem="selectItem=$event"></contactlist>
    </a-drawer>

    
    <a-drawer placement="right"
        :closable="false"
        :visible="locationVerificationModal"
        wrapClassName = "locationVerificationModal"
        @close="locationVerificationModalClose">
        <locationverification v-if="this.itemDetail.Id" :locationcenter="locationCenter" @locationselectdata="locationSelectData=$event" @parentmodalclose="locationVerificationModalClose"></locationverification>
    </a-drawer>

    <!--         
    <div style="padding: 20px; background: #fff;">
      <a-steps :current="0">
        <a-step>
          <template slot="title">
            Ürün Doğrulama
          </template>
          <span slot="description">Tamamlandı</span>
        </a-step>
        <a-step title="Fotoğraf Çekimleri" sub-title="20:00:08" description="Tamamlandı" />
        <a-step title="İşe Başlama" description="Tamamlandı" />
      </a-steps>
    </div>  
    -->

    <!-- this.itemDetail.IsOpenAppointment == false -->
    <div v-if="this.itemDetail.Id && this.userControl && this.itemDetail.IsCanTakeAction == true">

        <div class="startWorkBtn" @click="serviceRequestEdit" v-if="this.serviceQuery == false">
            <a href="#">Eksik Bilgileri Giriniz</a>
        </div>

        <!-- && this.userId == this.itemDetail.Appointment.TechnicianId  -->
        <div class="startWorkBtn" v-if="this.itemDetail.Appointment.ShippingControl == false && this.workStartBtnDisable == false && this.serviceQuery == true && this.itemDetail.Appointment != null ">
            <a-popconfirm
                title="İşi başlatmak istediğinizden emin misiniz?"
                ok-text="Evet"
                cancel-text="İptal"
                @confirm="locationVerificationModalAction()">
                    <a-button type="primary" size="small">
                        İşe Başla 
                    </a-button>
            </a-popconfirm>
        </div>
        
        <div class="startWorkBtn" @click="locationVerificationModalAction()" v-if="this.workStartBtnDisable == false && (this.itemDetail.Appointment.ShippingControl == true && this.itemDetail.Appointment.Location.Latitude == null) || (this.itemDetail.Appointment.ShippingControl == true &&  this.itemDetail.Appointment.StartingDate == null) || (this.itemDetail.Appointment.ShippingControl == true &&  this.itemDetail.Appointment.WorkTimeId == null)">
            <a-button type="primary" size="small">
                İşe Başla 
            </a-button>
        </div>

        <div class="startWorkBtn" @click="photoDetailAction()" v-if="this.itemDetail.Appointment != null && this.itemDetail.Appointment.Location.Latitude != null && this.itemDetail.Appointment.WorkTimeId != null && this.WorkTimeData.RequiredPhotoStatus == false && this.itemDetail.Appointment.StartingDate != null">
            <a href="#">Fotoğraf Çek</a>
        </div>

        <div class="startWorkBtn" @click="openFinishModal" v-if="this.itemDetail.Appointment != null && this.itemDetail.Appointment.Location.Latitude != null && this.itemDetail.Appointment.WorkTimeId != null && this.WorkTimeData.RequiredPhotoStatus == true && this.itemDetail.Appointment.StartingDate != null">
            <a href="#">İşi Bitir</a>
        </div>
    </div>

    <!-- <pre>
        Seçilen Konum
        {{ this.locationSelectData }}
    </pre> -->

    <div style="position: fixed; bottom: 0;" v-if="this.itemDetail.IsCanTakeAction == false && this.itemDetail.Appointment != null">
        <a-alert v-if="this.itemDetail.Appointment.StartingDate == null"
            description="Aktif başlanan işiniz var! Lütfen diğer iş sürecini tamamlayınız."
            type="warning"
            show-icon/>
    </div>

  </div>
</template>



<script>
  import moment from "moment";
  import sparepartlist from './sparePart';
  //import productlist from '../product/ProductList';
  import contactlist from '../general/generalList';
  import Menus from '../Menu';
  import workorderlist from './WorkOrderList.vue';
  import photolist from './appointmentPhotoList.vue';
  import locationverification from '../general/locationVerification.vue';
  import documentlist from '../document/documentList.vue';
  export default {
    name: "App",
    data() {
      return {
        moment,
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'coordinated' }),

        workStartBtnDisable:false,

        itemDetail: {},
        itemAppointmentDetail: {},
        queryId: '',
        workOrderPostData: {},
        addProduct : {},

        newWorkTimeId: null,

        RequestTypeList: [{ Name: 'Arıza', Id: 1 }, { Name: 'Periyodik Bakım', Id: 2 }, { Name: 'Hasar', Id: 15 }, { Name: 'Teslimat', Id: 3 }, { Name: 'Demo', Id: 4 }, { Name: 'Eğitim', Id: 5 }, { Name: 'İnformasyon', Id: 6 }, { Name: 'Ekspertiz', Id: 7 }, { Name: 'Revizyon', Id: 8 }, { Name: 'Çalışma Saati', Id: 9 }, { Name: 'Teslimat Kontrol', Id: 10 }, { Name: 'Yenileme', Id: 11 }, { Name: '2.El Satış Makine Hazırlık ', Id: 12 }, { Name: 'Refurbishment', Id: 13 }],
        ResultTypeList: [{ Name: 'Teklif Bekliyor', Id: 1 }, { Name: 'Müşteri Onayı Bekliyor', Id: 2 }, { Name: 'Parça Bekliyor', Id: 3 }, { Name: 'Randevu Bekliyor', Id: 4 }, { Name: 'Randevu Verildi', Id: 5 }, { Name: 'Servis Verildi', Id: 6 }, { Name: 'İslem Bekliyor', Id: 7 }, { Name: 'Fatura Kesilebilir', Id: 8 }, { Name: 'Fatura Kesildi', Id: 9 }, { Name: 'Talep İptal', Id: 10 } ],

        // Ürün doğrula modal
        productApproveModal: false,

        // Ürün Seçimi
        productSelectItem : {},
        productSelectModal : false,
        itemProductDetail : {},

        // Ürün Doğrılama
        productVerification: false,
        productCodeVerification: null,

        // İş Bitir
        workFinishData: {},
        finishWorkModal:false,
        contactselect: [],
        contactSelectModal:false,

        selectItem : [],
        modalData: {},

        // Müşteri Onay
        customerApprovalVisible: false,

        smsPhone: null,

        IsEquipment: false,


        // Sms 
        smsCounter: 1,
        smsVerificationCode:null,

         // Photo Control
        photocontrol: null,

        editedItem : {},

        // Aksiyon butonları görünme durumu
        actionRemove: false,

        // Lokasyon Doğrulama
        locationVerificationModal: false,
        locationSelectData: {},
        backgroundLocation : {},

        // E-imza
        options: {
            penColor: "#c0f"
        },
        
        // Makina saati sıfırlama
        isReset: false,

        // Telefon Model
        gsmPhone: null,

        // Fotoğraf Sayısı
        WorkTimePhotoCount : 0,
        WorkTimeData: {},

        // Yönlendirme
        photoRedicent: false,
        
        // Anlık Konum
        location:null,
        errorStr:null,

        // Randevu
        hourListSelect: [],
        hourList: ['00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45', '03:00', '03:15', '03:30', '03:45', '04:00', '04:15', '04:30', '04:45', '05:00', '05:15', '05:30', '05:45', '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45', '08:00', '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '15:45', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45'],

        appointmentDate: new Date(),
        dateFormat: 'DD/MM/YYYY',
        TechnicianId: null,
        TechnicianList: [],
        TechnicianListSelect: [],
        RoleList: localStorage.getItem('RoleList'),
        userId: localStorage.getItem('userId'),
        getFormData: {},
        disableTechnician : false,
        // Randevu

        // Çalışma Saati
        workTimesUpdate:  {},

        // Randevu
        AppointmentDetail: {},

        // Yeni Randevu Bilgileri
        newAppointmentData: {},

        // Yedek Parça Bilgi Aktarımı  
        workorderId : null,
        EndDate : null,
        StartingDateData : null,

        locationCenter: {},

        // Uyarı Mesajları
        successMessage: null,
        errorMessage: null,
        messageControl: 0,

        // User Control
        userControl :false,

        // Yedek Parça Verisi
        sparePartListData: [],
        unusedPartCount: 0,
        usedPartCount:0,

        // result type
        requestResultType: null,

        // Mesafe 
        newDistance: false,

        // Servis eksik bilgi kontrol
        serviceQuery: true,

        // İşi bitir
        FaultEndDate:null,

        getchiefFilter: {},

        // Geçmiş Talepler
        historyServiceList: [],

        activeKey: [],

        // Not Liste
        noteListModal: false,
        noteList: [],
        filterNote: {},

        // Gün Uyarısı
        dayWarning:false,

        // queryData
        queryData: {},

        workStartUpdatebtn: false,


      };
    },
    components: {
      Menus,
      workorderlist,
      contactlist,
      locationverification,
      sparepartlist,
      photolist,
      documentlist
      //productlist,
    },
    watch: {
        // gsmPhone(val) {
        //     this.gsmPhone = val.length
        // },

        productSelectItem() {
            //console.log(this.productSelectItem)
            this.productlist = this.productSelectItem
            //console.log(this.productlist)
            //console.log("Seçilen ürün ıd = " + this.productlist[0].key)

            this.$axios.get("/product/GetProduct/" + this.productlist[0].key).then(response => {
                this.itemProductDetail = response.data.Entity
            })
            
        },

        selectItem() {
            this.contactselect = this.selectItem
            console.log(this.contactselect)
            this.smsPhone = this.contactselect[0].phone
            console.log(this.smsPhone)

        },

        locationSelectData(val) {
           this.itemDetail.Appointment.Location = val
           //console.log("konum doğrulama başlat")
           this.locationCheck()
        },

        smsCounter(val) {
            console.log(val)
            this.getCounter()
        },

        location() {
            if(this.location) {
                this.backgroundLocation = {
                    lat: this.location.coords.latitude,
                    lng: this.location.coords.longitude
                }
            }

            //console.log(this.location)

            this.locationCenter  = {
                lat: this.backgroundLocation.lat,
                lng: this.backgroundLocation.lng
            } 
           //console.log(this.locationCenter.lat)
        },

        sparePartListData(val) {
            var usedPart = val.filter(function (datafilter1) {
                return datafilter1.AppointmentId != null;
            });

            var unusedPart = val.filter(function (datafilter) {
                return datafilter.AppointmentId == null;
            });

            this.usedPartCount = usedPart.length
            this.unusedPartCount = unusedPart.length

            //console.log("kullanılan  " + this.usedPartCount + " --- kullanılmayan  " + this.unusedPartCount)
        },

        activeKey(key) {
            console.log(key);
        },

    },
    created() {

        this.photocontrol = this.$route.query.photocontrol

        this.getShippingDetail()    

        //this.customerApprovalVisible = true

        this.getCounter()

        if(this.RoleList.search('Servis Teknisyeni') > -1) {
           this.getFormData.TechnicianName = localStorage.getItem('Name')
           this.getFormData.Id = localStorage.getItem('userId')
           this.TechnicianId = localStorage.getItem('userId')

           this.TechnicianListSelect = [{ key: this.TechnicianId, label:  this.getFormData.TechnicianName, name:  this.getFormData.TechnicianName }]
           this.disableTechnician = true
       }

       this.FaultEndDate = new Date().toLocaleDateString('en-US')


       this.getTechnicians()

        
    },

    mounted () {
       this.getLocation()
    },

    methods: {


        // Geçmiş Servis Talepleri
        async historyServiceRequests() {
            var filterService = { Skip: 0, PageSize: 20 }
            filterService.IsProductDetail = true
            filterService.ProductId = this.itemDetail.ProductId
            filterService.IsCompletedAppointment = true

            await this.$axios.get("/ServiceRequests/GetList?", { params: filterService }).then(resp => {
                if (resp.data.IsSuccessful) {
                    this.historyServiceList = resp.data.List, this.historyServiceList.length = resp.data.Count
                } 
            }).catch((error) => { console.log(error) })
        },

        // Not Liste
        async notModalActive(item) {
            this.noteList = []

            this.filterNote = { Skip: 0, PageSize: 20 }
            this.filterNote.QueryId = item.Id
            this.filterNote.ModuleTypeId = 13

            await this.$axios.get("/Notes/GetNoteList?", { params: this.filterNote }).then(resp => {
                if (resp.data.IsSuccessful) {
                    this.noteList = resp.data.List, this.noteList.length = resp.data.Count, this.loadingDialog = false
                } else {
                    console.log("hata")
                }
            }).catch((error) => { console.log(error) })

        },
        
        // Geçmiş Servis Talepleri

        // Teknisyenin işine müdahale etme işlemleri
        async getChiefList() {

            this.getchiefFilter = { Skip: 0, PageSize: 100, GroupId: this.itemDetail.ServiceGroupId  }
            await this.$axios.get("/ServiceGroup/GetGroupUserList", { params: this.getchiefFilter }).then(response => {
                this.chiefs = response.data.List

                this.chiefs = this.chiefs.filter(function (chieffilter) {
                    return chieffilter.UserRoleNames.search('Şef') > -1;
                });

                //console.log(this.chiefs)

                var chiefControl = this.chiefs.filter(function (chieffilter) {
                    return chieffilter.UserId == localStorage.getItem('userId');
                });


                if(this.itemDetail.Appointment.IsFixedTechnician) {
                    if(chiefControl.length > 0) {
                        this.itemDetail.IsCanTakeAction = true
                        this.userControl = true
                    }
                }

            });
                
            // this.getchiefFilter = { technicianId: this.itemDetail.Appointment.TechnicianId  }
            // await this.$axios.get("/user/GetChiefs", { params: this.getchiefFilter }).then(response => {
            //     this.chiefs = response.data.List

            //     var chiefControl = this.chiefs.filter(function (chieffilter) {
            //         return chieffilter.Id == localStorage.getItem('userId');
            //     });

            //     //console.log("şef var: " + chiefControl.length)

            //     if(this.itemDetail.Appointment.IsFixedTechnician) {
            //         if(chiefControl.length > 0) {
            //             this.itemDetail.IsCanTakeAction = true
            //             this.userControl = true
            //         }
            //     }
                

            // });

        },
        // Teknisyenin işine müdahale etme işlemleri

        // Randevu Bilgisi
        serviceRequestStatusChange(event) {
            if(event == 2) {
                this.newAppointmentData.AppointmentDate = new Date()
            }
        },
        serviceNotVerificationSmsChange(event) {
            if(event == 1)  this.newAppointmentData.NotVerificationSms  = false
            if(event == 2)  this.newAppointmentData.NotVerificationSms  = true
            console.log(this.newAppointmentData.NotVerificationSms)
        },
        appointmentDatechange(event) {
            this.newAppointmentData.AppointmentDate = event
        },

        appointmentHourChange(event) {
            console.log(event)
            this.newAppointmentData.AppointmentHour = event.key
        },

        phoneChange(value ) {
            console.log(value );
        },

        checkPhone(rule, value, callback) {
            //console.log(value + " --" + value.length)
            if (value.length == 10) {
                console.lo
                callback();
                return;
            }
            callback('Lütfen telefon numarası alanını doğru giriniz.');

        },
        
        technicianChange(event) {
            console.log(event)
            this.TechnicianId = event.key
            this.newAppointmentData.TechnicianName = event.label
        },

        async getTechnicians() {
            await this.$axios.get("/user/GetTechnicians").then(response => {
                if (response.data.IsSuccessful)
                    this.TechnicianList = response.data.List
                else {
                    this.$message.error('Teknisyeneler çekilirken hata oluştu.');
                }
            });
        },
           
        // Konum isteği
        getLocation() {
            //do we support geolocation
            if(!("geolocation" in navigator)) {
              this.errorStr = 'Geolocation is not available.';
              return;
            }

            // get position
            navigator.geolocation.getCurrentPosition(pos => {
                this.location = pos;
            }, err => {
                this.errorStr = err.message;
            })
        },


        // E-imza
        undo() {
            this.$refs.signaturePad.undoSignature();
        },
        save() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

            alert("Open DevTools see the save data.");
            console.log(isEmpty);
            console.log(data);
        },
        change() {
            this.options = {
                penColor: "#00f"
            };
        },
        resume() {
            this.options = {
                penColor: "#c0f"
            };
        },
        // E-imza

        async getCounter() {
            if(this.smsCounter < 101 && this.customerApprovalVisible == true) {
                await setTimeout(() => { 
                        this.smsCounter = this.smsCounter + 1
                },4000);
            }
        },

        // İş bitir
        openFinishModal() {
            if(this.unusedPartCount < 1 && this.usedPartCount > 0) {

                this.IsEquipment = this.itemDetail.Product.IsEquipment
                this.finishWorkModal = true

                if(this.itemDetail.Appointment.StartingDate) {
                    var StartingDate = new Date(this.itemDetail.Appointment.StartingDate)
                    var bitis   = new Date()
                    var fark  = new Date(bitis - StartingDate)
                    var gun  = Math.floor(fark/1000/60/60/24)

                    this.itemDetail.Appointment.setFinishDate = new Date(this.itemDetail.Appointment.StartingDate)
                    
                    var saat =  this.itemDetail.Appointment.setFinishDate.getHours() + 2
                    var dakika =  this.itemDetail.Appointment.setFinishDate.getMinutes()
                    var saniye =  this.itemDetail.Appointment.setFinishDate.getMinutes()
                    this.itemDetail.Appointment.setFinishDate.setHours(saat, dakika, saniye);

                    if(gun > 0) this.dayWarning = true
                }

                if(this.itemDetail.Appointment.Distance > 1000 || this.itemDetail.Appointment.Distance == null) {
                    this.newDistance = true
                    this.locationDistance()
                }

            } else {
                 var errorMessage1 = "Lütfen işi bitirmek için kullanılmayan parçaları siliniz!"
                 if(this.usedPartCount == 0) {
                     errorMessage1 = "İşi bitirmek için en az bir parçanın kullanılması gerekir!"
                 }
                 this.$message
                            .loading('Lütfen bekleyin..', 1.2)
                            .then(() => this.$message.error(errorMessage1, 2.5))

            }
        },

        finishWorkModalClose() {
            this.finishWorkModal = false
        },

        contactSelectAction() {
            this.contactSelectModal = true
        },

        contactSelectModalClose() {
            this.contactSelectModal = false
        },

        // Müşteri Onayı
        customerApprovalAction() {
            this.customerApprovalVisible = true
            this.getCounter()
        },
        
        customerApprovalClose() {
            this.customerApprovalVisible = false
        },
        // Müşteri Onayı

        // Lokasyon Doğrulama
        async locationVerificationModalAction() {
            this.workStartBtnDisable = true

            /* 
            this.AppointmentDetail = this.itemDetail.Appointment
            this.AppointmentDetail.ShippingControl = false
            this.AppointmentDetail.RequestId = this.itemDetail.Id
            this.serviceAppointmentUpdate()
            */
            
            if(this.itemDetail.Appointment.ShippingControl == false) {

                if(this.itemDetail.Shipping.Status == true) {
                    //console.log(this.itemDetail.Shipping.LocationId + " - " +  this.itemDetail.Appointment.LocationId) 
                    this.AppointmentDetail = this.itemDetail.Appointment
                    this.AppointmentDetail.ShippingControl = true
                    this.AppointmentDetail.RequestId = this.itemDetail.Id
                    if(this.itemDetail.Shipping.LocationId != this.itemDetail.Appointment.LocationId) {
                        this.AppointmentDetail.LocationId = this.itemDetail.Appointment.LocationId
                    }
                    await this.serviceAppointmentUpdate()
                    this.workStartBtnDisable = false

                    this.$message
                        .loading('Lütfen bekleyin..', 0.5)
                        .then(() => this.$message.success("Sevk bilgisi doğrulama işlemi başarılı.", 3.5))

                }
                
                if(this.itemDetail.Shipping.Status == false) {
                    this.workStartBtnDisable = false
                    this.$message
                        .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error("Bu ürüne ait onaysız sevk kaydı bulunmaktadır. Sevk kaydı onaylanmadan servis işlemi başlatılamaz.", 2.5))
                }

            }

            if(this.itemDetail.Appointment.ShippingControl == true) {
                if(this.itemDetail.Appointment.Location.Latitude == null) {
                    this.workStartUpdatebtn == true

                    this.AppointmentDetail.ShippingControl = true

                    this.locationVerificationModal = true
                    this.messageControl = 1

                    this.successMessage = "Konum doğrulandı."
                    this.errorMessage = "Konum doğrulama aşamasında hata oluştu."
                } else if(this.itemDetail.Appointment.StartingDate == null) {
                    this.AppointmentDetail = this.itemDetail.Appointment
                    this.AppointmentDetail.ShippingControl = true
                    this.AppointmentDetail.RequestId = this.itemDetail.Id

                    var startingdate= new Date()
                    this.AppointmentDetail.StartingDate = startingdate
                    await this.serviceAppointmentUpdate()
                    this.workStartBtnDisable = false
                } else {
                    await this.workStart()
                    this.workStartBtnDisable = false
                }
            }

        },

        locationVerificationModalClose() {
            this.locationVerificationModal = false
        },
        // Lokasyon Doğrulama

        
        // İş bitir

        // İş Başla
        
        async startWork(type) {
            if (type == 'photo') {
                    console.log("test")
 
            }

            if (type == 'photoDetail') {
                var prevGo = "/photo/photoDetail?dataProductId=" + this.itemDetail.Appointment.WorkTimeId + "&sourceType=2&page=service" + "&workOrderId=" + this.itemDetail.Appointment.Id
                this.$router.push(prevGo)
            }

            if (type == 'successful') {

                this.getShippingDetail()

                var AppointmentFilter = {}
                AppointmentFilter.ServiceRequestId = this.$route.params.id
                AppointmentFilter.PageSize = 10
                AppointmentFilter.Skip = 0

                this.$axios.get("/Offers/GetAppointmentList?", { params: AppointmentFilter }).then(resp => {
                    if (resp.data.IsSuccessful) {
                        this.editedItem  = resp.data.List[0]
                        var startingdate= new Date()
                        this.editedItem.RequestId = this.itemDetail.Id
                        this.editedItem.StartingDate = startingdate
                        console.log("başla " + this.editedItem.StartingDate)
                        this.AppointmenUpdate()
                    }
                }).catch((error) => { console.log(error) })

                    
            }
        },

        photoDetailAction() {
            var prevGo8 = "/photo/photoDetail?dataProductId=" + this.itemDetail.Appointment.WorkTimeId + "&sourceType=2&page=service&serviceId=" + this.itemDetail.Id  + "&workOrderId=" + this.itemDetail.Appointment.Id
            this.$router.push(prevGo8)
        },

        PhotoCheck() {
            this.photoRedicent = true
            this.locationCheck()
        },

        async workStart() {
            this.workStartBtnDisable = true
            
            await setTimeout(() => {
                //console.log(new Date())
                this.itemDetail.Start= true

                this.messageControl = 2

                if(this.itemDetail.Appointment.WorkTimeId == null ) {
                    
                    // Mesafe ölçme
                    this.locationDistance()
                    this.workTimesUpdateData()
                }

            }, 500)
        },

        async locationCheck() {
             this.workStartBtnDisable = true

            //console.log(this.locationSelectData)
            if(this.itemDetail.Appointment.Location.Latitude == null) {
                this.locationVerificationModalAction()   
                //console.log(this.locationSelectData)  
            } else {
                // Lokasyon güncelleme 
                this.locationUpdate()
                
                // Mesafe ölçme
                this.locationDistance()
                
                // workTime güncelleme
                this.workTimesUpdateData()

                // // Randevu Güncelle
                // this.AppointmenUpdate()
                this.getShippingDetail()

                this.locationVerificationModalAction()

                this.workStartBtnDisable = false
            }
        },

        async workTimesUpdateData() {
            
            if(this.itemDetail.Appointment.WorkTimeId == null) {
                this.workTimesUpdate.SourceType = 2
                this.workTimesUpdate.ProductId = this.itemDetail.ProductId
                this.workTimesUpdate.CompanyId = this.itemDetail.Appointment.CompanyId
                this.workTimesUpdate.CompanyLocationId = this.itemDetail.Appointment.LocationId
                this.workTimesUpdate.WorkTimeValue = 0

                // Konum
                this.workTimesUpdate.Latitude = Number(this.itemDetail.Appointment.Location.Latitude )
                this.workTimesUpdate.Longitude = Number(this.itemDetail.Appointment.Location.Longitude )
                this.workTimesUpdate.Distance = this.locationSelectData.Distance
        
                await this.$axios.post("/WorkTimes/addOrUpdate?", this.workTimesUpdate).then(resp => {
                    if (resp.data.IsSuccessful == true) {
                        this.newWorkTimeId = resp.data.Entity

                        this.AppointmenUpdate()

                        if(this.photoRedicent == true) {
                            var prevGo2 = "/photo/photoDetail?dataProductId=" + this.newWorkTimeId + "&sourceType=2&page=service&start=1" + "&serviceId=" + this.itemDetail.Id
                            this.$router.push(prevGo2)
                        }
                        
                    }
                }).catch(e => { console.log(e) })
            }
            
        },

        async locationDistance() {
                await this.$axios.get("Location/GetLocationsDistance/" +  this.itemDetail.Appointment.Location.Latitude + "/" +  this.itemDetail.Appointment.Location.Longitude  + "/" +  this.backgroundLocation.lat + "/" + this.backgroundLocation.lng).then(resp => {
                    if (resp.data.IsSuccessful) {
                        this.locationSelectData.Distance = resp.data.Entity

                       
                    } else {
                        console.log("hata")
                    }
                }).catch((error) => { console.log(error) })
        },

        async locationUpdate() {
            await this.$axios.get("/location/GetLocation/" + this.itemDetail.Appointment.LocationId).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    var locationDetail = resp.data.Entity

                    locationDetail.Latitude = this.itemDetail.Appointment.Location.Latitude 
                    locationDetail.Longitude = this.itemDetail.Appointment.Location.Longitude 

                    if(locationDetail.PlaceId == 0)  locationDetail.PlaceId = null

                    //if(locationDetail.DistrictId == 0)  locationDetail.DistrictId = null
                    
                    this.$axios.post("/location/AddOrUpdate", locationDetail).then(resp1 => {
                        if (resp1.data.IsSuccessful == true) {
                            var locationnewdid = resp1.data.Entity;
                            console.log(locationnewdid)

                            this.getShippingDetail()
                            this.dataReflesh()
                        } 
                    }).catch(e => { console.log(e) })
                }
                   
            });
        },

        async GetAppointmentDetail() {
            // Randevu Detay
            var AppointmentFilter = {}
            AppointmentFilter.ServiceRequestId = this.$route.params.id
            AppointmentFilter.PageSize = 10
            AppointmentFilter.Skip = 0

            await this.$axios.get("/Offers/GetAppointmentList?", { params: AppointmentFilter }).then(resp => {
                if (resp.data.IsSuccessful) {

                    this.AppointmentDetail  = resp.data.List[0]
                    this.AppointmentDetail.RequestId = this.itemDetail.Id

                    if(this.itemDetail.Start == true) {
                        this.AppointmentDetail.StartingDate  = new Date()
                        this.AppointmentDetail.StartingDate  = new Date(this.AppointmentDetail.StartingDate).toLocaleString('en-US')
                    }

                    if(this.AppointmentDetail.WorkTimeId == null && this.newWorkTimeId == null) {
                        this.AppointmentDetail.WorkTimeId  = this.newWorkTimeId
                    }
                    if(this.newWorkTimeId != null) this.AppointmentDetail.WorkTimeId  = this.newWorkTimeId

                }
            }).catch((error) => { console.log(error) })
            // Randevu Detay
        },

        async AppointmenUpdate() {
            await this.GetAppointmentDetail()
            //console.log("RequestId " + this.AppointmentDetail.RequestId)

            //console.log(this.AppointmentDetail)

            await this.$axios.post("/Offers/AddOrUpdateAppointment", this.AppointmentDetail).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    if(this.messageControl == 1) {
                        this.successMessage = "Konum doğrulandı."
                        this.errorMessage = "Konum doğrulama aşamasında hata oluştu."
                    }

                    if(this.messageControl == 2) {
                        this.StartingDateData = new Date()
                        this.successMessage = "İşe başlama sisteme iletildi."
                        this.errorMessage = "İşe başlama aşamasında hata oluştu lütfen tekrar deneyiniz."
                    }

                    this.$message
                            .loading('Lütfen bekleyin..', 1.2)
                            .then(() => this.$message.success(this.successMessage, 2.5))

                    this.getShippingDetail()
                    if(this.workStartUpdatebtn == true) {
                        this.locationVerificationModalAction()
                    }
                }  else {
                    this.$message
                            .loading('Lütfen bekleyin..', 1.2)
                            .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                }
            }).catch(e => { console.log(e) })
            
        },
        // İş Başla

        // Randevu Güncelle Standart veri
        async serviceAppointmentUpdate() {
            console.log(this.AppointmentDetail)
            await this.$axios.post("/Offers/AddOrUpdateAppointment", this.AppointmentDetail).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.getShippingDetail()
                    this.locationVerificationModalAction()
                    this.workStartBtnDisable = false
                }  else {
                    this.$message
                            .loading('Lütfen bekleyin..', 1.2)
                            .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                }
            }).catch(e => { console.log(e) })
            
        },


        // Ürün Doğrula
        sparePartUsed(itemProductDetail) {
            //console.log(itemProductDetail.Id)

            this.itemDetail.ProductId = itemProductDetail.Id
            //console.log(this.itemDetail)

            // Ürün doğrulaam modal
            this.productVerification = true

            /*
            this.$axios.post("/ServiceRequests/addOrUpdate?", this.itemDetail).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.$message
                                .loading('Lütfen bekleyin..', 2.5)
                                .then(() => this.$message.success('Ürün doğrulama işlemi başarılı.', 2.5))

                    this.productApproveModal = false;
                }
            }).catch(e => { console.log(e) })*/
        },

        productSerialNoCheck() {
            if(this.productCodeVerification == this.itemDetail.ProductId) {
                console.log("ürün doğrulama başarılı")
                this.productVerification = false
                this.productApproveModal = false
                this.itemDetail.IsProductVerified = true

                /*
                this.$axios.post("/ServiceRequests/addOrUpdate?", this.itemDetail).then(resp => {
                    if (resp.data.IsSuccessful == true) {
                    this.$message
                                .loading('Lütfen bekleyin..', 2.5)
                                .then(() => this.$message.success('Ürün doğrulama işlemi başarılı.', 2.5))

                    this.productApproveModal = false;
                    }
                }).catch(e => { console.log(e) })*/
                
            } else {
                console.log("ürün doğrulama başarısız")
                this.$message
                    .loading('Lütfen bekleyin..', 2.5)
                    .then(() => this.$message.error('Ürün doğrulama işlemi başarısız. Lütfen seri numarasını kontrol ediniz.', 2.5))
            }
        },

        // Ürün Doğrulama Son Aşama
        productVerificationClose() {
            this.productVerification = false
        },

        // Ürün Onayla
        productApproveAction() {
            this.productSelectModal = true
        },

        productApproveModalClose() {
            this.productApproveModal = false;
        },
        // Ürün Onayla

        // Ürün seçimi
        productSelectModalClick() {
            this.productSelectModal = true
            this.$root.productSelectModal = true
        },
        
        productModalClose() {
            this.productApproveModal = true;
            this.productSelectModal = false;
        },
        // Ürün seçimi

        callback(key) {
            //console.log(key);
            if(key == 1) { this.inVisible = false  } 
            if(key == 2) { this.inVisible = true  } 
            if(key == 3) { this.historyServiceRequests()  } 

        },
        
        openMenu () {
            this.$refs.openMenu.showDrawer()
        },

        prevGo () {
            this.$router.push("/service")
            //this.$router.go(-1)
        },

        serviceRequestEdit () {
            this.queryId = this.$route.params.id
            this.$router.push("/service/edit/"+this.queryId)
        },

        dataReflesh () {
            this.$refs.dataReflesh.getItems()
        },

        getShippingDetailAction() {
            this.getShippingDetail()
            this.dataReflesh()
        },
        
        async getShippingDetail() {
            this.workStartBtnDisable = true // İşe başla butonunu gizle

            this.queryId = this.$route.params.id
            await this.$axios.get("/ServiceRequests/Get/" + this.queryId).then(response => {
                this.itemDetail = response.data.Entity

                this.queryData = { companyId: this.itemDetail.CompanyId, customerId: 1, queryId: this.itemDetail.Id, moduleTypeId: '13' }

                // if(this.itemDetail.Appointment !=null && this.itemDetail.Appointment.StartingDate != null && this.itemDetail.Appointment.WorkTimeId != null && this.itemDetail.Appointment.WorkTimeId != null) {
                //     this.workStartBtnDisable = true
                // } else {
                //      this.workStartBtnDisable = false
                // }

                this.workOrderPostData = { ServiceRequestId: this.itemDetail.Id}

                this.itemProductDetail = this.itemDetail.Product
                this.itemAppointmentDetail =  response.data.Entity

                if(this.itemDetail.Appointment != null) {
                    this.newWorkTimeId = this.itemDetail.Appointment.WorkTimeId 
                    this.workorderId = this.itemAppointmentDetail.Appointment.Id
                    this.EndDate = this.itemAppointmentDetail.Appointment.EndDate
                    this.StartingDateData  =  this.itemAppointmentDetail.Appointment.StartingDate

                    if(this.itemDetail.Appointment.TechnicianId == localStorage.getItem('userId')) {
                        this.userControl = true
                    }

                    // if(this.RoleList.search('Şef') > -1 ) {
                    //     this.userControl = true
                    //     this.itemDetail.IsCanTakeAction = true
                    // }

                    // Fotoğraf Sayısı
                    if(this.itemDetail.Appointment.WorkTimeId != null) {
                        this.$axios.get("/WorkTimes/Get/" + this.itemDetail.Appointment.WorkTimeId).then(resp => {
                            this.WorkTimePhotoCount = resp.data.Count
                            this.WorkTimeData = resp.data.Entity
                        });
                    }
                    // Fotoğraf Sayısı
                    
                }

                if(this.itemDetail.ResultType == 9) {
                    this.actionRemove = true
                }
            
                if(this.itemDetail.Appointment != null) {
                    if(this.itemDetail.Appointment.StartingDate == null) {
                        if(this.photocontrol==1) {
                            this.startWork('successful')
                        }
                    }
                }

                // Servis eksik bilgileri
                // if(this.itemDetail.RequestType == 2 && this.itemDetail.MaintenanceTypeId == null ) {
                //     this.serviceQuery = false
                // }

                // İlgili Kişi Seçimi
                this.modalData = { PageTitle: "İlgili Kişi Seçimi", pageControl: "2", Prev: this.$route.path, apiUrl: "authenticator/getAll", CompanyId: this.itemDetail.CompanyId }

                // if (this.$route.query.productId) {
                //   this.addProduct = { CompanyId : this.item.ToCompanyId,  CompanyLocationId: this.item.ToLocationId, ProductId: this.$route.query.productId, ShippingId:this.item.Id  }
                // }

                if(this.itemDetail.Appointment != null ) {
                     this.getChiefList()
                }

                this.workStartBtnDisable = false // İşe başla butonunu göster
                
            })
        },

        // editData() {
        //     var editUrl = "/shipping/edit/" + this.queryId
        //     //console.log(editUrl)
        //     this.$router.push(editUrl)
        // },

        serviceRequestDelete() {
            this.$axios.post("/ServiceRequests/Delete/" + this.queryId).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.$message.loading({ content: 'Lütfen bekleyin...' });
                    setTimeout(() => {
                        this.dataReflesh()
                        this.$message.success({ content: 'Servis talebi silme işlemi başarılı.', duration: 2 });
                        setTimeout(() => { this.$router.push("/service") }, 1000);
                    }, 1000);
                    
                } else {
                    this.$message.loading({ content: 'Lütfen bekleyin...' });
                    setTimeout(() => {
                        this.dataReflesh()
                        this.$message.error({ content: resp.data.ExceptionMessage, duration: 2 });
                    }, 1000);
                }
            }).catch(e => { console.log(e) })
        },

        async smsVerification() {
            await this.$axios.post("/ServiceRequests/VerifyVerificationCode?appointmentId=" + this.itemDetail.Appointment.Id + "&verificationCode=" + this.smsVerificationCode + "&gsmNumber=" + this.smsPhone + "&requestResultType=" + this.requestResultType ).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.finishWorkModal = false
                    this.customerApprovalVisible = false

                    this.$message
                                .loading('Bilgiler kontrol ediliyor.', 0.50)
                                .then(() => this.$message.success('Sms doğrulama başarılı.', 1))

                    if(this.workFinishData.serviceRequestStatus == 2) {
                        this.appointmentAdd()
                    }

                    this.dataReflesh()
                    this.getShippingDetailAction()
                }
                else {
                    this.$message
                        .loading('Lütfen bekleyin..', 2.5)
                        .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                }
            }).catch(e => { console.log(e) })

        },

        smsSend() {
            var SendVerificationData = {}
            SendVerificationData.appointmentId = this.itemDetail.Appointment.Id
            SendVerificationData.gsm = Number(this.smsPhone)
            SendVerificationData.verificationType = 1

            this.$axios.post("/ServiceRequests/SendVerificationCode?appointmentId=" + this.itemDetail.Appointment.Id + "&gsm=" + SendVerificationData.gsm + "&verificationType=" + SendVerificationData.verificationType).then(resp => {
                if (resp.data.IsSuccessful == true) {
                
                    setTimeout(() => {
                        //this.finishWorkModal = false
                        this.customerApprovalAction()
                    }, 2000);
                }
                else {
                    this.$message
                        .loading('Lütfen bekleyin..', 2.5)
                        .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                }
            }).catch(e => { console.log(e) })
        },

      
        // İşi bitir Form
        handleFinishSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                
                if (!err) {
                    //console.log('Form alanları: ', values);

                    values.AppointmentId  = this.itemDetail.Appointment.Id
                    values.WorkTimeValue  = Number(values.WorkTimeValue)
                    
                    values.Gsm  = values.smsPhone

                    this.smsPhone = values.Gsm

                    values.isReset = this.isReset


                    if(this.newDistance) {
                        values.Distance = this.locationSelectData.Distance
                    } else  {
                        values.Distance = null
                    }

                    values.NotVerificationSms = this.newAppointmentData.NotVerificationSms

                    if(this.newAppointmentData.NotVerificationSms == false) {
                        values.ContactId  = values.ContactId.key
                    }

                    if(this.itemDetail.RequestType == 1 || this.itemDetail.RequestType == 12 || this.itemDetail.RequestType == 13 || this.itemDetail.RequestType == 15) {
                        if(this.workFinishData.serviceRequestStatus == 1) {
                            values.RequestResultType = 8
                        } 

                        if(this.workFinishData.serviceRequestStatus == 2) {
                            values.RequestResultType = 5
                        }
                    }

                    if(this.itemDetail.RequestType != 1 || this.itemDetail.RequestType != 12 || this.itemDetail.RequestType != 13 || this.itemDetail.RequestType != 15) {
                         values.RequestResultType = 8
                    }
                   
                    this.requestResultType = values.RequestResultType
                    
                    //console.log(values)

                    values.FaultEndDate = new Date(values.FaultEndDate)

                    if(this.workFinishData.serviceRequestStatus == true) {
                        values.FaultEndDate = new Date()
                    }

                    if(this.IsEquipment) {
                        values.WorkTimeValue = 0
                    }

                    this.$axios.post("/ServiceRequests/CompleteTask", values).then(resp => {
                        if (resp.data.IsSuccessful == true) {
                            this.$message
                                .loading('Bilgiler kontrol ediliyor.', 0.50)
                                .then(() => this.$message.success('Bilgiler doğrulandı.', 1))

                            this.createId = resp.data.Entity

                            if(this.newAppointmentData.NotVerificationSms == false) {
                                this.smsSend()
                            }
                            
                            if(this.newAppointmentData.NotVerificationSms == true) {
                                this.finishWorkModal = false
                                
                                if(this.workFinishData.serviceRequestStatus == 2) {
                                    this.appointmentAdd()
                                }
                                    
                                this.dataReflesh()
                                this.getShippingDetailAction()
                            }
                        }
                        else {
                            this.$message
                                .loading('Lütfen bekleyin..', 2.5)
                                .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                        }
                    }).catch(e => { console.log(e) })
                
                    
                } else {
                        this.$message
                            .loading('Lütfen bekleyin..', 2.5)
                            .then(() => this.$message.error('Hata oluştu tekrar deneyin!', 2.5))
                }

            });

        },

        appointmentAdd() {
            // Randevu Ekle
            //console.log(this.itemDetail)
            this.newAppointmentData.RequestId = this.itemDetail.Id
            this.newAppointmentData.AppointmentDate = new Date(this.newAppointmentData.AppointmentDate)
            this.newAppointmentData.AppointmentHour = this.newAppointmentData.AppointmentHour.trim()
            this.newAppointmentData.TechnicianId = this.TechnicianId //Number(localStorage.getItem('userId'))
            this.newAppointmentData.LocationId = this.itemDetail.Appointment.LocationId
            this.newAppointmentData.ContactId = null
            this.newAppointmentData.WorkTimeId = null

            //console.log(this.newAppointmentData)

            this.$axios.post("/Offers/AddOrUpdateAppointment?", this.newAppointmentData).then(resp => {
                if (resp.data.IsSuccessful == true) {
                    this.$message
                    .loading('Lütfen bekleyin..', 2.5)
                    .then(() => this.$message.success("Randevu ekleme başarılı.", 2.5))
                }
                else {
                    this.$message
                    .loading('Lütfen bekleyin..', 2.5)
                    .then(() => this.$message.error(resp.data.ExceptionMessage, 2.5))
                }

            }).catch(e => { console.log(e) })
            // Randevu Ekle
        }

    },
  };
</script>

<style>
    .productApproveModal .ant-drawer-content-wrapper,  .finishWorkModal .ant-drawer-content-wrapper{
        width: 100% !important;
    }
    .productApproveModal  .ant-drawer-body, .finishWorkModal  .ant-drawer-body{
        padding: 0px 0 !important;
    }
    .customerApprovalDialog .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .customerApprovalDialog  .ant-drawer-body {
        padding: 0px 0 !important;
    }
    .ant-popover-buttons {
        margin-bottom: 4px;
        padding: 0;
        text-align: center;
    }
    .ant-popover-buttons button {
        margin-left: 8px;
        padding: 0px 25px;
    }
</style>